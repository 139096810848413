/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers';

import paths from 'pages/Router/paths';
import { artistsCleanUp } from 'state/actions/artists';
import { useFormatMessage } from 'hooks';
import ErrorMessage from 'components/ErrorMessage';
import AsyncSelect from 'react-select/async';
import {firebaseError, getImgThumb, getImgUrl} from 'utils';

import './ArtistForm.scss';
import { searchIndex } from '../../service/algolia';
import { fetchCollection } from '../../state/api';
import firebase from "../../firebase";
import {USERS_CREATE_USER_FAIL} from "../../state/actions/users";

const defaultCountry = {
  id: 'ChIJ_UuggpyUkkYRwyW0T7qf6kA',
  name: 'Estonia'
};

const ArtistForm = ({ artist, onSubmitHandler, schema }) => {
  const { loading, success } = useSelector(
    (state) => ({
      loading: state.artists.loading,
      success: state.artists.success,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const { register, handleSubmit, errors, setValue, watch, control } = useForm({
    defaultValues: {
      ...artist,
      country: {value: artist.country?.id ?? defaultCountry.id, label: artist.country?.name ?? defaultCountry.name},
      users: artist.users ? artist.users.map(user => {
        return {value: user.id, label: user.name};
      }) : [],
      isErrArtist: artist?.source === 'err' ?? false,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (success) {
       setValue('file', null);
    }
    return () => dispatch(artistsCleanUp());
  }, [dispatch, success, setValue]);


  const imagePreviewUrl =
    watch('file') && watch('file')[0]
      ? URL.createObjectURL(watch('file')[0])
      : getImgThumb(artist.img, true);

  const imageFullUrl = getImgUrl(artist.img, true);

  const goBackMessage = useFormatMessage('ArtistForm.goBack');

  const pickAnotherFileMessage = useFormatMessage('ArtistForm.pickAnotherFile');
  const pickFileMessage = useFormatMessage('ArtistForm.pickFile');

  const loadCountries = async(searchTerm) => {
    const options = {};
    if (searchTerm) {
        const searchPlaces = firebase
            .functions()
            .httpsCallable('httpsGooglePlacesSearch');

        const response = await searchPlaces({type: 'countries', searchString: searchTerm});
        return response.data.map(c => {
          return {
            value: c.id,
            label: c.name,
          };
        });
    }

    return [{
      value: artist.country.id,
      label: artist.country.name,
    }];
  };


  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                {useFormatMessage('ArtistForm.artistInfo')}
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ArtistForm.name')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="name"
                          id="name"
                          className={classNames('input', {
                            'is-danger': errors.name,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.name && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <hr />

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ArtistForm.country')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <Controller
                          as={AsyncSelect}
                          name="country"
                          control={control}
                          loadOptions={loadCountries}
                          defaultOptions
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.country && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal"/>
                    <div className="field-body">
                      <ErrorMessage/>
                    </div>
                  </div>
                )}

                <hr />

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ArtistForm.description')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <textarea
                          name="description"
                          id="description"
                          className={classNames('textarea', {
                            'is-danger': errors.description,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="field has-check is-horizontal">
                  <div className="field-label">
                    <label className="label">{useFormatMessage('ArtistForm.ERRArtist')}</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="field">
                        <div className="control">
                          <label className="b-checkbox checkbox">
                            <input
                              type="checkbox"
                              name="isErrArtist"
                              ref={register}
                            />
                            <span className="check is-primary" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('UserForm.logo')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="file has-name">
                        <label className="file-label">
                          <input
                            className="file-input"
                            type="file"
                            name="file"
                            ref={register}
                            accept="image/*"
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="mdi mdi-upload" />
                            </span>
                            <span className="file-label">
                              {watch('file') && watch('file').file
                                ? pickAnotherFileMessage
                                : pickFileMessage}
                            </span>
                          </span>
                          <span className="file-name">
                            {watch('file') && watch('file')[0]?.name}
                          </span>
                        </label>
                      </div>
                      <em>Cover art must be square .jpg or .png file and be at least 1000x1000 px.</em>
                      {imagePreviewUrl && (
                          <div className="has-max-width">
                            <img
                                className="artist-img"
                                src={imagePreviewUrl}
                                alt="User profile logo preview"
                            />

                          </div>

                      )}
                      {imagePreviewUrl && (

                          <a href={imageFullUrl} download>Full image</a>


                      )}

                    </div>
                  </div>
                </div>
                {errors.file && (
                    <div className="field is-horizontal">
                      <div className="field-label is-normal" />
                      <div className="field-body">
                        <ErrorMessage />
                      </div>
                    </div>
                )}

                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('ArtistForm.submit')}</span>
                          </button>
                        </div>
                        <Link to={paths.ARTISTS} className="button">
                          {goBackMessage}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ArtistForm.propTypes = {
  artist: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    img: PropTypes.string,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
};

ArtistForm.defaultProps = {

};

export default ArtistForm;
