/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useCallback, useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import './Dropzone.scss';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#626262',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginTop: '30px'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const Dropzone = ({onDrop, acceptedFileTypes}) => {
    const onDropEvent = useCallback((acceptedFiles) => {
        onDrop(acceptedFiles);
    }, []);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({onDrop: onDropEvent, accept: acceptedFileTypes});

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    /* eslint-disable react/jsx-props-no-spreading */
    return (
        <div className="container">
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <div {...getRootProps({style})}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <input {...getInputProps()} />
                <p>Drag and drop or click to add your audio files</p>
                <em>Audio files must be 16-bit, 44.1 kHz WAVE files of good quality</em>
            </div>
        </div>
    );
    /* eslint-enable react/jsx-props-no-spreading */
};

export default Dropzone;
