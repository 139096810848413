import React, {useEffect, useMemo, useState} from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import AlbumForm from 'components/AlbumForm';
import { createAlbum, modifyAlbum, fetchAlbum } from 'state/actions/albums';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';
import {string} from "yup";

const Album = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success, albumData, error } = useSelector(
    (state) => ({
      success: state.albums.success,
      albumData: state.albums.album,
      error: state.albums.error,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      console.log(albumData);
      if (!albumData) {
        dispatch(fetchAlbum(id));
      }
    }
  }, [isEditing, id, albumData, dispatch]);

  const yupShape = {
    name: yup.string().required(),
    artists: yup.array().of(yup.object()).min(1),
    genres: yup.array().of(yup.object()).min(1),
    songs: yup.array().of(yup.object()).min(0),
    owner: yup.object({
      value: string(),
      label: string()
    }).required()
  };

  if(!albumData || !albumData.img) {
    yupShape.file = yup.mixed().test('file-test', 'Please pick a file', (value, context) => {
      return (value && value.length === 1);
    });
  }

  const schema = yup.object().shape(yupShape);

  const [createRedirect, setCreateRedirect] = useState(null);
  useEffect(() => {
    if(success) {
      if(id) {
        dispatch(fetchAlbum(id));
      } else {
        setCreateRedirect(`/albums/${albumData.id}`);
      }
    }
  }, [success]);

  const redirect = (isEditing && error) && (
    <Redirect to={paths.ALBUMS} />
  );

  const editAlbumMessage = useFormatMessage('Album.editAlbum');

  const newAlbumMessage = useFormatMessage('Album.newAlbum');

  const onSubmitHandler = (value) => {
    const newAlbum = {
      ...value,
      artists: value.artists.map(selected => selected.value),
      genres: value.genres.map(selected => selected.value),
      ownerId: value.owner.value,
      songs: (value.songs) ? value.songs : [],
      file: value?.file[0] || null,
      isEditing,
      id,
    };

    if (isEditing) {
      dispatch(modifyAlbum(newAlbum));
    } else {
      dispatch(createAlbum(newAlbum));
    }
  };

  return (
    <>
      {createRedirect && (
          <Redirect to={createRedirect} />
      )}
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editAlbumMessage : newAlbumMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !albumData ? (
          <ClipLoader />
        ) : (
          <AlbumForm
            isEditing={isEditing}
            album={
              isEditing
                ? { ...albumData, availableFrom: new Date(albumData?.availableFrom?.seconds ? albumData?.availableFrom?.seconds * 1000 : albumData?.availableFrom)}
                : {
                    name: '',
                    artists: [],
                    genres: [],
                    songs: [],
                    owner: {},
                    availableFrom: new Date(),
                    createdAt: new Date().toDateString(),
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default Album;
