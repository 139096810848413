import React, { useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import { createOwner, modifyOwner, fetchOwner } from 'state/actions/owners';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';
import OwnerForm from 'components/OwnerForm';

const Owner = () => {
    const { id } = useParams();

    const isEditing = useMemo(() => !!id, [id]);

    const { success, artistData, error } = useSelector(
        (state) => ({
            success: state.owners.success,
            artistData: state.owners.owner,
            error: state.owners.error,
        }),
        shallowEqual
    );

    const dispatch = useDispatch();

    useEffect(() => {
        if (isEditing) {
            if (!artistData) {
                dispatch(fetchOwner(id));
            }
        }
    }, [isEditing, id, artistData, dispatch]);

    const yupShape = {
        name: yup.string().required(),
        users: yup.array().of(yup.object()),
        isErrArtist: yup.boolean()
    };

    if (!artistData || !artistData.img) {
        yupShape.file = yup.mixed().test('file-test', 'Please pick a file', (value, context) => {
            return (value && value.length === 1);
        });
    }

    const schema = yup.object().shape(yupShape);

    const redirect = ((isEditing && error) || success) && (
        <Redirect to={paths.OWNERS} />
    );

    const editArtistMessage = useFormatMessage('Owner.editOwner');

    const newArtistMessage = useFormatMessage('Owner.addOwner');

    const onSubmitHandler = (value) => {
        const newArtist = {
            ...value,
            file: value?.file[0] || null,
            isEditing,
            recordLabel: value?.recordLabel ?? false,
            id,
        };

        if (isEditing) {
            dispatch(modifyOwner(newArtist));
        } else {
            dispatch(createOwner(newArtist));
        }
    };

    return (
        <>
            {redirect}
            <section className="hero is-hero-bar">
                <div className="hero-body">
                    <h1 className="title">
                        {isEditing ? editArtistMessage : newArtistMessage}
                    </h1>
                </div>
            </section>
            <section className="section is-main-section">
                {isEditing && !artistData ? (
                    <ClipLoader />
                ) : (
                    <OwnerForm
                        isEditing={isEditing}
                        owner={
                            isEditing
                                ? artistData
                                : {
                                    name: '',
                                    createdAt: new Date(),
                                }
                        }
                        onSubmitHandler={onSubmitHandler}
                        schema={schema}
                    />
                )}
            </section>
            <section className="section is-main-section">
                <h1 className="title">metadata</h1>
                <pre style={{whiteSpace: 'pre-wrap'}}>{JSON.stringify(artistData)}</pre>
            </section>
        </>
    );
};

export default Owner;
