import firebase from 'firebase.js';

const getFirestoreRef = (path) => firebase.firestore().collection(path);

export const fetchDocument = async (collection, id) => {
    const document = await getFirestoreRef(collection).doc(id).get();
    if (!document.exists) {
        return null;
    }

    return {id: document.id, ...document.data()};
};

export const fetchDocuments = (collection, ids) => {
    const unique = ids.filter((id, index, self) => {
        return self.indexOf(id) === index;
    });

    const ref = getFirestoreRef(collection);
    return Promise.all(unique.map((id) => {
        return ref.doc(id).get();
    })).then((snapshots) => {
        return snapshots.map((snapshot) => {
            return {id: snapshot.id, ...snapshot.data()};
        });
    });
};


export const fetchCollection = async (collection, options = {}) => {
    const data = [];
    let baseQuery = getFirestoreRef(collection);

    if (options.queries) {
        const {queries} = options;
        queries.forEach(({attribute, operator, value}) => {
            baseQuery = baseQuery.where(attribute, operator, value);
        });
    }


    if (options.sort) {
        const {attribute, order} = options.sort;
        baseQuery = baseQuery.orderBy(attribute, order);
    }

    if(options.startAfter) {
        baseQuery = baseQuery.startAfter(options.startAfter);
        if(options.pageSize) {
            baseQuery = baseQuery.limit(options.pageSize);
        }
    } else if(options.endBefore) {
        baseQuery = baseQuery.endBefore(options.endBefore);
        if(options.pageSize) {
            baseQuery = baseQuery.limitToLast(options.pageSize);
        }
    } else if(options.pageSize) {
        baseQuery = baseQuery.limit(options.pageSize);
    }

    (await baseQuery.get()).forEach((doc) =>
        data.push({id: doc.id, ...doc.data()})
    );

    return data;
};

export const deleteDocument = (collection, id) => {
    return getFirestoreRef(collection).doc(id).delete();
};

export const createDocument = (collection, id, values) => {
    if (id === null) {
        return getFirestoreRef(collection).add(values);
    }
    return getFirestoreRef(collection).doc(id).set(values);
};

export const updateDocument = (collection, id, values) => {
    return getFirestoreRef(collection).doc(id).update(values);
};
