import {createAction} from 'redux-act';
import {toast} from 'react-toastify';

import {firebaseError, getImgThumb} from 'utils';
import firebase from 'firebase.js';
import {checkUserData} from './auth';
import {
    fetchCollection,
    fetchDocument,
    fetchDocuments,
    createDocument,
    deleteDocument,
    updateDocument,
} from '../api';
import {searchIndex} from "../../service/algolia";
import SongStore from "../../service/SongStore";

export const APPROVALS_SONGS_FETCH_DATA_INIT = createAction('APPROVALS_SONGS_FETCH_DATA_INIT');
export const APPROVALS_SONGS_FETCH_DATA_SUCCESS = createAction('APPROVALS_SONGS_FETCH_DATA_SUCCESS');
export const APPROVALS_SONG_FETCH_DATA_SUCCESS = createAction('APPROVALS_SONG_FETCH_DATA_SUCCESS');
export const APPROVALS_SONGS_FETCH_DATA_FAIL = createAction('APPROVALS_SONGS_FETCH_DATA_FAIL');

export const APPROVALS_SONGS_MODIFY_SONG_INIT = createAction('APPROVALS_SONGS_MODIFY_SONG_INIT');
export const APPROVALS_SONGS_MODIFY_SONG_SUCCESS = createAction('APPROVALS_SONGS_MODIFY_SONG_SUCCESS');
export const APPROVALS_SONGS_MODIFY_SONG_FAIL = createAction('APPROVALS_SONGS_MODIFY_v_FAIL');

export const APPROVALS_SONGS_CLEAN_UP = createAction('APPROVALS_SONGS_CLEAN_UP');

export const APPROVALS_SONGS_CLEAR_DATA_LOGOUT = createAction('APPROVALS_SONGS_CLEAR_DATA_LOGOUT');

export const paginateApprovalsSongs = (pageSize, search, direction, attribute) => {
    return async (dispatch, getState) => {
        const prevSongs = [...getState().approvalSongs.data];
        const prevTotal = getState().approvalSongs.total;
        const prevPage = getState().approvalSongs.page;
        let newPage = (direction === 'init') ? 1 : (prevPage || 1);
        if (direction === 'next') {
            newPage += 1;
        } else if (direction === 'prev') {
            newPage -= 1;
        }

        const prevOrder = getState().approvalSongs.sortConfig.order;
        const prevAttribute = getState().approvalSongs.sortConfig.attribute;

        const newAttribute = attribute || prevAttribute;
        let newOrder = 'asc';
        if (attribute === prevAttribute) {
            newOrder = prevOrder === 'asc' ? 'desc' : 'asc';
        }
        const newSortConfig = {
            attribute: newAttribute,
            order: newOrder
        };

        dispatch(checkUserData());
        dispatch(APPROVALS_SONGS_FETCH_DATA_INIT());

        let approvalSongs;
        let total = prevTotal;
        try {
            if (search) {
                const res = await searchIndex('songs', search, newPage - 1, pageSize);
                const ids = res.hits.map((hit) => hit.objectID);
                const baseSongs = await fetchDocuments('songs', ids);
                approvalSongs = baseSongs.filter((song) => 'approved' in song);
                total = res.nbHits;
            } else if (direction === 'next') {
                approvalSongs = await fetchCollection('songs', {
                    /* sort: newSortConfig, */
                    startAfter: prevSongs.pop().name,
                    pageSize,
                    queries: [
                      {
                        attribute: 'approved',
                        operator: '!=',
                        value: "",
                      }
                    ],
                });
            } else if (direction === 'prev') {
                approvalSongs = await fetchCollection('songs', {
                    /* sort: newSortConfig, */
                    endBefore: prevSongs.shift().name,
                    pageSize,
                    queries: [
                      {
                        attribute: 'approved',
                        operator: '!=',
                        value: "",
                      }
                    ],
                });
            } else {
                approvalSongs = await fetchCollection('songs', {
                    /* sort: newSortConfig, */
                    pageSize,
                    queries: [
                      {
                        attribute: 'approved',
                        operator: '!=',
                        value: "",
                      }
                    ],
                });
                total = await firebase.firestore().collection('songs').get().then((counter) => {
                  return counter.size;
                });
            }
        } catch (error) {
            toast.error(error);
            return dispatch(APPROVALS_SONGS_FETCH_DATA_FAIL({error}));
        }

        return dispatch(
            APPROVALS_SONGS_FETCH_DATA_SUCCESS({
                data: approvalSongs,
                total,
                page: newPage,
                sortConfig: newSortConfig
            })
        );
    };
};

export const fetchSong = (id) => {
    return async (dispatch, getState) => {
        dispatch(checkUserData());
        dispatch(APPROVALS_SONGS_FETCH_DATA_INIT());

        let song;
        try {
            song = await fetchDocument('songs', id);
            song.artists = await fetchDocuments('artists', song.artists);
        } catch (error) {
            toast.error(error);
            return dispatch(APPROVALS_SONGS_FETCH_DATA_FAIL({error}));
        }

        if (!song) {
            const errorMessage = 'Song not available';
            toast.error(errorMessage);
            return dispatch(APPROVALS_SONGS_FETCH_DATA_FAIL({error: errorMessage}));
        }

        return dispatch(
            APPROVALS_SONG_FETCH_DATA_SUCCESS({
                song,
            })
        );
    };
};

export const fetchSongs = (songId = '') => {
    return async (dispatch, getState) => {
        dispatch(checkUserData());

        dispatch(APPROVALS_SONGS_FETCH_DATA_INIT());

        if (songId) {
            let song;
            try {
                song = await fetchDocument('songs', songId);
                song.artists = await fetchDocuments('artists', song.artists);
            } catch (error) {
                toast.error(error);
                return dispatch(APPROVALS_SONGS_FETCH_DATA_FAIL({error}));
            }

            if (!song) {
                const errorMessage = 'Song not available';
                toast.error(errorMessage);
                return dispatch(APPROVALS_SONGS_FETCH_DATA_FAIL({error: errorMessage}));
            }

            const songs = getState().songs.data;
            songs.push(song);

            return dispatch(
              APPROVALS_SONGS_FETCH_DATA_SUCCESS({
                    data: songs,
                })
            );
        }

        let songs;
        try {
            songs = await fetchCollection('songs');
            const artistIds = [];
            await songs.forEach((song) => {
                artistIds.push(...song.artists);
            });

            const artists = await fetchDocuments('artists', artistIds);

            songs = songs.map((song) => {
                return {
                    ...song,
                    artists: artists.filter((artist) => {
                        return song.artists.indexOf(artist.id) !== -1;
                    }),
                };
            });
        } catch (error) {
            console.log(error);
            toast.error(error);
            return dispatch(APPROVALS_SONGS_FETCH_DATA_FAIL({error}));
        }

        return dispatch(
            APPROVALS_SONGS_FETCH_DATA_SUCCESS({
                data: songs,
            })
        );
    };
};


export const clearApprovalsSongsDataLogout = () => {
    return (dispatch) => {
        dispatch(APPROVALS_SONGS_CLEAR_DATA_LOGOUT());
    };
};

export const modifySong = (newData) => {
    return async (dispatch, getState) => {
        dispatch(APPROVALS_SONGS_MODIFY_SONG_INIT());
        const {locale} = getState().preferences;
        const currentSong = getState().approvalSongs.song;

        if (currentSong.id !== newData.id) {
            return dispatch(
              APPROVALS_SONGS_MODIFY_SONG_FAIL({
                    error: 'Technical error',
                })
            );
        }

        let songData;
        try {
            songData = await SongStore.modifySong(currentSong, newData);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                APPROVALS_SONGS_MODIFY_SONG_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('Song updated successfully');

        return dispatch(APPROVALS_SONGS_MODIFY_SONG_SUCCESS({
            song: {
                ...songData,
                artists: await fetchDocuments('artists', songData.artists),
            },
            id: songData.id,
        }));
    };
};

export const setApproved = async (song) => {
  if (!song.id) return;
  await updateDocument('songs', song.id, { approved: true });

  toast.success('Song approved successfully');
};

export const removeApproved = async (song) => {
  if (!song.id) return;
  await updateDocument('songs', song.id, { approved: false });

  toast.success('Approved removed successfully');
};


export const setExplicit = async (song) => {
  if (!song.id) return;
  await updateDocument('songs', song.id, { explicit: true });

  toast.success('Song approved successfully');
};

export const removeExplicit = async (song) => {
  if (!song.id) return;
  await updateDocument('songs', song.id, { explicit: false });

  toast.success('Approved removed successfully');
};

export const approvalsSongsCleanUp = () => (dispatch) => dispatch(APPROVALS_SONGS_CLEAN_UP());
