import { useFormatMessage } from 'hooks';
import React from 'react';
import SongStatisticsExport from './SongStatisticsExport';
import './style.scss';
import UsersExport from './UsersExport';
import ArtistsExport from "./ArtistsExport";

const Export = () => {
  return (
    <>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">{useFormatMessage('Export.export')}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="tile is-ancestor">
          <div className="tile is-parent is-5">
            <div className="tile is-child">
              <UsersExport />
            </div>
          </div>
          <div className="tile is-parent is-5">
            <div className="tile is-child">
              <SongStatisticsExport />
            </div>
          </div>
        </div>
        <div className="tile is-ancestor">
          <div className="tile is-parent is-5">
            <div className="tile is-child">
              <ArtistsExport />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Export;
