import React, { useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import paths from 'pages/Router/paths';
import * as yup from 'yup';
import { useFormatMessage } from 'hooks';
import { fetchTransaction, modifyTransaction } from 'state/actions/transactions';
import TransactionForm from 'components/TransactionForm';

const Transaction = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { stateb, success, transactionData, error } = useSelector(
    (state) => ({
      stateb: state,
      success: state.transactions.success,
      transactionData: state.transactions.transaction,
      error: state.transactions.error,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      if (!transactionData) {
        dispatch(fetchTransaction(id));
      }
    }
  }, [isEditing, id, transactionData, dispatch]);

  const yupShape = {
    status: yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
  };

  const schema = yup.object().shape(yupShape);

  const redirect = ((isEditing && error) || success) && (
    <Redirect to={paths.TRANSACTIONS} />
  );

  const editTransactionMessage = useFormatMessage('Transactions.editTransaction');

  const newTransactionMessage = useFormatMessage('Transactions.editTransaction');

  const onSubmitHandler = (value) => {
    const { status } = value;
    const newTransaction = {
      id,
      status: status.value,
    };
    
    if (isEditing) {
      dispatch(modifyTransaction(newTransaction));
    } /* else {
      dispatch(createPlaylist(newPlaylist));
    } */
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editTransactionMessage : newTransactionMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !transactionData ? (
          <ClipLoader />
        ) : (
          <TransactionForm
            isEditing={isEditing}
            transaction={transactionData}
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default Transaction;
