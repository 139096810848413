import React, { useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import GenreForm from 'components/GenreForm';
import { createGenre, modifyGenre, fetchGenre } from 'state/actions/genres';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';

const Genre = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success, genreData, error } = useSelector(
    (state) => ({
      success: state.genres.success,
      genreData: state.genres.data.find((genre) => genre.id === id),
      error: state.genres.error,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      if (!genreData) {
        dispatch(fetchGenre(id));
      }
    }
  }, [isEditing, id, genreData, dispatch]);

  const yupShape = {
    name: yup.string().required(),
  };

  const schema = yup.object().shape(yupShape);

  const redirect = ((isEditing && error) || success) && (
    <Redirect to={paths.GENRES} />
  );

  const editGenreMessage = useFormatMessage('Genre.editGenre');
  const newGenreMessage = useFormatMessage('Genre.editGenre');

  const onSubmitHandler = (value) => {
    const newGenre = {
      ...value,
      file: value?.file[0] || null,
      isEditing,
      id,
    };

    if (isEditing) {
      dispatch(modifyGenre(newGenre));
    } else {
      dispatch(createGenre(newGenre));
    }
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editGenreMessage : newGenreMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !genreData ? (
          <ClipLoader />
        ) : (
          <GenreForm
            isEditing={isEditing}
            genre={
              isEditing
                ? genreData
                : {
                    name: '',
                    createdAt: new Date().toDateString(),
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default Genre;
