import { useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import SongApprovals from 'pages/Songs/SongApprovals';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import AlbumApprovals from './AlbumApprovals';

const Approvals = () => {
    const {isAdmin} = useSelector(
        (state) => {
            return ({
                isAdmin: state.auth.userData.isAdmin,
            });
        },
        shallowEqual
    );
    const [tab, setTab] = useState(1);

    const redirect = !isAdmin && <Redirect to={paths.ROOT}/>;
    return (
        <>
            {redirect}
            <section className="hero is-hero-bar">
                <div className="hero-body">
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <h1 className="title">{useFormatMessage('Approvals.approvals')}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <div className="buttons">
                                    <button className={`button ${tab === 1 ? 'is-info' : 'is-light'}`} onClick={() => setTab(1)}>{useFormatMessage('Approvals.albums')}</button>
                                    <button className={`button ${tab === 2 ? 'is-info' : 'is-light'}`} onClick={() => setTab(2)}>{useFormatMessage('Approvals.recordings')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {tab === 1 && <AlbumApprovals />}
            {tab === 2 && <SongApprovals />}
        </>
    );
};

export default Approvals;
