import algoliasearch from 'algoliasearch';

const {REACT_APP_ALGOLIA_APP_ID, REACT_APP_ALGOLIA_SEARCH_API_KEY} = process.env;
const client = algoliasearch(REACT_APP_ALGOLIA_APP_ID, REACT_APP_ALGOLIA_SEARCH_API_KEY);

const searchIndex = (indexName, search, page = 0, limit = 10) => {
    const index = client.initIndex(indexName);
    const params = {
        hitsPerPage: limit,
        page,
    };
    return index.search(search, params);
};

export {
    searchIndex
};

