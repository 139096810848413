/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';

import {useFormatMessage} from 'hooks';
import classes from './Table.module.scss';
import './TableMobile.css';

const RemoteTable = ({
    columns,
    data,
    onPageSizeChange,
    onPageChange,
    onSort,
    pages,
    page = 1,
    pageSize = 10
}) => {
    const perPage = useFormatMessage('Table.perPage');

    const sort = (field) => {
      if(onSort) {
        onSort(field);
      }
    };

    return (
        <div className="table-wrapper">
            <table
                className="table is-striped has-mobile-cards is-hoverable"
                role="table"
            >
                <thead>
                    <tr role="row">
                        {columns.map((col, index) => {
                            const rowKey = `row-${index}`;
                            return (
                                <th colSpan={1} role="columnheader" key={rowKey} onClick={() => sort(col.field)}>
                                  {col.field ? (
                                    <div className="th-wrap has-pointer">{col.name}
                                      <span className="icon is-pulled-right"><i className='mdi mdi-sort'/></span>
                                    </div>
                                  ) : (
                                      <div className="th-wrap has-pointer">{col.name}</div>
                                  )}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => {
                        const rowKey = `row-${index}`;
                        return (
                            <tr role="row" key={rowKey}>
                                {item.map((value, jIndex) => {
                                    const cellKey = `cell-${jIndex}`;
                                    return (
                                        <td role="cell" className={value.className} key={cellKey}>
                                            {value.value}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className={classNames('level', classes.level)}>
                <div className="level-left">
                    <div className="control">
                        <span className="select">
                          <select
                              value={pageSize}
                              onChange={(e) => onPageSizeChange(Number(e.target.value))}
                          >
                            {[50, 100, 500].map((size) => (
                                <option key={size} value={size}>
                                    {size} {perPage}
                                </option>
                            ))}
                          </select>
                        </span>
                    </div>
                </div>
                <div className="level-right">
                    <div className="level-item">
                        <nav className="pagination">
                            <button
                                type="button"
                                className="pagination-link pagination-previous"
                                onClick={() => onPageChange(page - 1, 'prev')}
                                disabled={page <= 1}
                            >
                                <span className="icon" aria-hidden="true">
                                  <i className="mdi mdi-chevron-left mdi-24px"/>
                                </span>
                            </button>
                            <button
                                type="button"
                                className="pagination-link pagination-next"
                                onClick={() => onPageChange(page + 1, 'next')}
                                disabled={page >= pages}
                            >
                                <span className="icon" aria-hidden="true">
                                  <i className="mdi mdi-chevron-right mdi-24px"/>
                                </span>
                            </button>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RemoteTable;
