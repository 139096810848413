import {createReducer} from 'redux-act';

import {
    FEATURED_FETCH_DATA_INIT,
    FEATURED_FETCH_DATA_SUCCESS,
    FEATURED_FETCH_DATA_FAIL,
    FEATURED_CREATE_FEATURED_INIT,
    FEATURED_CREATE_FEATURED_SUCCESS,
    FEATURED_CREATE_FEATURED_FAIL,
    FEATURED_CLEAN_UP
} from 'state/actions/featured';

const initialState = {
    data: [],
    featured: null,
    total: 0,
    page: 1,
    loading: false,
    error: null,
    success: false,
    deleted: false,
    sortConfig: {
      attribute: 'name',
      order: 'asc'
    }
};

export const featuredReducer = createReducer(
    {
        [FEATURED_FETCH_DATA_INIT]: () => ({
            ...initialState,
            loading: true,
        }),
        [FEATURED_FETCH_DATA_SUCCESS]: (state, payload) => ({
            ...state,
            data: payload.data,
            total: payload.total,
            page: payload.page,
            sortConfig: payload.sortConfig,
            loading: false,
            error: null,
        }),
        [FEATURED_FETCH_DATA_FAIL]: (state, payload) => ({
            ...state,
            loading: false,
            error: payload.error,
        }),

        [FEATURED_CREATE_FEATURED_INIT]: (state) => ({
            ...state,
            loading: true,
        }),
        [FEATURED_CREATE_FEATURED_SUCCESS]: (state, payload) => ({
            ...state,
            data: state.data.concat(payload.featured),
            loading: false,
            error: null,
            success: true,
        }),
        [FEATURED_CREATE_FEATURED_FAIL]: (state, payload) => ({
            ...state,
            loading: false,
            error: payload.error,
        }),

        [FEATURED_CLEAN_UP()]: (state) => ({
            ...state,
            loading: false,
            error: null,
            success: false,
            deleted: false,
        }),
    },
    initialState
);
