/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers';

import paths from 'pages/Router/paths';
import { genresCleanUp } from 'state/actions/genres';
import { useFormatMessage } from 'hooks';
import ErrorMessage from 'components/ErrorMessage';
import { getImgThumb } from 'utils';

import './GenreForm.scss';

const GenreForm = ({ genre, onSubmitHandler, schema }) => {
  const { loading, success } = useSelector(
    (state) => ({
      loading: state.genres.loading,
      success: state.genres.success,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const { register, handleSubmit, errors, setValue, watch } = useForm({
    defaultValues: { ...genre },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (success) {
       setValue('file', null);
    }
    return () => dispatch(genresCleanUp());
  }, [dispatch, success, setValue]);


  const imagePreviewUrl =
    watch('file') && watch('file')[0]
      ? URL.createObjectURL(watch('file')[0])
      : getImgThumb(genre.img, true);

  const goBackMessage = useFormatMessage('GenreForm.goBack');

  const pickAnotherFileMessage = useFormatMessage('GenreForm.pickAnotherFile');
  const pickFileMessage = useFormatMessage('GenreForm.pickFile');


  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                {useFormatMessage('GenreForm.genreInfo')}
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('GenreForm.name')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="name"
                          id="name"
                          className={classNames('input', {
                            'is-danger': errors.name,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.name && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field has-check is-horizontal">
                  <div className="field-label">
                    <label className="label">{useFormatMessage('GenreForm.adminOnly')}</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="field">
                        <div className="control">
                          <label className="b-checkbox checkbox">
                            <input
                                type="checkbox"
                                name="adminOnly"
                                ref={register}
                            />
                            <span className="check is-primary" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('UserForm.logo')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="file has-name">
                        <label className="file-label">
                          <input
                            className="file-input"
                            type="file"
                            name="file"
                            ref={register}
                            accept="image/*"
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="mdi mdi-upload" />
                            </span>
                            <span className="file-label">
                              {watch('file') && watch('file').file
                                ? pickAnotherFileMessage
                                : pickFileMessage}
                            </span>
                          </span>
                          <span className="file-name">
                            {watch('file') && watch('file')[0]?.name}
                          </span>
                        </label>
                      </div>
                      {imagePreviewUrl && (
                          <div className="is-user-avatar image has-max-width">
                            <img
                                className="user-avatar"
                                src={imagePreviewUrl}
                                alt="User profile logo preview"
                            />
                          </div>
                      )}
                    </div>
                  </div>
                </div>
                {errors.file && (
                    <div className="field is-horizontal">
                      <div className="field-label is-normal" />
                      <div className="field-body">
                        <ErrorMessage />
                      </div>
                    </div>
                )}

                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('GenreForm.submit')}</span>
                          </button>
                        </div>
                        <Link to={paths.GENRES} className="button">
                          {goBackMessage}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

GenreForm.propTypes = {
  genre: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    img: PropTypes.string,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
};

GenreForm.defaultProps = {

};

export default GenreForm;
