/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useFormatMessage } from 'hooks';
import ErrorMessage from 'components/ErrorMessage';
import AsyncSelect from 'react-select/async';
import DatePicker from 'react-datepicker';
import {string} from "yup";

import { fetchCollection } from '../../state/api';
import { searchIndex } from '../../service/algolia';

import './SongForm.scss';
import { songsCleanUp } from '../../state/actions/songs';

const SongForm = ({ song, onSubmitHandler, goBackHandler }) => {
  const { loading, success } = useSelector(
    (state) => ({
      loading: state.songs.loading,
      success: state.songs.success
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const yupShape = {
    name: yup.string().required(),
    artists: yup.array().of(yup.object()).min(1),
    isrc: yup.string().matches(/^$|^[A-Z]{2}[0-9A-Z]{3}\d{2}\d{5}$/),
    owner: yup.object({
      value: string(),
      label: string()
    }).required()
  };

  if (!song.url) {
    yupShape.songFile = yup.mixed().test('file-test', 'Please pick a file', (value, context) => {
      return (value && value.length === 1);
    });
  }

  const schema = yup.object().shape(yupShape);

  const initialOwnerValue = song.ownerId ?? (song.owner?.id ?? null);
  const initialOwnerLabel = (song.owner?.name ?? song.owner?.email) ?? (song.ownerId ?? null);

  const { register, handleSubmit, errors, setValue, watch, control } = useForm({
    defaultValues: {
      ...song,
      artists: song.artists.map(artist => {
        return { value: artist.id, label: artist.name };
      }),
      owner: { value: initialOwnerValue, label: initialOwnerLabel }
    },
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (success) {
      setValue('songFile', null);
    }
    return () => dispatch(songsCleanUp());
  }, [dispatch, success, setValue]);

  const goBackMessage = useFormatMessage('SongForm.goBack');
  const pickAnotherFileMessage = useFormatMessage('SongForm.pickAnotherFile');
  const pickFileMessage = useFormatMessage('SongForm.pickFile');

  const loadArtists = (searchTerm) => {
    const options = {};
    if (searchTerm) {
      return searchIndex('artists', searchTerm, 0, 50).then((res) => {
        return res.hits.map((hit) => {
          return {
            value: hit.objectID,
            label: hit.name
          };
        });
      });
    }
    return fetchCollection('artists', { pageSize: 50 }).then((artists) => {
      return artists.map((artist) => {
        return {
          value: artist.id,
          label: artist.name
        };
      });
    });
  };

  const loadUsers = (searchTerm) => {
    const options = {};
    if (searchTerm) {
      return searchIndex('users', searchTerm, 0, 50).then((res) => {
        return res.hits.map((hit) => {
          return {
            value: hit.objectID,
            label: hit.name
          };
        });
      });
    }
    return fetchCollection('users', { pageSize: 50 }).then((users) => {
      return users.map((user) => {
        return {
          value: user.id,
          label: user.name
        };
      });
    });
  };

  const loadOwners = () => {
    return fetchCollection('owners', {sort: {attribute: 'name', order: 'asc'}}).then((owners) => {
      const options = owners.map((owner) => ({
        value: owner.id,
        label: `${owner.name ? `${owner.name} - ` : ''}${owner.email}`,
        name: owner.name
      }));

      const sortedOwners = options.sort((a, b) => {
        if (a.name === null) {
          return 1;
        }

        if (b.name === null) {
          return -1;
        }

        if (a.name === b.name) {
          return 0;
        }

        return a < b ? -1 : 1;
      });

      return sortedOwners;
    });
  };

  return (
    <>
      <div className='tile is-ancestor'>
        <div className='tile is-parent'>
          <div className='card tile is-child'>
            <header className='card-header'>
              <p className='card-header-title'>
                <span className='icon'>
                  <i className='mdi mdi-account-edit default' />
                </span>
                {useFormatMessage('SongForm.songInfo')}
              </p>
            </header>
            <div className='card-content'>
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className='field is-horizontal'>
                  <div className='field-label is-normal'>
                    <label className='label'>
                      {useFormatMessage('SongForm.name')}
                    </label>
                  </div>
                  <div className='field-body'>
                    <div className='field'>
                      <div className='control'>
                        <input
                          name='name'
                          id='name'
                          className={classNames('input', {
                            'is-danger': errors.name
                          })}
                          ref={register}
                          type='text'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.name && (
                  <div className='field is-horizontal'>
                    <div className='field-label is-normal' />
                    <div className='field-body'>
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className='field is-horizontal'>
                  <div className='field-label is-normal'>
                    <label className='label'>
                      {useFormatMessage('SongForm.artists')}
                    </label>
                  </div>
                  <div className='field-body'>
                    <div className='field'>
                      <div className='control'>
                        <Controller
                          as={AsyncSelect}
                          name='artists'
                          control={control}
                          loadOptions={loadArtists}
                          defaultOptions
                          isMulti
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.artists && (
                  <div className='field is-horizontal'>
                    <div className='field-label is-normal' />
                    <div className='field-body'>
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className='field is-horizontal'>
                  <div className='field-label is-normal'>
                    <label className='label'>
                      {useFormatMessage('SongForm.recordingYear')}
                    </label>
                  </div>
                  <div className='field-body'>
                    <div className='field'>
                      <div className='control'>
                        <Controller
                          name='recordingYear'
                          control={control}
                          defaultOptions
                          defaultValue={null}
                          render={({ onChange, value }) => (
                            <DatePicker
                              selected={!value ? null : new Date(`${value}`)}
                              showYearPicker
                              dateFormat='yyyy'
                              onChange={date => onChange(!date ? null : date.getFullYear())}
                              maxDate={new Date()}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='field is-horizontal'>
                  <div className='field-label is-normal'>
                    <label className='label'>
                      {useFormatMessage('SongForm.isrc')}
                    </label>
                  </div>
                  <div className='field-body'>
                    <div className='field'>
                      <div className='control'>
                        <input
                          name='isrc'
                          id='isrc'
                          className={classNames('input', {
                            'is-danger': errors.isrc
                          })}
                          ref={register}
                          type='text'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.isrc && (
                  <div className='field is-horizontal'>
                    <div className='field-label is-normal' />
                    <div className='field-body'>
                      <ErrorMessage text={useFormatMessage('invalidIsrc')} />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('AlbumForm.owner')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <Controller
                            as={AsyncSelect}
                            name="owner"
                            control={control}
                            loadOptions={loadOwners}
                            defaultOptions
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.owner && (
                    <div className="field is-horizontal">
                      <div className="field-label is-normal"/>
                      <div className="field-body">
                        <ErrorMessage/>
                      </div>
                    </div>
                )}

                <hr />

                <div className='field is-horizontal'>
                  <div className='field-label is-normal'>
                    <label className='label'>
                      {useFormatMessage('SongForm.song')}
                    </label>
                  </div>
                  <div className='field-body'>
                    <div className='field'>
                      <div className='file has-name'>
                        <label className='file-label'>
                          <input
                            className='file-input'
                            type='file'
                            name='songFile'
                            ref={register}
                            accept='audio/*'
                          />
                          <span className='file-cta'>
                            <span className='file-icon'>
                              <i className='mdi mdi-upload' />
                            </span>
                            <span className='file-label'>
                              {watch('songFile') && watch('songFile').file
                                ? pickAnotherFileMessage
                                : pickFileMessage}
                            </span>
                          </span>
                          <span className='file-name'>
                            {watch('songFile') && watch('songFile')[0]?.name}
                          </span>
                        </label>
                      </div>
                      {song.url && (
                        <div className='is-user-avatar image'>
                          <audio controls>
                            <source src={`${song.url}?alt=media`} />
                            Audio playing not supported
                          </audio>
                          <a href={`${song.url}?alt=media`} target='_blank' rel='noreferrer'>Open uploaded song</a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {errors.songFile && (
                  <div className='field is-horizontal'>
                    <div className='field-label is-normal' />
                    <div className='field-body'>
                      <ErrorMessage />
                    </div>
                  </div>
                )}
                <hr />
                <div className='field is-horizontal'>
                  <div className='field-label' />
                  <div className='field-body'>
                    <div className='field'>
                      <div className='field is-grouped'>
                        <div className='control'>
                          <button
                            type='submit'
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('SongForm.submit')}</span>
                          </button>
                        </div>
                        <button
                          className='button'
                          type='button'
                          onClick={goBackHandler}>
                          <span>{goBackMessage}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SongForm.propTypes = {
  song: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    artists: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })),
    recordingYear: PropTypes.number,
    url: PropTypes.string,
    isrc: PropTypes.string,
    owner: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  goBackHandler: PropTypes.func.isRequired
};

SongForm.defaultProps = {};

export default SongForm;
