import {createAction} from 'redux-act';
import {toast} from 'react-toastify';

import {firebaseError} from 'utils';
import firebase from 'firebase.js';
import {checkUserData} from './auth';
import {
    fetchCollection,
    fetchDocument,
    fetchDocuments,
    updateDocument,
} from '../api';
import {searchIndex} from "../../service/algolia";

export const TRANSACTIONS_FETCH_DATA_INIT = createAction('TRANSACTIONS_FETCH_DATA_INIT');
export const TRANSACTIONS_FETCH_DATA_SUCCESS = createAction('TRANSACTIONS_FETCH_DATA_SUCCESS');
export const TRANSACTIONS_FETCH_DATA_FAIL = createAction('TRANSACTIONS_FETCH_DATA_FAIL');

export const TRANSACTION_FETCH_DATA_SUCCESS = createAction('TRANSACTION_FETCH_DATA_SUCCESS');

export const TRANSACTIONS_MODIFY_TRANSACTION_INIT = createAction('TRANSACTIONS_MODIFY_TRANSACTION_INIT');
export const TRANSACTIONS_MODIFY_TRANSACTION_SUCCESS = createAction('TRANSACTIONS_MODIFY_TRANSACTION_SUCCESS');
export const TRANSACTIONS_MODIFY_TRANSACTION_FAIL = createAction('TRANSACTIONS_MODIFY_TRANSACTION_FAIL');

export const TRANSACTIONS_CLEAN_UP = createAction('TRANSACTIONS_CLEAN_UP');

export const TRANSACTIONS_CLEAR_DATA_LOGOUT = createAction('TRANSACTIONS_CLEAR_DATA_LOGOUT');

export const paginateTransactions = (pageSize, search, direction, attribute, order) => {
    return async (dispatch, getState) => {
        const prevTransactions = [...getState().transactions.data];
        const prevTotal = getState().transactions.total;
        const prevPage = getState().transactions.page;
        let newPage = (direction === 'init') ? 1 : (prevPage || 1);
        if (direction === 'next') {
            newPage += 1;
        } else if (direction === 'prev') {
            newPage -= 1;
        }

        const prevOrder = getState().transactions.sortConfig.order;
        const prevAttribute = getState().transactions.sortConfig.attribute;

        const newAttribute = attribute || prevAttribute;
        let newOrder = order || prevOrder;
        if (attribute === prevAttribute) {
            newOrder = prevOrder === 'asc' ? 'desc' : 'asc';
        }
        const newSortConfig = {
            attribute: newAttribute,
            order: newOrder
        };

        dispatch(checkUserData());
        dispatch(TRANSACTIONS_FETCH_DATA_INIT());

        let transactions;
        let total = prevTotal;
        try {
            if (search) {
                const res = await searchIndex('transactions', search, newPage - 1, pageSize);
                const ids = res.hits.map((hit) => hit.objectID);

                transactions = await fetchDocuments('transactions', ids);
                total = res.nbHits;
            } else if (direction === 'next') {
              transactions = await fetchCollection('transactions', {
                    sort: newSortConfig,
                    startAfter: prevTransactions.pop().createdAt,
                    pageSize,
                });
            } else if (direction === 'prev') {
              transactions = await fetchCollection('transactions', {
                    sort: newSortConfig,
                    endBefore: prevTransactions.shift().createdAt,
                    pageSize,
                });
            } else {
              transactions = await fetchCollection('transactions', {
                    sort: newSortConfig,
                    pageSize,
                });
                total = await firebase.firestore().collection('transactions').get().then((counter) => {
                  return counter.size;
                });
            }
        } catch (error) {
            toast.error(error);
            return dispatch(TRANSACTIONS_FETCH_DATA_FAIL({error}));
        }

        return dispatch(
            TRANSACTIONS_FETCH_DATA_SUCCESS({
                data: transactions,
                total,
                page: newPage,
                sortConfig: newSortConfig
            })
        );
    };
};

export const fetchTransaction = (id) => {
    return async (dispatch, getState) => {
        dispatch(checkUserData());
        dispatch(TRANSACTIONS_FETCH_DATA_INIT());

        let transaction;
        try {
           transaction = await fetchDocument('transactions', id);
        } catch (error) {
            toast.error(error);
            return dispatch(TRANSACTIONS_FETCH_DATA_FAIL({error}));
        }

        if (!transaction) {
            const errorMessage = 'Transaction not available';
            toast.error(errorMessage);
            return dispatch(TRANSACTIONS_FETCH_DATA_FAIL({error: errorMessage}));
        }

        return dispatch(
            TRANSACTION_FETCH_DATA_SUCCESS({
                transaction
            })
        );
    };
};

export const clearTransactionsDataLogout = () => {
    return (dispatch) => {
        dispatch(TRANSACTIONS_CLEAR_DATA_LOGOUT());
    };
};

export const modifyTransaction = ({ id, status }) => {
    return async (dispatch, getState) => {
        dispatch(TRANSACTIONS_MODIFY_TRANSACTION_INIT());
        const {locale} = getState().preferences;
        const currentTransaction = getState().transactions.transaction;
        if(currentTransaction.id !== id) {
            return dispatch(
                TRANSACTIONS_MODIFY_TRANSACTION_FAIL({
                    error: 'Technical error',
                })
            );
        }

        const transactionData = {
            status
        };

        try {
            await updateDocument('transactions', id, transactionData);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                TRANSACTIONS_MODIFY_TRANSACTION_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('Transaction updated successfully');

        return dispatch(TRANSACTIONS_FETCH_DATA_SUCCESS({
            transaction: {
                ...transactionData,
            },
            id
        }));
    };
};

export const transactionsCleanUp = () => (dispatch) => dispatch(TRANSACTIONS_CLEAN_UP());
