/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Modal.scss';

const Modal = ({isOpen, children, onClose, title, footer = null}) => {
    const [container] = useState(document.createElement('div'));

    useEffect(() => {
        document.body.appendChild(container);
        return () => {
            document.body.removeChild(container);
        };
    }, []);

    const body = (
        <div className={classNames('modal', {'is-active': isOpen})}>
            <div className="modal-background" onClick={onClose} />
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{title}</p>
                    <button className="delete" aria-label="close" onClick={onClose} />
                </header>
                <section className="modal-card-body">
                    {children}
                </section>
                <footer className="modal-card-foot">
                    {footer}
                </footer>
            </div>
        </div>
    );

    return (isOpen) ? ReactDOM.createPortal(body, container) : null;
};

Modal.propTypes = {
};

Modal.defaultProps = {};

export default Modal;
