import classNames from "classnames";
import RemoteTable from 'components/RemoteTable';
import { useFormatMessage, usePrevious } from 'hooks';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { paginateApprovalsSongs, approvalsSongsCleanUp, setApproved, removeApproved, setExplicit, removeExplicit } from 'state/actions/songs_approvals';
import { getImgThumb } from "utils";
import classes from "../Users/Users.module.scss";

const SongApprovals = () => {
    const {approvalSongsList, approvalSongsTotal, page, isAdmin, error, loading} = useSelector(
        (state) => {
            return ({
                approvalSongsList: state.approvalSongs.data,
                approvalSongsTotal: state.approvalSongs.total,
                page: state.approvalSongs.page,
                isAdmin: state.auth.userData.isAdmin,
                error: state.approvalSongs.error,
                loading: state.approvalSongs.loading,
            });
        },
        shallowEqual
    );

    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [pageSize, setPageSize] = useState(50);
    const prev = usePrevious({page, pageSize, search});

    const handlePageChange = (newPage, movement) => {
        dispatch(paginateApprovalsSongs(pageSize, search, movement));
    };

    const handleSort = (attribute, order) => {
        if(attribute && order) {
            dispatch(paginateApprovalsSongs(pageSize, search, 'init', attribute, order));
        }
    };

    const pullInitial = () => dispatch(paginateApprovalsSongs(pageSize, search, 'init'));

    useEffect(() => {
        if (isAdmin) {
            pullInitial();
        }

        return () => dispatch(approvalsSongsCleanUp());
    }, [dispatch, isAdmin]);

    useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        if(prev === undefined) {
            return; // Only run once we have actually searched
        }

        const timeout = setTimeout(() => {
            pullInitial();
        }, 500);
        setSearchTimeout(timeout);
    }, [search]);

    useEffect(() => {
        if (prev && pageSize !== prev.pageSize) {
            pullInitial();
        }
    }, [pageSize]);

    const onHandleApprove = async (song) => {
        if (!song.approved) await setApproved(song);
        else await removeApproved(song);
        pullInitial();
    };


    const onHandleExplicit = async (song) => {
        if (!song.explicit) await setExplicit(song);
        else await removeExplicit(song);
        pullInitial();
    };

    const cols = [
        {
            name: 'Name',
            field: 'name',
        },
        {
            name: 'Created',
            field: 'createdAt',
        },
        {
            name: '',
        },
        {
            name: 'Approve',
        },
        {
            name: 'Explicit content',
        },
    ];

    const tableData = approvalSongsList.map((song) => {
        return [
            {
                value: song.name,
            },
            {
                value: song.createdAt ? new Date(song.createdAt.seconds * 1000).toLocaleDateString() : 'N/A',
            },
            {
                value: (
                    <audio controls preload="none">
                        <source src={`${song.url}?alt=media`} />
                        <track src={`${song.url}?alt=media`} kind="captions" srcLang="en" label="english_captions" />
                        Audio playing not supported
                    </audio>
                )
            },
            {
                className: 'is-actions-cell',
                value: (<>
                    <div className="buttons is-left">
                        <button
                            type="button"
                            className="button is-small"
                            onClick={() => onHandleApprove(song)}
                        >
                            <span className="icon is-small">
                                <i className={song.approved ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                            </span>
                        </button>
                    </div>
                </>),
            },
          {
            className: 'is-actions-cell',
            value: (<>
              <div className="buttons is-left">
                <button
                  type="button"
                  className="button is-small"
                  onClick={() => onHandleExplicit(song)}
                >
                            <span className="icon is-small">
                                <i className={song.explicit ? "mdi mdi-star" : "mdi mdi-star-outline"} />
                            </span>
                </button>
              </div>
            </>),
          },
        ];
    });

    return (
        <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
            <header className="card-header">
                <p className={classNames('card-header-title', classes.tableHeader)}>
                    <span>{useFormatMessage('Playlists.search')}</span>
                    <input
                        type="text"
                        className="input"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </p>
            </header>
            <div className="b-table">
                {loading ? <ClipLoader /> : <RemoteTable
                    columns={cols}
                    data={tableData}
                    onPageSizeChange={(size) => setPageSize(size)}
                    onPageChange={(pageNo, movementType) => handlePageChange(pageNo, movementType)}
                    onSort={(attribute) => handleSort(attribute, 'desc')}
                    pages={Math.ceil(approvalSongsTotal / pageSize)}
                    page={page}
                    pageSize={pageSize}
                />}
                {error && 'Show error'}
            </div>
        </div>
    </section>
    );
};

export default SongApprovals;
