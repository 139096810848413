import { createReducer } from 'redux-act';

import {
  OWNERS_FETCH_DATA_INIT,
  OWNER_FETCH_DATA_SUCCESS,
  OWNERS_FETCH_DATA_SUCCESS,
  OWNERS_FETCH_DATA_FAIL,
  OWNERS_DELETE_OWNER_INIT,
  OWNERS_DELETE_OWNER_SUCCESS,
  OWNERS_DELETE_OWNER_FAIL,
  OWNERS_CREATE_OWNER_INIT,
  OWNERS_CREATE_OWNER_SUCCESS,
  OWNERS_CREATE_OWNER_FAIL,
  OWNERS_MODIFY_OWNER_INIT,
  OWNERS_MODIFY_OWNER_SUCCESS,
  OWNERS_MODIFY_OWNER_FAIL,
  OWNERS_CLEAN_UP,
  OWNERS_CLEAR_DATA_LOGOUT,
} from 'state/actions/owners';

const initialState = {
  data: [],
  owner: null,
  total: 0,
  page: 1,
  loading: false,
  error: null,
  success: false,
  deleted: false,
  sortConfig: {
    attribute: 'name',
    order: 'asc'
  }
};

export const ownersReducer = createReducer(
  {
    [OWNERS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [OWNERS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data,
      total: payload.total,
      page: payload.page,
      sortConfig: payload.sortConfig,
      loading: false,
      error: null,
    }),
    [OWNERS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [OWNERS_DELETE_OWNER_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [OWNER_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      owner: payload.owner,
      loading: false,
      error: null,
    }),
    [OWNERS_DELETE_OWNER_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [OWNERS_DELETE_OWNER_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [OWNERS_CREATE_OWNER_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [OWNERS_CREATE_OWNER_SUCCESS]: (state, payload) => ({
      ...state,
      owner: payload.owner,
      data: state.data.concat(payload.owner),
      loading: false,
      error: null,
      success: true,
    }),
    [OWNERS_CREATE_OWNER_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [OWNERS_MODIFY_OWNER_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [OWNERS_MODIFY_OWNER_SUCCESS]: (state, payload) => ({
      ...state,
      data: !state.data
        ? []
        : state.data.map((elem) => {
            if (elem.id === payload.id) {
              return payload;
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
    }),
    [OWNERS_MODIFY_OWNER_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [OWNERS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [OWNERS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
