import {createAction} from 'redux-act';

import {firebaseError, getImgThumb} from 'utils';
import firebase from 'firebase.js';
import {toast} from "react-toastify";
import {checkUserData} from './auth';
import {
    fetchCollection,
    fetchDocument,
    fetchDocuments,
    createDocument,
    deleteDocument,
    updateDocument,
} from '../api';
import {searchIndex} from "../../service/algolia";
import AlbumStore from "../../service/AlbumStore";

// ALBUMS
export const APPROVALS_ALBUMS_FETCH_DATA_INIT = createAction('APPROVALS_ALBUMS_FETCH_DATA_INIT');
export const APPROVALS_ALBUMS_FETCH_DATA_SUCCESS = createAction('APPROVALS_ALBUMS_FETCH_DATA_SUCCESS');
export const APPROVALS_ALBUM_FETCH_DATA_SUCCESS = createAction('APPROVALS_ALBUM_FETCH_DATA_SUCCESS');
export const APPROVALS_ALBUMS_FETCH_DATA_FAIL = createAction('APPROVALS_ALBUMS_FETCH_DATA_FAIL');

export const APPROVALS_ALBUMS_MODIFY_ALBUM_INIT = createAction('APPROVALS_ALBUMS_MODIFY_ALBUM_INIT');
export const APPROVALS_ALBUMS_MODIFY_ALBUM_SUCCESS = createAction('APPROVALS_ALBUMS_MODIFY_ALBUM_SUCCESS');
export const APPROVALS_ALBUMS_MODIFY_ALBUM_FAIL = createAction('APPROVALS_ALBUMS_MODIFY_v_FAIL');

export const APPROVALS_ALBUMS_CLEAN_UP = createAction('APPROVALS_ALBUMS_CLEAN_UP');
export const APPROVALS_ALBUMS_CLEAR_DATA_LOGOUT = createAction('APPROVALS_ALBUMS_CLEAR_DATA_LOGOUT');


export const paginateApprovalsAlbums = (pageSize, search, direction, attribute) => {
    return async (dispatch, getState) => {
        const prevAlbums = [...getState().approvalAlbums.data];
        const prevTotal = getState().approvalAlbums.total;
        const prevPage = getState().approvalAlbums.page;
        let newPage = (direction === 'init') ? 1 : (prevPage || 1);
        if (direction === 'next') {
            newPage += 1;
        } else if (direction === 'prev') {
            newPage -= 1;
        }

        const prevOrder = getState().approvalAlbums.sortConfig.order;
        const prevAttribute = getState().approvalAlbums.sortConfig.attribute;

        const newAttribute = attribute || prevAttribute;

        let newOrder = 'asc';
        if (attribute === prevAttribute) {
            newOrder = (prevOrder === 'asc') ? 'desc' : 'asc';
        }
        const newSortConfig = {
            attribute: newAttribute,
            order: newOrder
        };

        dispatch(checkUserData());
        dispatch(APPROVALS_ALBUMS_FETCH_DATA_INIT());

        let approvalAlbums;
        let total = prevTotal;
        try {
            if (search) {
                const res = await searchIndex('albums', search, newPage - 1, pageSize);
                const ids = res.hits.map((hit) => hit.objectID);
                const baseAlbums = await fetchDocuments('albums', ids);
                approvalAlbums = baseAlbums.filter((album) => 'approved' in album);
                total = res.nbHits;
            } else if (direction === 'next') {
              console.log("next", prevAlbums);
              const data = [];
              const baseQuery =  firebase.firestore().collection('albums').orderBy('createdAt', 'desc')
                .startAfter(prevAlbums.pop().createdAt).limit(pageSize);

              (await baseQuery.get()).forEach((doc) =>
                data.push({id: doc.id, ...doc.data()})
              );

              approvalAlbums = data;
            } else if (direction === 'prev') {
              console.log("prev", prevAlbums);
              const data = [];
              const baseQuery =  firebase.firestore().collection('albums')
                .orderBy('createdAt', 'desc')
                .endBefore(prevAlbums.shift().createdAt).limit(pageSize);

              (await baseQuery.get()).forEach((doc) =>
                data.push({id: doc.id, ...doc.data()})
              );

              approvalAlbums = data;

            } else {
              const data = [];
              const baseQuery =  firebase.firestore().collection('albums').orderBy('createdAt', 'desc').limit(pageSize);

              (await baseQuery.get()).forEach((doc) =>
                data.push({id: doc.id, ...doc.data()})
              );

              approvalAlbums = data;

              /*
              approvalAlbums = await fetchCollection('albums', {

                pageSize,
                queries: [
                  {
                    attribute: 'approved',
                    operator: '!=',
                    value: "",
                  },
                ],
              });



               */
              total = await firebase.firestore().collection('albums').get().then((counter) => {
                  return counter.size;
                });
            }
        } catch (error) {
            toast.error(error);
            return dispatch(APPROVALS_ALBUMS_FETCH_DATA_FAIL({error}));
        }

        return dispatch(
            APPROVALS_ALBUMS_FETCH_DATA_SUCCESS({
                data: approvalAlbums,
                total,
                page: newPage,
                sortConfig: newSortConfig
            })
        );
    };
};

export const fetchAlbum = (id) => {
    return async (dispatch, getState) => {
        dispatch(checkUserData());
        dispatch(APPROVALS_ALBUMS_FETCH_DATA_INIT());

        let album;
        try {
            album = await fetchDocument('albums', id);
            // TODO optimize these kinds of fetches to a local cache?
            album.artists = (album.artists) ? await fetchDocuments('artists', album.artists) : [];
            album.genres = (album.genres) ? await fetchDocuments('genres', album.genres) : [];
            album.songs = (album.songs) ? await fetchDocuments('songs', album.songs) : [];
            album.songs = await Promise.all(album.songs.map(async (song) => {
                return {
                    ...song,
                    artists: (song.artists) ? await fetchDocuments('artists', song.artists) : [],
                };
            }));
        } catch (error) {
            toast.error(error);
            return dispatch(APPROVALS_ALBUMS_FETCH_DATA_FAIL({error}));
        }

        if (!album) {
            const errorMessage = 'Album not available';
            toast.error(errorMessage);
            return dispatch(APPROVALS_ALBUMS_FETCH_DATA_FAIL({error: errorMessage}));
        }

        return dispatch(
            APPROVALS_ALBUM_FETCH_DATA_SUCCESS({
                album,
            })
        );
    };
};

export const clearApprovalsAlbumsDataLogout = () => {
    return (dispatch) => {
        dispatch(APPROVALS_ALBUMS_CLEAR_DATA_LOGOUT());
    };
};

export const modifyApprovalsAlbum = (newData) => {
    return async (dispatch, getState) => {
        dispatch(APPROVALS_ALBUMS_MODIFY_ALBUM_INIT());
        const {locale} = getState().preferences;
        const currentAlbum = getState().albums.album;

        if (currentAlbum.id !== newData.id) {
            return dispatch(
                APPROVALS_ALBUMS_MODIFY_ALBUM_FAIL({
                    error: 'Technical error',
                })
            );
        }

        let albumData;
        try {
            albumData = await AlbumStore.modifyAlbum(currentAlbum, newData);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                APPROVALS_ALBUMS_MODIFY_ALBUM_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('Album updated successfully');

        return dispatch(APPROVALS_ALBUMS_MODIFY_ALBUM_SUCCESS({
            album: {
                ...albumData,
                artists: await fetchDocuments('artists', albumData.artists),
                genres: await fetchDocuments('genres', albumData.genres),
                songs: await fetchDocuments('songs', albumData.songs),
            },
            id: albumData.id,
        }));
    };
};

export const setApproved = async (album) => {
  if (!album.id || !album.songs) return;
  await updateDocument('albums', album.id, { approved: true });

  await Promise.all(album.songs.map(async (songId) => {
    const songRef = firebase.firestore().collection('songs').doc(songId);
    const song = await songRef.get();
    if (!song.exists) return Promise.resolve();

    const tokenFunction = firebase
      .functions()
      .httpsCallable('httpsToken');

    tokenFunction({
      songId: song.id,
      type: 'Song upload'
    }).then(result => {
      console.log(result);
    });

    return songRef.update({ approved: true });
  }));

  toast.success('Album approved successfully');
};

export const removeApproved = async (album) => {
  if (!album.id || !album.songs) return;
  await updateDocument('albums', album.id, { approved: false });

  await Promise.all(album.songs.map(async (songId) => {
    const songRef = firebase.firestore().collection('songs').doc(songId);
    const song = await songRef.get();
    if (!song.exists) return Promise.resolve();
    return songRef.update({ approved: false });
  }));

  toast.success('Approved removed successfully');
};

export const approvalsAlbumsCleanUp = () => (dispatch) => dispatch(APPROVALS_ALBUMS_CLEAN_UP());
