import {createAction} from 'redux-act';

import {firebaseError, getImgThumb} from 'utils';
import firebase from 'firebase.js';
import {toast} from "react-toastify";
import {checkUserData} from './auth';
import {
    fetchCollection,
    fetchDocument,
    fetchDocuments,
    createDocument,
    deleteDocument,
    updateDocument,
} from '../api';
import {searchIndex} from "../../service/algolia";
import AlbumStore from "../../service/AlbumStore";

export const ALBUMS_FETCH_DATA_INIT = createAction('ALBUMS_FETCH_DATA_INIT');
export const ALBUMS_FETCH_DATA_SUCCESS = createAction('ALBUMS_FETCH_DATA_SUCCESS');
export const ALBUM_FETCH_DATA_SUCCESS = createAction('ALBUM_FETCH_DATA_SUCCESS');
export const ALBUMS_FETCH_DATA_FAIL = createAction('ALBUMS_FETCH_DATA_FAIL');

export const ALBUMS_DELETE_ALBUM_INIT = createAction('ALBUMS_DELETE_ALBUM_INIT');
export const ALBUMS_DELETE_ALBUM_SUCCESS = createAction('ALBUMS_DELETE_ALBUM_SUCCESS');
export const ALBUMS_DELETE_ALBUM_FAIL = createAction('ALBUMS_DELETE_ALBUM_FAIL');

export const ALBUMS_CREATE_ALBUM_INIT = createAction('ALBUMS_CREATE_ALBUM_INIT');
export const ALBUMS_CREATE_ALBUM_SUCCESS = createAction('ALBUMS_CREATE_ALBUM_SUCCESS');
export const ALBUMS_CREATE_ALBUM_FAIL = createAction('ALBUMS_CREATE_ALBUM_FAIL');

export const ALBUMS_MODIFY_ALBUM_INIT = createAction('ALBUMS_MODIFY_ALBUM_INIT');
export const ALBUMS_MODIFY_ALBUM_SUCCESS = createAction('ALBUMS_MODIFY_ALBUM_SUCCESS');
export const ALBUMS_MODIFY_ALBUM_FAIL = createAction('ALBUMS_MODIFY_v_FAIL');

export const ALBUMS_CLEAN_UP = createAction('ALBUMS_CLEAN_UP');

export const ALBUMS_CLEAR_DATA_LOGOUT = createAction('ALBUMS_CLEAR_DATA_LOGOUT');

export const paginateAlbums = (pageSize, search, direction, attribute) => {
    return async (dispatch, getState) => {
        const prevAlbums = [...getState().albums.data];
        const prevTotal = getState().albums.total;
        const prevPage = getState().albums.page;
        let newPage = (direction === 'init') ? 1 : (prevPage || 1);
        if (direction === 'next') {
            newPage += 1;
        } else if (direction === 'prev') {
            newPage -= 1;
        }

        const prevOrder = getState().albums.sortConfig.order;
        const prevAttribute = getState().albums.sortConfig.attribute;

        const newAttribute = attribute || prevAttribute;

        let newOrder = 'asc';
        if (attribute === prevAttribute) {
            newOrder = (prevOrder === 'asc') ? 'desc' : 'asc';
        }
        const newSortConfig = {
            attribute: newAttribute,
            order: newOrder
        };

        dispatch(checkUserData());
        dispatch(ALBUMS_FETCH_DATA_INIT());

        let albums;
        let total = prevTotal;
        try {
            if (search) {
                const res = await searchIndex('albums', search, newPage - 1, pageSize);
                const ids = res.hits.map((hit) => hit.objectID);

                albums = await fetchDocuments('albums', ids);
                total = res.nbHits;
            } else if (direction === 'next') {
                albums = await fetchCollection('albums', {
                    sort: newSortConfig,
                    startAfter: prevAlbums.pop().name,
                    pageSize,
                });
            } else if (direction === 'prev') {
                albums = await fetchCollection('albums', {
                    sort: newSortConfig,
                    endBefore: prevAlbums.shift().name,
                    pageSize,
                });
            } else {
                albums = await fetchCollection('albums', {
                    sort: newSortConfig,
                    pageSize,
                });

                total = await firebase.firestore().collection('albums').get().then((counter) => {
                  return counter.size;
                });
            }
        } catch (error) {
            toast.error(error);
            return dispatch(ALBUMS_FETCH_DATA_FAIL({error}));
        }

        return dispatch(
            ALBUMS_FETCH_DATA_SUCCESS({
                data: albums,
                total,
                page: newPage,
                sortConfig: newSortConfig
            })
        );
    };
};

export const fetchAlbum = (id) => {
    return async (dispatch, getState) => {
        dispatch(checkUserData());
        dispatch(ALBUMS_FETCH_DATA_INIT());

        let album;
        try {
            album = await fetchDocument('albums', id);
            // TODO optimize these kinds of fetches to a local cache?
            album.artists = (album.artists) ? await fetchDocuments('artists', album.artists) : [];
            album.genres = (album.genres) ? await fetchDocuments('genres', album.genres) : [];
            album.owner = (album.ownerId) ? await fetchDocument('owners', album.ownerId): null;
            album.songs = (album.songs) ? await fetchDocuments('songs', album.songs) : [];
            album.songs = await Promise.all(album.songs.map(async (song) => {
                return {
                    ...song,
                    artists: (song.artists) ? await fetchDocuments('artists', song.artists) : [],
                };
            }));
        } catch (error) {
            toast.error(error);
            return dispatch(ALBUMS_FETCH_DATA_FAIL({error}));
        }

        if (!album) {
            const errorMessage = 'Album not available';
            toast.error(errorMessage);
            return dispatch(ALBUMS_FETCH_DATA_FAIL({error: errorMessage}));
        }

        return dispatch(
            ALBUM_FETCH_DATA_SUCCESS({
                album,
            })
        );
    };
};

const deleteFile = (oldImage) => {
    if (!oldImage.includes('firebasestorage')) {
        return null;
    }

    const imagePath = oldImage.split('albums%2F').pop();
    const thumbPath = getImgThumb(oldImage).split('albums%2F').pop();

    return Promise.all([
        firebase.storage().ref(`albums/${imagePath}`).delete(),
        firebase.storage().ref(`albums/${thumbPath}`).delete()
    ]);
};

export const deleteAlbum = (id) => {
    return async (dispatch, getState) => {
        dispatch(ALBUMS_DELETE_ALBUM_INIT());
        const {locale} = getState().preferences;
        const {img, url} = getState()
            .albums.data.filter((album) => album.id === id)
            .pop();

        const deleteFileTask = img ? deleteFile(img) : null;
        const deleteAlbumTask = deleteDocument('albums', id);

        try {
            await Promise.all([deleteFileTask, deleteAlbumTask]);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                ALBUMS_DELETE_ALBUM_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('The album was deleted.');
        return dispatch(ALBUMS_DELETE_ALBUM_SUCCESS({id}));
    };
};

export const clearAlbumsDataLogout = () => {
    return (dispatch) => {
        dispatch(ALBUMS_CLEAR_DATA_LOGOUT());
    };
};

const uploadFile = (uid, file) => {
    const storageRef = firebase.storage().ref();
    const fileExtension = file.name.split('.').pop();
    const fileName = `${uid}.${fileExtension}`;
    return storageRef.child(`albums/${fileName}`).put(file);
};

const getImageUrl = (uid, file) => {
    const fileExtension = file.name.split('.').pop();
    const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;
    return `${bucketUrl}/o/albums%2F${uid}.${fileExtension}`;
};

export const createAlbum = ({name, file, artists, genres, songs, availableFrom, ownerId}) => {
    return async (dispatch, getState) => {
        dispatch(ALBUMS_CREATE_ALBUM_INIT());
        const {locale} = getState().preferences;

        const albumData = {
            name,
            artists: artists.map((artist) => {
                return (artist.id) ? artist.id : artist;
            }),
            genres: genres.map((genre) => {
                return (genre.id) ? genre.id : genre;
            }),
            songs: songs.map((song) => {
                return (song.id) ? song.id : song;
            }),
            createdAt: new Date(),
            isSingle: songs.length <= 1,
            availableFrom,
            approved: false,
            ownerId
        };
        let response;
        try {
            response = await createDocument('albums', null, albumData);

            if (file) {
                const imgUrl = getImageUrl(response.id, file);
                await uploadFile(response.id, file);

                albumData.img = imgUrl;
                await updateDocument('albums', response.id, albumData);
            }

            albumData.id = response.id;
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                ALBUMS_CREATE_ALBUM_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('Album created successfully');
        return dispatch(ALBUMS_CREATE_ALBUM_SUCCESS({
            album: {
                ...albumData,
                artists: await fetchDocuments('artists', albumData.artists),
                genres: await fetchDocuments('genres', albumData.genres),
                songs: await fetchDocuments('songs', albumData.songs),
                owner: await fetchDocument('owners', albumData.ownerId)
            }
        }));
    };
};

export const modifyAlbum = (newData) => {
    return async (dispatch, getState) => {
        dispatch(ALBUMS_MODIFY_ALBUM_INIT());
        const {locale} = getState().preferences;
        const currentAlbum = getState().albums.album;

        if (currentAlbum.id !== newData.id) {
            return dispatch(
                ALBUMS_MODIFY_ALBUM_FAIL({
                    error: 'Technical error',
                })
            );
        }

        let albumData;
        try {
            albumData = await AlbumStore.modifyAlbum(currentAlbum, newData);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                ALBUMS_MODIFY_ALBUM_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('Album updated successfully');

        return dispatch(ALBUMS_MODIFY_ALBUM_SUCCESS({
            album: {
                ...albumData,
                artists: await fetchDocuments('artists', albumData.artists),
                genres: await fetchDocuments('genres', albumData.genres),
                songs: await fetchDocuments('songs', albumData.songs),
                owner: await fetchDocument('owners', albumData.ownerId)
            },
            id: albumData.id,
        }));
    };
};

export const albumsCleanUp = () => (dispatch) => dispatch(ALBUMS_CLEAN_UP());
