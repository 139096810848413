import firebase from 'firebase';
import React, { useState } from 'react';
import ExportTile from './ExportTile';

const baseCSVHeaders = ['songName', 'album', 'artists', 'genres', 'periodFrom', 'periodTo', 'app', 'source', 'totalTimeListened', 'fullSubscriptionShare', 'feeShare', 'fonoShare', 'eelShare', 'eauShare'];
const EELCSVHeaders = ['songName', 'album', 'artists', 'genres', 'periodFrom', 'periodTo', 'totalTimeListened', 'moneyGenerated'];

const SongStatisticsExport = () => {
    const [csvHeaders, setCSVHeaders] = useState(baseCSVHeaders);
    const [data, setData] = useState(null);
    const [isFetchingStatistics, setIsFetchingStatistics] = useState(false);
    const [exportSelection, setExportSelection] = useState('All');

    const getSongStatistics = async (startDate, endDate) => {
        setIsFetchingStatistics(true);
        setData(null);
        setCSVHeaders(baseCSVHeaders);

        const parts = startDate.toLocaleDateString().split('/');
        const start = `${parts[2]}-${parts[1]}-${parts[0]}`;
        const { docs } = await firebase.firestore().collection('songTransactions').where('periodFrom', '==', start).get();
        const transactions = [];
        const tempGenres = {};

        const totals = { songName: '', album: '', artists: '', genres: '', periodFrom: '', periodTo: '', app: '', source: '', totalTimeListened: 0, fullSubscriptionShare: 0, feeShare: 0, fonoShare: 0, eelShare: 0, eauShare: 0 };
        // eslint-disable-next-line no-restricted-syntax
        for (const doc of docs) {
            const { songName, album, artists, periodFrom, periodTo, app, fullSubscriptionShare, source, totalTimeListened, userId } = doc.data();
            // eslint-disable-next-line no-await-in-loop
            const docAlbum = await firebase.firestore().collection('albums').doc(album.id).get();
            const genreIds = docAlbum.data().genres;
            const docGenres = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const genreId of genreIds) {
                if (tempGenres[genreId]) docGenres.push(tempGenres[genreId]);
                else {
                    // eslint-disable-next-line no-await-in-loop
                    const g = await firebase.firestore().collection('genres').doc(genreId).get();
                    if (g.exists) {
                        tempGenres[genreId] = g.data().name;
                        docGenres.push(g.data().name);
                    }
                }
            }
            totals.genres = [...(totals.genres.length > 0 && totals.genres.split(', ')), ...docGenres].join(', ');
            const feeShare = fullSubscriptionShare * 2.99 / 100;
            const fonoShare = fullSubscriptionShare * (app === 'ios' ? 0.5 : 0.94) / 100;
            const eelShare = fullSubscriptionShare * 0.3 / 100;
            const eauShare = fullSubscriptionShare * 0.3 / 100;
            const record = { songName, album: album?.name, artists, periodFrom, periodTo, app, source, totalTimeListened, fullSubscriptionShare, feeShare, fonoShare, eelShare, eauShare };
            transactions.push(record);
            totals.totalTimeListened += totalTimeListened;
            totals.fullSubscriptionShare += fullSubscriptionShare;
            totals.feeShare += feeShare;
            totals.fonoShare += fonoShare;
            totals.eelShare += eelShare;
            totals.eauShare += eauShare;
        }
        transactions.push(totals);

        setIsFetchingStatistics(false);
        setData(transactions);
    };

    const getEELSongStatistics = async (startDate, endDate) => {
        setIsFetchingStatistics(true);
        setData(null);

        setCSVHeaders(EELCSVHeaders);


        const parts = startDate.toLocaleDateString().split('/');
        const start = `${parts[2]}-${parts[1]}-${parts[0]}`;
        const { docs } = await firebase.firestore().collection('songTransactions').where('periodFrom', '==', start).get();
        const transactions = [];

        const totals = { songName: '', album: '', artists: '', periodFrom: '', periodTo: '', totalTimeListened: 0, moneyGenerated: 0 };
        docs.forEach((doc) => {
            const { songName, album, artists, periodFrom, periodTo, fullSubscriptionShare, totalTimeListened } = doc.data();
            const eelShare = fullSubscriptionShare * 0.3 / 100;
            const money = eelShare * 2.99;
            totals.moneyGenerated += money;
            totals.totalTimeListened += totalTimeListened;
            const record = { songName, album: album?.name, artists, periodFrom, periodTo, totalTimeListened, moneyGenerated: money };
            transactions.push(record);
        });
        transactions.push(totals);

        setIsFetchingStatistics(false);
        setData(transactions);
    };

    const getEAUSongStatistics = async (startDate, endDate) => {
        setIsFetchingStatistics(true);
        setData(null);

        setCSVHeaders(EELCSVHeaders);


        const parts = startDate.toLocaleDateString().split('/');
        const start = `${parts[2]}-${parts[1]}-${parts[0]}`;
        const { docs } = await firebase.firestore().collection('songTransactions').where('periodFrom', '==', start).get();
        const transactions = [];

        const totals = { songName: '', album: '', artists: '', periodFrom: '', periodTo: '', totalTimeListened: 0, moneyGenerated: 0 };
        docs.forEach((doc) => {
            const { songName, album, artists, periodFrom, periodTo, fullSubscriptionShare, totalTimeListened } = doc.data();
            const eauShare = fullSubscriptionShare * 0.3 / 100;
            const money = eauShare * 2.99;
            totals.moneyGenerated += money;
            totals.totalTimeListened += totalTimeListened;
            const record = { songName, album: album?.name, artists, periodFrom, periodTo, totalTimeListened, moneyGenerated: money };
            transactions.push(record);
        });
        transactions.push(totals);

        setIsFetchingStatistics(false);
        setData(transactions);
    };

    return (
        <ExportTile
            title="Export song statistics"
            onFetch={(startDate, endDate) => {
                if (exportSelection === 'EEL') {
                    getEELSongStatistics(startDate, endDate);
                } else if (exportSelection === 'EAU') {
                    getEAUSongStatistics(startDate, endDate);
                } else getSongStatistics(startDate, endDate);
            }}
            isFetching={isFetchingStatistics}
            data={data}
            headers={csvHeaders}
            showMonthYearPicker
        >
            {/* <select onChange={(e) => setExportSelection(e.target.value)}>
                <option value="All">All</option>
                <option value="EEL">EEL</option>
                <option value="EAU">EAU</option>
            </select> */}
        </ExportTile>
    );
};

export default SongStatisticsExport;