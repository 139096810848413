import {createAction} from 'redux-act';
import {toast} from 'react-toastify';

import {firebaseError, getImgThumb} from 'utils';
import firebase from 'firebase.js';
import {checkUserData} from './auth';
import {
    fetchCollection,
    fetchDocuments,
    fetchDocument,
    createDocument,
    deleteDocument,
    updateDocument,
} from '../api';
import { searchIndex  } from '../../service/algolia';

export const ARTISTS_FETCH_DATA_INIT = createAction('ARTISTS_FETCH_DATA_INIT');
export const ARTIST_FETCH_DATA_SUCCESS = createAction('ARTIST_FETCH_DATA_SUCCESS');
export const ARTISTS_FETCH_DATA_SUCCESS = createAction('ARTISTS_FETCH_DATA_SUCCESS');
export const ARTISTS_FETCH_DATA_FAIL = createAction('ARTISTS_FETCH_DATA_FAIL');

export const ARTISTS_DELETE_ARTIST_INIT = createAction('ARTISTS_DELETE_ARTIST_INIT');
export const ARTISTS_DELETE_ARTIST_SUCCESS = createAction('ARTISTS_DELETE_ARTIST_SUCCESS');
export const ARTISTS_DELETE_ARTIST_FAIL = createAction('ARTISTS_DELETE_ARTIST_FAIL');

export const ARTISTS_CREATE_ARTIST_INIT = createAction('ARTISTS_CREATE_ARTIST_INIT');
export const ARTISTS_CREATE_ARTIST_SUCCESS = createAction('ARTISTS_CREATE_ARTIST_SUCCESS');
export const ARTISTS_CREATE_ARTIST_FAIL = createAction('ARTISTS_CREATE_ARTIST_FAIL');

export const ARTISTS_MODIFY_ARTIST_INIT = createAction('ARTISTS_MODIFY_ARTIST_INIT');
export const ARTISTS_MODIFY_ARTIST_SUCCESS = createAction('ARTISTS_MODIFY_ARTIST_SUCCESS');
export const ARTISTS_MODIFY_ARTIST_FAIL = createAction('ARTISTS_MODIFY_v_FAIL');

export const ARTISTS_CLEAN_UP = createAction('ARTISTS_CLEAN_UP');

export const ARTISTS_CLEAR_DATA_LOGOUT = createAction('ARTISTS_CLEAR_DATA_LOGOUT');

export const paginateArtists = (pageSize, search, direction, attribute) => {
    return async (dispatch, getState) => {
        const prevArtists = [...getState().artists.data];
        const prevTotal = getState().artists.total;
        const prevPage = getState().artists.page;
        let newPage = (direction === 'init') ? 1 : (prevPage || 1);
        if(direction === 'next') {
            newPage += 1;
        } else if(direction === 'prev') {
            newPage -= 1;
        }

        const prevOrder = getState().artists.sortConfig.order;
        const prevAttribute = getState().artists.sortConfig.attribute;

        const newAttribute = attribute || prevAttribute;
        let newOrder = 'asc';
        if (attribute === prevAttribute) {
            newOrder = prevOrder === 'asc' ? 'desc' : 'asc';
        }
        const newSortConfig = {
            attribute: newAttribute,
            order: newOrder
        };

        dispatch(checkUserData());
        dispatch(ARTISTS_FETCH_DATA_INIT());

        let artists;
        let total = prevTotal;
        try {
            if(search) {
                const res = await searchIndex('artists', search, newPage - 1, pageSize);
                const ids = res.hits.map((hit) => hit.objectID);

                artists = await fetchDocuments('artists', ids);
                total = res.nbHits;
            } else if(direction === 'next') {
                artists = await fetchCollection('artists', {
                    sort: newSortConfig,
                    startAfter: prevArtists.pop().name,
                    pageSize,
                });
            } else if(direction === 'prev') {
                artists = await fetchCollection('artists', {
                    sort: newSortConfig,
                    endBefore: prevArtists.shift().name,
                    pageSize,
                });
            } else {
                artists = await fetchCollection('artists', {
                    sort: newSortConfig,
                    pageSize,
                });
                total = await firebase.firestore().collection('artists').get().then((counter) => {
                  return counter.size;
                });
            }
        } catch (error) {
            toast.error(error);
            return dispatch(ARTISTS_FETCH_DATA_FAIL({error}));
        }

        return dispatch(
            ARTISTS_FETCH_DATA_SUCCESS({
                data: artists,
                total,
                page: newPage,
                sortConfig: newSortConfig
            })
        );
    };
};

export const fetchArtist = (artistId) => {
    return async (dispatch, getState) => {
        dispatch(checkUserData());

        dispatch(ARTISTS_FETCH_DATA_INIT());

        let artist;
        try {
            artist = await fetchDocument('artists', artistId);
            artist.users = (artist.users) ? await fetchDocuments('users', artist.users) : [];
        } catch (error) {
            toast.error(error);
            return dispatch(ARTISTS_FETCH_DATA_FAIL({error}));
        }

        if (!artist) {
            const errorMessage = 'Artist not available';
            toast.error(errorMessage);
            return dispatch(ARTISTS_FETCH_DATA_FAIL({error: errorMessage}));
        }

        return dispatch(
            ARTIST_FETCH_DATA_SUCCESS({
                artist,
            })
        );
    };
};

const deleteImage = (oldImage) => {
    if (!oldImage.includes('firebasestorage')) {
        return null;
    }

    const imagePath = oldImage.split('artists%2F').pop();
    const thumbPath = getImgThumb(oldImage).split('artists%2F').pop();

    return Promise.all([
        firebase.storage().ref(`artists/${imagePath}`).delete(),
        firebase.storage().ref(`artists/${thumbPath}`).delete()
    ]);
};

export const deleteArtist = (id) => {
    return async (dispatch, getState) => {
        dispatch(ARTISTS_DELETE_ARTIST_INIT());
        const {locale} = getState().preferences;
        const {img} = getState()
            .artists.data.filter((artist) => artist.id === id)
            .pop();

        const deleteImageTask = img ? deleteImage(img) : null;

        const deleteArtistTask = deleteDocument('artists', id);

        try {
            await Promise.all([deleteImageTask, deleteArtistTask]);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                ARTISTS_DELETE_ARTIST_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('The artist was deleted.');
        return dispatch(ARTISTS_DELETE_ARTIST_SUCCESS({id}));
    };
};

export const clearArtistsDataLogout = () => {
    return (dispatch) => {
        dispatch(ARTISTS_CLEAR_DATA_LOGOUT());
    };
};

const uploadImage = (uid, file) => {
    const storageRef = firebase.storage().ref();
    const fileExtension = file.name.split('.').pop();
    const fileName = `${uid}.${fileExtension}`;
    return storageRef.child(`artists/${fileName}`).put(file);
};

const getImageUrl = (uid, file) => {
    const fileExtension = file.name.split('.').pop();
    const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;
    return `${bucketUrl}/o/artists%2F${uid}.${fileExtension}`;
};

export const createArtist = ({name, file, users, country, source, description}) => {
    return async (dispatch, getState) => {
        dispatch(ARTISTS_CREATE_ARTIST_INIT());
        const {locale} = getState().preferences;
        const artistData = {
            name,
            createdAt: new Date(),
            users: users.map((user) => {
                return (user.id) ? user.id : user;
            }),
            source,
            country,
            description
        };
        let response;
        try {
            response = await createDocument('artists', null, artistData);

            if (file) {
                const imgUrl = getImageUrl(response.id, file);
                await uploadImage(response.id, file);

                artistData.img = imgUrl;
                await updateDocument('artists', response.id, artistData);
            }

            artistData.id = response.id;
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                ARTISTS_CREATE_ARTIST_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('Artist created successfully');
        return dispatch(ARTISTS_CREATE_ARTIST_SUCCESS({artist: artistData}));
    };
};

export const modifyArtist = ({
                                 id,
                                 name,
                                 file,
                                 users,
                                 source,
                                  country,
                                  description
                             }) => {
    return async (dispatch, getState) => {
        dispatch(ARTISTS_MODIFY_ARTIST_INIT());
        const {locale} = getState().preferences;
        const {img} = getState().artists.artist;
        let deleteImgTask;
        let uploadImgTask;
        let newImgUrl = null;
        if (file) {
            newImgUrl = getImageUrl(id, file);
            deleteImgTask = img && deleteImage(img);
            uploadImgTask = uploadImage(id, file);
        }

        const artistData = {
            name,
            img: newImgUrl || img,
            users: users.map((user) => {
                return (user.id) ? user.id : user;
            }),
            country,
            source,
            description
        };
        const updateArtistDbTask = updateDocument('artists', id, artistData);

        try {
            await Promise.all([deleteImgTask, uploadImgTask, updateArtistDbTask]);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                ARTISTS_MODIFY_ARTIST_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('Artist updated successfully');

        return dispatch(ARTISTS_MODIFY_ARTIST_SUCCESS({artist: artistData, id}));
    };
};

export const artistsCleanUp = () => (dispatch) => dispatch(ARTISTS_CLEAN_UP());
