import React, {useEffect, useState} from 'react';
import {useFormatMessage, usePrevious} from 'hooks';

import {DataGrid} from "@mui/x-data-grid";
import {fetchCollection, fetchDocument, updateDocument} from "../../state/api";
import useSearch from '../../hooks/useSearch';


const Withdrawals = () => {

    const {query, setQuery, page, setPage, pageSize, setPageSize } = useSearch();
    const [rows, setRows] = useState([]);
    const prev = usePrevious({page, pageSize, query});

    const getData = async () => {
        const data = await fetchCollection('transactions', {
            queries: [
                {
                    attribute: 'type',
                    operator: '==',
                    value: "ROYALTIES_OUT",
                }
            ],
        });
        setRows(data);
    };

    const acceptWithdrawal = async (id) => {
        await updateDocument('transactions', id, {status: 'Completed'});
        getData();
    };

    const rejectWithdrawal = async (id) => {
        await updateDocument('transactions', id, {status: 'Failed'});
        getData();
    };

    useEffect(() => {
            getData();
    }, []);


    const columns = [
        {
            field: 'recipient',
            headerName: 'Recipient',
            flex: 1,
        },
        {
            field: 'iban',
            headerName: 'IBAN',
            flex: 1,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
        },
        {
            field: 'createdAt',
            headerName: 'Created',
            flex: 1,
            renderCell: ({row}) => {
                return (row.createdAt && row.createdAt.seconds) ? new Date(row.createdAt.seconds * 1000).toLocaleDateString() : "-";
            }
        },
        {
            field: 'edit',
            headerName: '',
            flex: 1,
            renderCell: (transaction) => {
                return (
                    <>
                        {transaction.row.status === 'Pending' ? (
                            <div className="buttons is-right is-actions-cell">
                                <button
                                    type="button"
                                    className="button is-small is-primary"
                                    onClick={() => acceptWithdrawal(transaction.id)}
                                >
                                    ACCEPT
                                </button>
                                <button
                                    type="button"
                                    className="button is-small is-danger"
                                    onClick={() => rejectWithdrawal(transaction.id)}
                                >
                                    REJECT
                                </button>
                            </div>
                        ): (
                            <></>
                        )}

                    </>
                );
            },
            width: 70,
            sortable: false,
        }
    ];

    return (
        <>
            <section className="hero is-hero-bar">
                <div className="hero-body">
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <h1 className="title">{useFormatMessage('Withdrawals.withdrawals')} ({rows.length})</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section is-main-section">
                <div className="card has-table has-mobile-sort-spaced">
                    <div className="b-table">
                        <DataGrid
                            columns={columns}
                            rows={rows}
                            pageSize={pageSize}
                            onPageSizeChange={(size) => setPageSize(size)}
                            disableColumnMenu
                            disableColumnSelector
                            isRowSelectable={() => false}
                            isCellEditable={() => false}
                            disableSelectionOnClick
                            autoHeight
                            page={page}
                            onPageChange={(index) => setPage(index)}
                            sx={{
                                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                                    outline: "none"
                                }
                            }}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default Withdrawals;

