import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {Redirect, Link} from 'react-router-dom';
import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';

import {useFormatMessage, useFormatDate, usePrevious} from 'hooks';
import RemoteTable from 'components/RemoteTable';
import {paginateUsers, deleteUser, usersCleanUp} from 'state/actions/users';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import classes from './Users.module.scss';
import { paginateArtists } from '../../state/actions/artists';

const Users = () => {
    const {usersList, usersTotal, page, isAdmin, error, loading, deleted} = useSelector(
        (state) => ({
            usersList: state.users.data,
            usersTotal: state.users.total,
            page: state.users.page,
            isAdmin: state.auth.userData.isAdmin,
            error: state.users.error,
            loading: state.users.loading,
            deleted: state.users.deleted,
        }),
        shallowEqual
    );

    const [deleteModal, setDeleteModal] = useState({
        userId: null,
        isOpen: false,
    });

    const dispatch = useDispatch();

    const [search, setSearch] = useState('');
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [pageSize, setPageSize] = useState(50);
    const prev = usePrevious({page, pageSize, search});

    const handlePageChange = (newPage, movement) => {
        dispatch(paginateUsers(pageSize, search, movement));
    };

    const handleSort = (attribute) => {
        if(attribute) {
            dispatch(paginateUsers(pageSize, search, 'init', attribute));
        }
    };

    const pullInitial = () => dispatch(paginateUsers(pageSize, search, 'init'));

    useEffect(() => {
        if (isAdmin) {
            pullInitial();
        }

        return () => dispatch(usersCleanUp());
    }, [dispatch, isAdmin]);

    useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        if(prev === undefined) {
            return; // Only run once we have actually searched
        }

        const timeout = setTimeout(() => {
            pullInitial();
        }, 500);
        setSearchTimeout(timeout);
    }, [search]);

    useEffect(() => {
        if (prev && pageSize !== prev.pageSize) {
            pullInitial();
        }
    }, [pageSize]);

    useEffect(() => {
        if (deleted && !loading) {
            setDeleteModal((prevState) => ({
                userId: null,
                isOpen: !prevState.isOpen,
            }));
        }
    }, [deleted, loading]);

    const redirect = !isAdmin && <Redirect to={paths.ROOT}/>;

    const onRemoveButtonClickHandler = (userId) => {
        setDeleteModal((prevState) => ({
            userId,
            isOpen: !prevState.isOpen,
        }));
    };

    const onCloseModalHandler = () => {
        setDeleteModal({userId: null, isOpen: false});
    };

    const onDeleteUserHandler = () => {
        dispatch(deleteUser(deleteModal.userId));
    };

    const cols = [
        {
            name: '',
        },
        {
            name: 'Name',
            field: 'name',
        },
        {
            name: 'Email',
        },
        {
            name: 'Id',
        },
        {
            name: 'Location',
        },
        {
            name: 'Admin',
        },
        {
            name: 'Created',
            field: 'createdAt',
        },
        {
            name: 'Name',
        },
    ];

    const tableData = usersList.map((user) => {
        // TODO:: Fix this created field. It should always be unix timestamp, right now it's a string in DB?
        const createdAt = (user.createdAt && user.createdAt.seconds) ? new Date(user.createdAt.seconds * 1000) : user.createdAt;
        return [
            {
                value: (
                    <div className="image">
                        <img src={user.logoUrl} alt="" className="is-rounded"/>
                    </div>
                ),
            },
            {
                value: user.name,
            },
            {
                value: user.email,
            },
            {
                value: user.id,
            },
            {
                value: user.location,
            },
            {
                value: (
                    <small className="has-text-grey is-abbr-like">
                        {user.isAdmin ? (
                            <span className="icon">
                                <i className="mdi mdi-check"/>
                            </span>
                        ) : (
                            <span className="icon">
                                <i className="mdi mdi-close"/>
                            </span>
                        )}
                    </small>
                )
            },
            {
                value: (
                    <small className="has-text-grey is-abbr-like">
                        {useFormatDate(createdAt, {
                            weekday: 'short',
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                        })}
                    </small>
                ),
            },
            {
                value: (
                    <>
                            <div className="buttons is-right">
                                <Link to={`/users/${user.id}`} className="button is-small is-primary">
                                    <span className="icon is-small">
                                      <i className="mdi mdi-account-edit"/>
                                    </span>
                                </Link>
                                <button
                                    type="button"
                                    className="button is-small is-danger"
                                    onClick={() => onRemoveButtonClickHandler(user.id)}
                                >
                                    <span className="icon is-small">
                                      <i className="mdi mdi-trash-can"/>
                                    </span>
                                </button>
                            </div>
                    </>
                ),
            }
        ];
    });

    const deleteMessage = useFormatMessage('Users.delete');
    const confirmMessage = useFormatMessage('Users.confirm');
    const permDeleteMessage = useFormatMessage('Users.permDelete');
    const cancelMessage = useFormatMessage('Users.cancel');

    return (
        <>
            {redirect}
            {deleteModal.isOpen && (
                <ConfirmationModal
                    isActive={deleteModal.isOpen}
                    isLoading={loading}
                    confirmButtonMessage={deleteMessage}
                    title={confirmMessage}
                    body={permDeleteMessage}
                    cancelButtonMessage={cancelMessage}
                    onConfirmation={onDeleteUserHandler}
                    onCancel={onCloseModalHandler}
                />
            )}
            <section className="hero is-hero-bar">
                <div className="hero-body">
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <h1 className="title">{useFormatMessage('Users.users')} ({usersTotal})</h1>
                            </div>
                        </div>
                        <div className="level-right">
                            <div className="level-item">
                                <Link to={paths.ADD_USER} className="button">
                                    {useFormatMessage('Users.newUser')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section is-main-section">
                <div className="card has-table has-mobile-sort-spaced">
                    <header className="card-header">
                        <p className={classNames('card-header-title', classes.tableHeader)}>
                            <span>{useFormatMessage('Users.search')}</span>
                            <input
                                type="text"
                                className="input"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </p>
                    </header>
                    <div className="b-table">
                        {loading ? <ClipLoader/> : <RemoteTable
                            columns={cols}
                            data={tableData}
                            onPageSizeChange={(size) => setPageSize(size)}
                            onPageChange={(pageNo, movementType) => handlePageChange(pageNo, movementType)}
                            onSort={(attribute) => handleSort(attribute)}
                            pages={Math.ceil(usersTotal / pageSize)}
                            page={page}
                            pageSize={pageSize}
                        />}
                        {error && 'Show error'}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Users;
