import firebase from 'firebase';
import React, { useState } from 'react';
import ExportTile from './ExportTile';

const csvHeaders = ['createdAt', 'email', 'name', 'songs', 'return_path', 'metadata', 'substitution_data', 'tags'];

const ArtistsExport = () => {
    const [data, setData] = useState(null);
    const [isFetchingStatistics, setIsFetchingStatistics] = useState(false);

    const getArtists = async () => {
        setIsFetchingStatistics(true);

        const ownerIds = {};
        const result = await firebase.firestore().collection('songs').where('ownerId', '!=', false).get();
        result.docs.forEach(doc => {
            const { ownerId } = doc.data();
            if (ownerIds[ownerId] === undefined) {
                ownerIds[ownerId] = 0;
            }
            ownerIds[ownerId] += 1;
        });

        const { docs } = await firebase.firestore().collection('owners').get();
        const artists = [];

        docs.forEach((doc) => {
            const { email, name, createdAt } = doc.data();
            if (email) {
                const songCount = ownerIds[doc.id] ?? 0;
                let signupDate = new Date(createdAt).toLocaleDateString();
                if (createdAt?.toDate()) signupDate = createdAt.toDate().toLocaleDateString();
                const record = { createdAt: signupDate, name, email, songs: songCount, return_path: 'noreply@fairmus.com', metadata: "{}", substitution_data: "{}", tags: "[]" };
                artists.push(record);
            }
        });

        setIsFetchingStatistics(false);
        setData(artists);
    };

    return (
        <ExportTile
            title="Export artists from web"
            onFetch={() => getArtists()}
            isFetching={isFetchingStatistics}
            data={data}
            headers={csvHeaders}
            showMonthYearPicker
            showDatePicker={false}
        />
    );
};

export default ArtistsExport;