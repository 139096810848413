import React, { useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import ArtistForm from 'components/ArtistForm';
import { createArtist, modifyArtist, fetchArtist } from 'state/actions/artists';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';

const Artist = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success, artistData, error } = useSelector(
    (state) => ({
      success: state.artists.success,
      artistData: state.artists.artist,
      error: state.artists.error,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      if (!artistData) {
        dispatch(fetchArtist(id));
      }
    }
  }, [isEditing, id, artistData, dispatch]);

  const yupShape = {
    name: yup.string().required(),
    users: yup.array().of(yup.object()),
    isErrArtist: yup.boolean(),
    description: yup.string()
  };

  if(!artistData || !artistData.img) {
    yupShape.file = yup.mixed().test('file-test', 'Please pick a file', (value, context) => {
      return (value && value.length === 1);
    });
  }

  const schema = yup.object().shape(yupShape);

  const redirect = ((isEditing && error) || success) && (
    <Redirect to={paths.ARTISTS} />
  );

  const editArtistMessage = useFormatMessage('Artist.editArtist');

  const newArtistMessage = useFormatMessage('Artist.addArtist');

  const onSubmitHandler = (value) => {
    console.log(`value`, value);
    const newArtist = {
      ...value,
      file: value?.file[0] || null,
      users: value.users ? value.users.map(selected => selected.value) : [],
      country: {id: value.country.value, name: value.country.label} ,
      description: value.description ?? '',
      isEditing,
      id,
      source: value?.isErrArtist ? 'err' : null
    };

    if (isEditing) {
      dispatch(modifyArtist(newArtist));
    } else {
      dispatch(createArtist(newArtist));
    }
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editArtistMessage : newArtistMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !artistData ? (
          <ClipLoader />
        ) : (
          <ArtistForm
            isEditing={isEditing}
            artist={
              isEditing
                ? artistData
                : {
                    name: '',
                    createdAt: new Date(),
                    users: [],
                    description: ''
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default Artist;
