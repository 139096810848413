import {createAction} from 'redux-act';
import {toast} from 'react-toastify';

import {firebaseError, getImgThumb} from 'utils';
import firebase from 'firebase.js';
import {checkUserData} from './auth';
import {
    fetchCollection,
    fetchDocuments,
    fetchDocument,
    createDocument,
    deleteDocument,
    updateDocument,
} from '../api';
import { searchIndex  } from '../../service/algolia';

export const GENRES_FETCH_DATA_INIT = createAction('GENRES_FETCH_DATA_INIT');
export const GENRES_FETCH_DATA_SUCCESS = createAction('GENRES_FETCH_DATA_SUCCESS');
export const GENRES_FETCH_DATA_FAIL = createAction('GENRES_FETCH_DATA_FAIL');

export const GENRES_DELETE_GENRE_INIT = createAction('GENRES_DELETE_GENRE_INIT');
export const GENRES_DELETE_GENRE_SUCCESS = createAction('GENRES_DELETE_GENRE_SUCCESS');
export const GENRES_DELETE_GENRE_FAIL = createAction('GENRES_DELETE_GENRE_FAIL');

export const GENRES_CREATE_GENRE_INIT = createAction('GENRES_CREATE_GENRE_INIT');
export const GENRES_CREATE_GENRE_SUCCESS = createAction('GENRES_CREATE_GENRE_SUCCESS');
export const GENRES_CREATE_GENRE_FAIL = createAction('GENRES_CREATE_GENRE_FAIL');

export const GENRES_MODIFY_GENRE_INIT = createAction('GENRES_MODIFY_GENRE_INIT');
export const GENRES_MODIFY_GENRE_SUCCESS = createAction('GENRES_MODIFY_GENRE_SUCCESS');
export const GENRES_MODIFY_GENRE_FAIL = createAction('GENRES_MODIFY_v_FAIL');

export const GENRES_CLEAN_UP = createAction('GENRES_CLEAN_UP');

export const GENRES_CLEAR_DATA_LOGOUT = createAction('GENRES_CLEAR_DATA_LOGOUT');

export const paginateGenres = (pageSize, search, direction) => {
    return async (dispatch, getState) => {
        const prevGenres = [...getState().genres.data];
        const prevTotal = getState().genres.total;
        const prevPage = getState().genres.page;
        let newPage = (direction === 'init') ? 1 : (prevPage || 1);
        if(direction === 'next') {
            newPage += 1;
        } else if(direction === 'prev') {
            newPage -= 1;
        }

        dispatch(checkUserData());
        dispatch(GENRES_FETCH_DATA_INIT());

        let genres;
        let total = prevTotal;
        try {
            if(search) {
                const res = await searchIndex('genres', search, newPage - 1, pageSize);
                const ids = res.hits.map((hit) => hit.objectID);

                genres = await fetchDocuments('genres', ids);
                total = res.nbHits;
            } else if(direction === 'next') {
                genres = await fetchCollection('genres', {
                    sort: {
                        attribute: 'name',
                        order: 'asc'
                    },
                    startAfter: prevGenres.pop().name,
                    pageSize,
                });
            } else if(direction === 'prev') {
                genres = await fetchCollection('genres', {
                    sort: {
                        attribute: 'name',
                        order: 'asc'
                    },
                    endBefore: prevGenres.shift().name,
                    pageSize,
                });
            } else {
                genres = await fetchCollection('genres', {
                    sort: {
                        attribute: 'name',
                        order: 'asc'
                    },
                    pageSize,
                });
                total = await firebase.firestore().collection('genres').get().then((counter) => {
                  return counter.size;
                });
            }
        } catch (error) {
            toast.error(error);
            return dispatch(GENRES_FETCH_DATA_FAIL({error}));
        }

        return dispatch(
            GENRES_FETCH_DATA_SUCCESS({
                data: genres,
                total,
                page: newPage,
            })
        );
    };
};

export const fetchGenre = (genreId) => {
    return async (dispatch, getState) => {
        dispatch(checkUserData());

        dispatch(GENRES_FETCH_DATA_INIT());

        let genre;
        try {
            genre = await fetchDocument('genres', genreId);
        } catch (error) {
            toast.error(error);
            return dispatch(GENRES_FETCH_DATA_FAIL({error}));
        }

        if (!genre) {
            const errorMessage = 'Genre not available';
            toast.error(errorMessage);
            return dispatch(GENRES_FETCH_DATA_FAIL({error: errorMessage}));
        }

        const genres = getState().genres.data;
        genres.push(genre);

        return dispatch(
            GENRES_FETCH_DATA_SUCCESS({
                data: genres,
            })
        );
    };
};

const deleteImage = (oldImage) => {
    if (!oldImage.includes('firebasestorage')) {
        return null;
    }

    const imagePath = oldImage.split('genres%2F').pop();
    const thumbPath = getImgThumb(oldImage).split('genres%2F').pop();

    return Promise.all([
        firebase.storage().ref(`genres/${imagePath}`).delete(),
        firebase.storage().ref(`genres/${thumbPath}`).delete()
    ]);
};

export const deleteGenre = (id) => {
    return async (dispatch, getState) => {
        dispatch(GENRES_DELETE_GENRE_INIT());
        const {locale} = getState().preferences;
        const {img} = getState()
            .genres.data.filter((genre) => genre.id === id)
            .pop();

        const deleteImageTask = img ? deleteImage(img) : null;

        const deleteGenreTask = deleteDocument('genres', id);

        try {
            await Promise.all([deleteImageTask, deleteGenreTask]);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                GENRES_DELETE_GENRE_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('The genre was deleted.');
        return dispatch(GENRES_DELETE_GENRE_SUCCESS({id}));
    };
};

export const clearGenresDataLogout = () => {
    return (dispatch) => {
        dispatch(GENRES_CLEAR_DATA_LOGOUT());
    };
};

const uploadImage = (uid, file) => {
    const storageRef = firebase.storage().ref();
    const fileExtension = file.name.split('.').pop();
    const fileName = `${uid}.${fileExtension}`;
    return storageRef.child(`genres/${fileName}`).put(file);
};

const getImageUrl = (uid, file) => {
    const fileExtension = file.name.split('.').pop();
    const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;
    return `${bucketUrl}/o/genres%2F${uid}.${fileExtension}`;
};

export const createGenre = ({name, adminOnly, file,}) => {
    return async (dispatch, getState) => {
        dispatch(GENRES_CREATE_GENRE_INIT());
        const {locale} = getState().preferences;

        const genreData = {name, adminOnly, createdAt: new Date()};
        let response;
        try {
            response = await createDocument('genres', null, genreData);


            if (file) {
                const imgUrl = getImageUrl(response.id, file);
                await uploadImage(response.id, file);

                genreData.img = imgUrl;
                await updateDocument('genres', response.id, genreData);
            }

            genreData.id = response.id;
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                GENRES_CREATE_GENRE_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('Genre created successfully');
        return dispatch(GENRES_CREATE_GENRE_SUCCESS({genre: genreData}));
    };
};

export const modifyGenre = ({
                                 id,
                                 name,
                                  adminOnly,
                                 file,
                             }) => {
    return async (dispatch, getState) => {
        dispatch(GENRES_MODIFY_GENRE_INIT());
        const {locale} = getState().preferences;
        const genre = getState().genres.data.find((thisGenre) => thisGenre.id === id);
        const img = genre.img ? genre.img : null;
        console.log(img);
        let deleteImgTask;
        let uploadImgTask;
        let newImgUrl = null;
        if (file) {
            newImgUrl = getImageUrl(id, file);
            deleteImgTask = img && deleteImage(img);
            uploadImgTask = uploadImage(id, file);
        }

        const genreData = {
            name,
            adminOnly,
            img: newImgUrl || img,
        };
        const updateGenreDbTask = updateDocument('genres', id, genreData);

        try {
            await Promise.all([deleteImgTask, uploadImgTask, updateGenreDbTask]);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                GENRES_MODIFY_GENRE_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('Genre updated successfully');

        return dispatch(GENRES_MODIFY_GENRE_SUCCESS({genre: genreData, id}));
    };
};

export const genresCleanUp = () => (dispatch) => dispatch(GENRES_CLEAN_UP());
