import { atom } from "jotai";
import { focusAtom } from 'jotai/optics';

type InitialStateProps = {
  query: string | null;
  page: number | null;
  pageSize: number | null;
}

const initialState: InitialStateProps = {
  query: null,
  page: 0,
  pageSize: 25,
};

export const baseSearchAtom = atom(initialState);

export const pageAtom = focusAtom(baseSearchAtom, (optic) => optic.prop(`page`));
export const pageSizeAtom = focusAtom(baseSearchAtom, (optic) => optic.prop(`pageSize`));
export const queryAtom = focusAtom(baseSearchAtom, (optic) => optic.prop('query'));
