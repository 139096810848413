import classNames from "classnames";
import RemoteTable from 'components/RemoteTable';
import { useFormatMessage, usePrevious } from 'hooks';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { paginateApprovalsAlbums, approvalsAlbumsCleanUp, setApproved, removeApproved } from 'state/actions/albums_approvals';
import { getImgThumb } from "utils";
import classes from "../Users/Users.module.scss";

const AlbumApprovals = () => {
    const {approvalAlbumsList, approvalAlbumsTotal, page, isAdmin, error, loading} = useSelector(
        (state) => {
            return ({
                approvalAlbumsList: state.approvalAlbums.data,
                approvalAlbumsTotal: state.approvalAlbums.total,
                page: state.approvalAlbums.page,
                isAdmin: state.auth.userData.isAdmin,
                error: state.approvalAlbums.error,
                loading: state.approvalAlbums.loading,
            });
        },
        shallowEqual
    );

    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [pageSize, setPageSize] = useState(50);
    const prev = usePrevious({page, pageSize, search});

    const handlePageChange = (newPage, movement) => {
        dispatch(paginateApprovalsAlbums(pageSize, search, movement));
    };

    const handleSort = (attribute, order) => {
        if(attribute && order) {
            dispatch(paginateApprovalsAlbums(pageSize, search, 'init', attribute, order));
        }
    };

    const pullInitial = () => dispatch(paginateApprovalsAlbums(pageSize, search, 'init'));

    useEffect(() => {
        if (isAdmin) {
            pullInitial();
        }

        return () => dispatch(approvalsAlbumsCleanUp());
    }, [dispatch, isAdmin]);

    useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        if(prev === undefined) {
            return; // Only run once we have actually searched
        }

        const timeout = setTimeout(() => {
            pullInitial();
        }, 500);
        setSearchTimeout(timeout);
    }, [search]);

    useEffect(() => {
        if (prev && pageSize !== prev.pageSize) {
            pullInitial();
        }
    }, [pageSize]);

    const onHandleApprove = async (album) => {
        if (!album.approved) await setApproved(album);
        else await removeApproved(album);
        pullInitial();
    };


    const cols = [
        {
            name: '',
        },
        {
            name: 'Name',
            field: 'name',
        },
        {
            name: 'Year',
            field: 'recordingYear',
        },
        {
            name: 'Songs',
        },
        {
            name: 'Created',
            field: 'createdAt',
        },
        {
            name: 'Available',
            field: 'availableFrom',
        },
        {
            name: 'Approve',
        },
    ];

    const tableData = approvalAlbumsList.map((album) => {
        return [
            {
                className: 'table-img-cell',
                value: (<>
                    <img src={`${getImgThumb(album.img, true)}`} alt="" className="table-img album-img" />
                </>),
            },
            {
                value: album.name,
            },
            {
                value: album.recordingYear,
            },
            {
                value: album.songs?.length ?? 0,
            },
            {
                value: new Date(album.createdAt.seconds * 1000).toLocaleDateString(),
            },
            {
                value: album.availableFrom && album.availableFrom.seconds !== undefined ? new Date(album.availableFrom.seconds * 1000).toLocaleDateString() : '',
            },
            {
                className: 'is-actions-cell',
                value: (<>
                    <div className="buttons is-left">
                        <button
                            type="button"
                            className="button is-small"
                            onClick={() => onHandleApprove(album)}
                        >
                                <span className="icon is-small">
                                  <i className={album.approved ? "mdi mdi-star" : "mdi mdi-star-outline"}/>
                                </span>
                        </button>
                    </div>
                </>),
            },
        ];
    });

    return (
        <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
            <header className="card-header">
                <p className={classNames('card-header-title', classes.tableHeader)}>
                    <span>{useFormatMessage('Playlists.search')}</span>
                    <input
                        type="text"
                        className="input"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </p>
            </header>
            <div className="b-table">
                {loading ? <ClipLoader /> : <RemoteTable
                    columns={cols}
                    data={tableData}
                    onPageSizeChange={(size) => setPageSize(size)}
                    onPageChange={(pageNo, movementType) => handlePageChange(pageNo, movementType)}
                    onSort={(attribute) => handleSort(attribute, 'desc')}
                    pages={Math.ceil(approvalAlbumsTotal / pageSize)}
                    page={page}
                    pageSize={pageSize}
                />}
                {error && 'Show error'}
            </div>
        </div>
    </section>
    );
};

export default AlbumApprovals;
