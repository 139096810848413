import { useFormatMessage } from "hooks";
import React, { useState } from "react";
import { CSVLink } from 'react-csv';
import DatePicker from 'react-datepicker';
import ClipLoader from 'react-spinners/ScaleLoader';
import {getCurrentMonth, getDateMinusOneMonth, getLastMonth} from "utils";

const currentDate = new Date();
const ExportTile = ({ title, onFetch, isFetching, data, headers, showMonthYearPicker = false, showDatePicker = true, children }) => {
    const [startDate, setStartDate] = useState(showMonthYearPicker ? getLastMonth() : getDateMinusOneMonth());
    const [endDate, setEndDate] = useState(showMonthYearPicker ? getCurrentMonth : currentDate);

    const handleChange = (dates) => {
        if(!showMonthYearPicker) {
            const [start, end] = dates;
            setStartDate(start);
            setEndDate(end);
        } else  {
            const start = new Date(dates.getFullYear(), dates.getMonth(), 1);
            const end = new Date(dates.getFullYear(), dates.getMonth() -1, 1);
            setStartDate(start);
            setEndDate(end);
        }
    };

    const generateFileName = () => {
        if(showDatePicker) {
            return `${title.split(' ')?.map((e) => e.toLowerCase())?.join('_')}_${startDate?.toLocaleDateString()}-${endDate?.toLocaleDateString()}.csv`;
        }
        return `${title.split(' ')?.map((e) => e.toLowerCase())?.join('_')}_${new Date()?.toLocaleDateString()}.csv`;
    };

    return (
        <article className="tile is-child notification">
            <p className="title">{title}</p>

            {showDatePicker && (
                <div className="field">
                    <div className="field-label">
                        <label className="label" htmlFor="range" style={{ textAlign: 'left' }}>
                            {useFormatMessage('ExportForm.dateRange')}
                        </label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            {showMonthYearPicker ? (
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleChange}
                                    maxDate={getLastMonth()}
                                    showMonthYearPicker
                                />
                            ):  (
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    maxDate={currentDate}
                                    selectsRange
                                />
                            )}

                        </div>
                    </div>
                    {children && (
                        <div className="field-body">
                            <div className="field">
                                {children}
                            </div>
                        </div>
                    )}
                </div>
            )}

            <div className="level">
                <div className="level-item level-left">
                    <button onClick={() => onFetch(startDate, endDate)} className="button">Fetch</button>
                </div>
                <div className="level-item level-right">
                    {isFetching && !data ? <ClipLoader height={25} /> : data && (
                        <CSVLink separator=";" data={data} headers={headers} filename={generateFileName()}>
                            Download CSV
                        </CSVLink>
                    )}
                </div>
            </div>
        </article>
    );
};

export default ExportTile;