import firebase from 'firebase.js';
import { useFormatMessage } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
// @ts-ignore
import AsyncSelect from 'react-select/async';
// @ts-ignore
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import { searchIndex } from 'service/algolia';
import { fetchCollection } from 'state/api';

const Actions = () => {
    return (
        <>
            <section className="hero is-hero-bar">
                <div className="hero-body">
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <h1 className="title">{useFormatMessage('Actions.actions')}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section is-main-section">
                <LinkOwnerWithAlbum />
            </section>
        </>
    );
};

const LinkOwnerWithAlbum = () => {
    const { watch, control, reset } = useForm({
        defaultValues: {
            owner: null,
            album: null,
        },
    });
    const [owners, setOwners] = useState<{ label: string, value: string }[] | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (!owners) {
            (async () => {
                const documents = await fetchCollection('owners');
                const _owners = documents.map((doc) => ({ value: doc.id, label: doc.name ? doc.name : doc.email }));
                setOwners(_owners);
            })();
        };
    }, [owners]);

    const loadAlbums = (searchTerm: string) => {
        return searchIndex('albums', searchTerm, 0, 50).then((res) => {
            return res.hits.map((hit) => {
                return {
                    value: hit.objectID,
                    label: hit.name,
                    isDisabled: Boolean(hit.ownerId)
                };
            }).sort((a, b) => {
                return Number(a.isDisabled) - Number(b.isDisabled);
            });
        });
    };

    if (!owners) return <ClipLoader />;

    const formOwner = watch('owner');
    const formAlbum = watch('album');

    const handleLink = async () => {
        if (!formOwner) return;
        if (!formAlbum) return;

        const songsRef = firebase.firestore().collection('songs');
        const albumsRef = firebase.firestore().collection('albums');

        setIsSubmitting(true);
        // @ts-ignore
        const albumRef = albumsRef.doc(formAlbum.value);
        const album = await albumRef.get();

        if (album.exists) {
            // @ts-ignore
            const songIds = await album.data().songs;

            // @ts-ignore
            songIds.forEach(async (songId) => {
                // @ts-ignore
                console.log('Liked song', songId, 'with owner', formOwner.value);
                // @ts-ignore
                await songsRef.doc(songId).update({ ownerId: formOwner.value });
            });

            // @ts-ignore
            console.log('Linked album', album.data().name, 'to owner', formOwner.value);
            // @ts-ignore
            await albumRef.update({ ownerId: formOwner.value });
            reset();
        }
        setIsSubmitting(false);
    };

    return (
        <div className="tile is-ancestor">
            <div className="tile is-parent is-5">
                <div className="tile is-child">
                    <article className="tile is-child notification">
                        <p className="title">Link owner with album</p>
                        <div className="field">
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <Controller
                                            as={Select}
                                            name="owner"
                                            control={control}
                                            placeholder="Select artist account"
                                            isSearchable
                                            options={owners}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <Controller
                                            as={AsyncSelect}
                                            name="album"
                                            control={control}
                                            placeholder="Select album"
                                            loadOptions={loadAlbums}
                                            isDisabled={!formOwner}
                                            isOptionDisabled={(option: any) => option.isDisabled}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="level">
                            <div className="level-item level-right">
                                <button onClick={handleLink} className="button" disabled={(!formOwner || !formAlbum) || isSubmitting}>Link</button>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    );
};

export default Actions;
