export default {
  ROOT: '/',
  LOGIN: '/login',
  USERS: '/users',
  ADD_USER: '/users/new',
  MODIFY_USER: '/users/:id',
  PROFILE: '/profile',
  RESET_PASSWORD: '/recover-password',
  SECTION: '/section',
  ARTISTS: '/artists',
  OWNERS: '/owners',
  ADD_ARTIST: '/artists/new',
  ADD_OWNER: '/owners/new',
  MODIFY_ARTIST: '/artists/:id',
  MODIFY_OWNER: '/owners/:id',
  GENRES: '/genres',
  ADD_GENRE: '/genres/new',
  MODIFY_GENRE: '/genres/:id',
  SONGS: '/songs',
  ADD_SONG: '/songs/new',
  MODIFY_SONG: '/songs/:id',
  ALBUMS: '/albums',
  ADD_ALBUM: '/albums/new',
  MODIFY_ALBUM: '/albums/:id',
  PLAYLISTS: '/playlists',
  ADD_PLAYLIST: '/playlists/new',
  MODIFY_PLAYLIST: '/playlists/:id',
  EXPORT: '/export',
  TRANSACTIONS: '/transactions',
  WITHDRAWALS: '/withdrawals',
  ADD_TRANSACTION: '/transactions/new',
  MODIFY_TRANSACTION: '/transactions/:id',
  APPROVALS: '/approvals',
  MODIFY_APPROVALS: '/approvals/:id',
  ACTIONS: '/actions',
  SUBMENU_1: '/submenu1',
  SUBMENU_2: '/submenu2'
};
