import {createAction} from 'redux-act';
import {toast} from 'react-toastify';

import {firebaseError, getImgThumb} from 'utils';
import firebase from 'firebase.js';
import {checkUserData} from './auth';
import {
    fetchCollection,
    fetchDocuments,
    fetchDocument,
    createDocument,
    deleteDocument,
    updateDocument,
} from '../api';
import { searchIndex  } from '../../service/algolia';

export const OWNERS_FETCH_DATA_INIT = createAction('OWNERS_FETCH_DATA_INIT');
export const OWNER_FETCH_DATA_SUCCESS = createAction('OWNER_FETCH_DATA_SUCCESS');
export const OWNERS_FETCH_DATA_SUCCESS = createAction('OWNERS_FETCH_DATA_SUCCESS');
export const OWNERS_FETCH_DATA_FAIL = createAction('OWNERS_FETCH_DATA_FAIL');

export const OWNERS_DELETE_OWNER_INIT = createAction('OWNERS_DELETE_OWNER_INIT');
export const OWNERS_DELETE_OWNER_SUCCESS = createAction('OWNERS_DELETE_OWNER_SUCCESS');
export const OWNERS_DELETE_OWNER_FAIL = createAction('OWNERS_DELETE_OWNER_FAIL');

export const OWNERS_CREATE_OWNER_INIT = createAction('OWNERS_CREATE_OWNER_INIT');
export const OWNERS_CREATE_OWNER_SUCCESS = createAction('OWNERS_CREATE_OWNER_SUCCESS');
export const OWNERS_CREATE_OWNER_FAIL = createAction('OWNERS_CREATE_OWNER_FAIL');

export const OWNERS_MODIFY_OWNER_INIT = createAction('OWNERS_MODIFY_OWNER_INIT');
export const OWNERS_MODIFY_OWNER_SUCCESS = createAction('OWNERS_MODIFY_OWNER_SUCCESS');
export const OWNERS_MODIFY_OWNER_FAIL = createAction('OWNERS_MODIFY_v_FAIL');

export const OWNERS_CLEAN_UP = createAction('OWNERS_CLEAN_UP');

export const OWNERS_CLEAR_DATA_LOGOUT = createAction('OWNERS_CLEAR_DATA_LOGOUT');

export const paginateOwners = (pageSize, search, direction, attribute) => {
    return async (dispatch, getState) => {
        const prevOwners = [...getState().owners.data];
        const prevTotal = getState().owners.total;
        const prevPage = getState().owners.page;
        let newPage = (direction === 'init') ? 1 : (prevPage || 1);
        if(direction === 'next') {
            newPage += 1;
        } else if(direction === 'prev') {
            newPage -= 1;
        }

        const prevOrder = getState().owners.sortConfig.order;
        const prevAttribute = getState().owners.sortConfig.attribute;

        const newAttribute = attribute || prevAttribute;
        let newOrder = 'asc';
        if (attribute === prevAttribute) {
            newOrder = prevOrder === 'asc' ? 'desc' : 'asc';
        }
        const newSortConfig = {
            attribute: newAttribute,
            order: newOrder
        };

        dispatch(checkUserData());
        dispatch(OWNERS_FETCH_DATA_INIT());

        let owners;
        let total = prevTotal;
        try {
            if(search) {
                const res = await searchIndex('owners', search, newPage - 1, pageSize);
                const ids = res.hits.map((hit) => hit.objectID);

                owners = await fetchDocuments('owners', ids);
                total = res.nbHits;
            } else if(direction === 'next') {
              owners = await fetchCollection('owners', {
                    sort: newSortConfig,
                    startAfter: prevOwners.pop().name,
                    pageSize,
                });
            } else if(direction === 'prev') {
              owners = await fetchCollection('owners', {
                    sort: newSortConfig,
                    endBefore: prevOwners.shift().name,
                    pageSize,
                });
            } else {
              owners = await fetchCollection('owners', {
                    sort: newSortConfig,
                    pageSize,
                });
                total = await firebase.firestore().collection('owners').get().then((counter) => {
                  return counter.size;
                });
            }
        } catch (error) {
            toast.error(error);
            return dispatch(OWNERS_FETCH_DATA_FAIL({error}));
        }

        return dispatch(
            OWNERS_FETCH_DATA_SUCCESS({
                data: owners,
                total,
                page: newPage,
                sortConfig: newSortConfig
            })
        );
    };
};

export const fetchOwner = (ownerId) => {
    return async (dispatch, getState) => {
        dispatch(checkUserData());

        dispatch(OWNERS_FETCH_DATA_INIT());

        let owner;
        try {
          owner = await fetchDocument('owners', ownerId);
        } catch (error) {
            toast.error(error);
            return dispatch(OWNERS_FETCH_DATA_FAIL({error}));
        }

        if (!owner) {
            const errorMessage = 'Owner not available';
            toast.error(errorMessage);
            return dispatch(OWNERS_FETCH_DATA_FAIL({error: errorMessage}));
        }

        return dispatch(
            OWNER_FETCH_DATA_SUCCESS({
                owner,
            })
        );
    };
};

const deleteImage = (oldImage) => {
    if (!oldImage.includes('firebasestorage')) {
        return null;
    }

    const imagePath = oldImage.split('owners%2F').pop();
    const thumbPath = getImgThumb(oldImage).split('owners%2F').pop();

    return Promise.all([
        firebase.storage().ref(`owners/${imagePath}`).delete(),
        firebase.storage().ref(`owners/${thumbPath}`).delete()
    ]);
};

export const deleteOwner = (id) => {
    return async (dispatch, getState) => {
        dispatch(OWNERS_DELETE_OWNER_INIT());
        const {locale} = getState().preferences;
        const {image: img} = getState()
            .owners.data.filter((owner) => owner.id === id)
            .pop();

        const deleteImageTask = img ? deleteImage(img) : null;

        const deleteOwnerTask = deleteDocument('owners', id);

        try {
            await Promise.all([deleteImageTask, deleteOwnerTask]);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                OWNERS_DELETE_OWNER_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('The Owner was deleted.');
        return dispatch(OWNERS_DELETE_OWNER_SUCCESS({id}));
    };
};

export const clearOwnersDataLogout = () => {
    return (dispatch) => {
        dispatch(OWNERS_CLEAR_DATA_LOGOUT());
    };
};

const uploadImage = (uid, file) => {
    const storageRef = firebase.storage().ref();
    const fileExtension = file.name.split('.').pop();
    const fileName = `${uid}.${fileExtension}`;
    return storageRef.child(`owners/${fileName}`).put(file);
};

const getImageUrl = (uid, file) => {
    const fileExtension = file.name.split('.').pop();
    const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;
    return `${bucketUrl}/o/owners%2F${uid}.${fileExtension}`;
};

export const createOwner = ({name, file, recordLabel}) => {
    return async (dispatch, getState) => {
        dispatch(OWNERS_CREATE_OWNER_INIT());
        const {locale} = getState().preferences;
        const ownerData = {
            name,
            createdAt: new Date(),
            isAdmin: false,
            recordLabel
        };
        let response;
        try {
            response = await createDocument('owners', null, ownerData);

            if (file) {
                const imgUrl = getImageUrl(response.id, file);
                await uploadImage(response.id, file);

                ownerData.image = `${imgUrl}?alt=media`;
                await updateDocument('owners', response.id, ownerData);
            }

        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                OWNERS_CREATE_OWNER_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('Owner created successfully');
        return dispatch(OWNERS_CREATE_OWNER_SUCCESS({owner: ownerData}));
    };
};

export const modifyOwner = ({
                                 id,
                                 name,
                                 file,
                                 recordLabel
                             }) => {
    return async (dispatch, getState) => {
        dispatch(OWNERS_MODIFY_OWNER_INIT());
        const {locale} = getState().preferences;
        const {image: img} = getState().owners.owner;
        let deleteImgTask;
        let uploadImgTask;
        let newImgUrl = null;
        if (file) {
            newImgUrl = getImageUrl(id, file);
            // deleteImgTask = img && deleteImage(img);
            uploadImgTask = uploadImage(id, file);
        }

        const ownerData = {
            name,
            image: newImgUrl ? `${newImgUrl}?alt=media` : img,
            recordLabel
        };
        const updateOwnerDbTask = updateDocument('owners', id, ownerData);

        try {
            await Promise.all([deleteImgTask, uploadImgTask, updateOwnerDbTask]);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                OWNERS_MODIFY_OWNER_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('Owner updated successfully');

        return dispatch(OWNERS_MODIFY_OWNER_SUCCESS({owner: ownerData, id}));
    };
};

export const ownersCleanUp = () => (dispatch) => dispatch(OWNERS_CLEAN_UP());
