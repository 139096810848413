
import { atom, useAtom } from 'jotai';
import {pageAtom, pageSizeAtom, queryAtom} from '../state/searchStore';

const useSearch = () => {
  const [query, setQuery] = useAtom(queryAtom);
  const [page, setPage] = useAtom(pageAtom);
  const [pageSize, setPageSize] = useAtom(pageSizeAtom);


  return {
    query,
    setQuery,
    page,
    setPage,
    pageSize,
    setPageSize
  };
};

export default useSearch;
