import { createReducer } from 'redux-act';

import {
  ARTISTS_FETCH_DATA_INIT,
  ARTIST_FETCH_DATA_SUCCESS,
  ARTISTS_FETCH_DATA_SUCCESS,
  ARTISTS_FETCH_DATA_FAIL,
  ARTISTS_DELETE_ARTIST_INIT,
  ARTISTS_DELETE_ARTIST_SUCCESS,
  ARTISTS_DELETE_ARTIST_FAIL,
  ARTISTS_CREATE_ARTIST_INIT,
  ARTISTS_CREATE_ARTIST_SUCCESS,
  ARTISTS_CREATE_ARTIST_FAIL,
  ARTISTS_MODIFY_ARTIST_INIT,
  ARTISTS_MODIFY_ARTIST_SUCCESS,
  ARTISTS_MODIFY_ARTIST_FAIL,
  ARTISTS_CLEAN_UP,
  ARTISTS_CLEAR_DATA_LOGOUT,
} from 'state/actions/artists';
import { ALBUM_FETCH_DATA_SUCCESS } from '../../actions/albums';

const initialState = {
  data: [],
  artist: null,
  total: 0,
  page: 1,
  loading: false,
  error: null,
  success: false,
  deleted: false,
  sortConfig: {
    attribute: 'name',
    order: 'asc'
  }
};

export const artistsReducer = createReducer(
  {
    [ARTISTS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [ARTISTS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data,
      total: payload.total,
      page: payload.page,
      sortConfig: payload.sortConfig,
      loading: false,
      error: null,
    }),
    [ARTISTS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ARTISTS_DELETE_ARTIST_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ARTIST_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      artist: payload.artist,
      loading: false,
      error: null,
    }),
    [ARTISTS_DELETE_ARTIST_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [ARTISTS_DELETE_ARTIST_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ARTISTS_CREATE_ARTIST_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ARTISTS_CREATE_ARTIST_SUCCESS]: (state, payload) => ({
      ...state,
      artist: payload.artist,
      data: state.data.concat(payload.artist),
      loading: false,
      error: null,
      success: true,
    }),
    [ARTISTS_CREATE_ARTIST_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ARTISTS_MODIFY_ARTIST_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ARTISTS_MODIFY_ARTIST_SUCCESS]: (state, payload) => ({
      ...state,
      data: !state.data
        ? []
        : state.data.map((elem) => {
            if (elem.id === payload.id) {
              return {
                name: payload.artist.name,
                id: payload.id,
                users: payload.users,
                // logoUrl: payload.user.logoUrl,
                createdAt: payload.artist.createdAt,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
    }),
    [ARTISTS_MODIFY_ARTIST_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ARTISTS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [ARTISTS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
