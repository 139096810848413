import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useFormatMessage, usePrevious} from 'hooks';
import { Redirect, Link } from 'react-router-dom';
import classNames from "classnames";
import ClipLoader from "react-spinners/ClipLoader";
import RemoteTable from 'components/RemoteTable';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import {
    paginatePlaylists,
    deletePlaylist,
    playlistsCleanUp,
} from 'state/actions/playlists';

import defaultImage from 'assets/user-default-log.svg';
import classes from "../Users/Users.module.scss";
import {getImgThumb} from "../../utils";
import { paginateUsers } from '../../state/actions/users';
import {createFeatured, deleteFeatured} from "../../state/actions/featured";

const Playlists = () => {
    const {playlistsList, playlistsTotal, page, isAdmin, error, loading, deleted} = useSelector(
        (state) => {
            return ({
                playlistsList: state.playlists.data,
                playlistsTotal: state.playlists.total,
                page: state.playlists.page,
                isAdmin: state.auth.userData.isAdmin,
                error: state.playlists.error,
                loading: state.playlists.loading,
                deleted: state.playlists.deleted,
            });
        },
        shallowEqual
    );

    const [deleteModal, setDeleteModal] = useState({
        playlistId: null,
        isOpen: false,
    });
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [pageSize, setPageSize] = useState(50);
    const prev = usePrevious({page, pageSize, search});

    const handlePageChange = (newPage, movement) => {
        dispatch(paginatePlaylists(pageSize, search, movement));
    };

    const handleSort = (attribute) => {
        if(attribute) {
            dispatch(paginatePlaylists(pageSize, search, 'init', attribute));
        }
    };

    const pullInitial = () => dispatch(paginatePlaylists(pageSize, search, 'init'));

    useEffect(() => {
        if (isAdmin) {
            pullInitial();
        }

        return () => dispatch(playlistsCleanUp());
    }, [dispatch, isAdmin]);

    useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        if(prev === undefined) {
            return; // Only run once we have actually searched
        }

        const timeout = setTimeout(() => {
            pullInitial();
        }, 500);
        setSearchTimeout(timeout);
    }, [search]);

    useEffect(() => {
        if (prev && pageSize !== prev.pageSize) {
            pullInitial();
        }
    }, [pageSize]);

    useEffect(() => {
        if (deleted && !loading) {
            setDeleteModal((prevState) => ({
                playlistId: null,
                isOpen: !prevState.isOpen,
            }));
        }
    }, [deleted, loading]);

    const redirect = !isAdmin && <Redirect to={paths.ROOT}/>;

    const onRemoveButtonClickHandler = (playlistId) => {
        setDeleteModal((prevState) => ({
            playlistId,
            isOpen: !prevState.isOpen,
        }));
    };

    const onHandleFeatured = (playlist) => {
        const data = {
            ...playlist,
            type: 'Playlist'
        };
        const createFeature = !playlist.featuredId;
        if(createFeature) {

            dispatch(createFeatured(data));
        } else {
            dispatch(deleteFeatured(data));
        }
        pullInitial();
    };

    const onCloseModalHandler = () => {
        setDeleteModal({playlistId: null, isOpen: false});
    };

    const onDeletePlaylistHandler = () => {
        dispatch(deletePlaylist(deleteModal.playlistId));
    };

    const cols = [
        {
            name: '',
        },
        {
            name: 'Name',
            field: 'name',
        },
        {
            name: 'Songs',
        },
        {
            name: 'Type',
            field: 'private',
        },
        {
            name: 'Section',
        },
        {
            name: 'Updated',
            field: 'updatedAt',
        },
        {
            name: 'Created',
            field: 'createdAt',
        },
        {
            name: '',
        },
    ];

    const tableData = playlistsList.map((playlist) => {
        return [
            {
                className: 'table-img-cell',
                value: (<>
                    <img src={playlist.img ? `${getImgThumb(playlist.img, true)}` : defaultImage} alt="" className="table-img album-img" />
                </>),
            },
            {
                value: playlist.name,
            },
            {
                value: playlist.songs?.length,
            },
            {
                value: playlist.private ? 'Private playlist' : 'Public playlist',
            },
            {
                value: playlist.section ?? 'unpublished',
            },
            {
                value: (playlist.updatedAt && playlist.updatedAt.seconds) ? new Date(playlist.updatedAt.seconds * 1000).toLocaleDateString() : "-",
            },
            {
                value: (playlist.createdAt && playlist.createdAt.seconds) ? new Date(playlist.createdAt.seconds * 1000).toLocaleDateString() : "-",
            },
            {
                className: 'is-actions-cell',
                value: (
                    <>
                        <div className="buttons is-right">
                            <button
                                type="button"
                                className="button is-small"
                                onClick={() => onHandleFeatured(playlist)}
                            >
                                <span className="icon is-small">
                                  <i className={playlist.featuredId ? "mdi mdi-star" : "mdi mdi-star-outline"}/>
                                </span>
                            </button>
                            <Link to={`/playlists/${playlist.id}`} className="button is-small is-primary">
                                <span className="icon is-small">
                                  <i className="mdi mdi-pencil-outline"/>
                                </span>
                            </Link>
                            <button
                                type="button"
                                className="button is-small is-danger"
                                onClick={() => onRemoveButtonClickHandler(playlist.id)}
                            >
                                <span className="icon is-small">
                                  <i className="mdi mdi-trash-can"/>
                                </span>
                            </button>
                        </div>
                    </>
                ),
            }
        ];
    });

    const deleteMessage = useFormatMessage('Playlists.delete');
    const confirmMessage = useFormatMessage('Playlists.confirm');
    const permDeleteMessage = useFormatMessage('Playlists.permDelete');
    const cancelMessage = useFormatMessage('Playlists.cancel');

    return (
        <>
            {redirect}
            {deleteModal.isOpen && (
                <ConfirmationModal
                    isActive={deleteModal.isOpen}
                    isLoading={loading}
                    confirmButtonMessage={deleteMessage}
                    title={confirmMessage}
                    body={permDeleteMessage}
                    cancelButtonMessage={cancelMessage}
                    onConfirmation={onDeletePlaylistHandler}
                    onCancel={onCloseModalHandler}
                />
            )}
            <section className="hero is-hero-bar">
                <div className="hero-body">
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <h1 className="title">{useFormatMessage('Playlists.playlists')} ({playlistsTotal})</h1>
                            </div>
                        </div>
                        <div className="level-right">
                            <div className="level-item">
                                <Link to={paths.ADD_PLAYLIST} className="button">
                                    {useFormatMessage('Playlists.newPlaylist')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section is-main-section">
                <div className="card has-table has-mobile-sort-spaced">
                    <header className="card-header">
                        <p className={classNames('card-header-title', classes.tableHeader)}>
                            <span>{useFormatMessage('Playlists.search')}</span>
                            <input
                                type="text"
                                className="input"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </p>
                    </header>
                    <div className="b-table">
                        {loading ? <ClipLoader /> : <RemoteTable
                            columns={cols}
                            data={tableData}
                            onPageSizeChange={(size) => setPageSize(size)}
                            onPageChange={(pageNo, movementType) => handlePageChange(pageNo, movementType)}
                            onSort={(attribute) => handleSort(attribute)}
                            pages={Math.ceil(playlistsTotal / pageSize)}
                            page={page}
                            pageSize={pageSize}
                        />}
                        {error && 'Show error'}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Playlists;
