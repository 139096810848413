import {createAction} from 'redux-act';
import {toast} from 'react-toastify';

import {firebaseError, getImgThumb} from 'utils';
import firebase from 'firebase.js';
import {checkUserData} from './auth';
import {
    fetchCollection,
    fetchDocument,
    fetchDocuments,
    createDocument,
    deleteDocument,
    updateDocument,
} from '../api';
import {searchIndex} from "../../service/algolia";

export const PLAYLISTS_FETCH_DATA_INIT = createAction('PLAYLISTS_FETCH_DATA_INIT');
export const PLAYLISTS_FETCH_DATA_SUCCESS = createAction('PLAYLISTS_FETCH_DATA_SUCCESS');
export const PLAYLIST_FETCH_DATA_SUCCESS = createAction('PLAYLIST_FETCH_DATA_SUCCESS');
export const PLAYLISTS_FETCH_DATA_FAIL = createAction('PLAYLISTS_FETCH_DATA_FAIL');

export const PLAYLISTS_FEATURED_PLAYLIST_INIT = createAction('PLAYLISTS_FEATURED_PLAYLIST_INIT');
export const PLAYLISTS_FEATURED_PLAYLIST_SUCCESS = createAction('PLAYLISTS_FEATURED_PLAYLIST_SUCCESS');

export const PLAYLISTS_DELETE_PLAYLIST_INIT = createAction('PLAYLISTS_DELETE_PLAYLIST_INIT');
export const PLAYLISTS_DELETE_PLAYLIST_SUCCESS = createAction('PLAYLISTS_DELETE_PLAYLIST_SUCCESS');
export const PLAYLISTS_DELETE_PLAYLIST_FAIL = createAction('PLAYLISTS_DELETE_PLAYLIST_FAIL');

export const PLAYLISTS_CREATE_PLAYLIST_INIT = createAction('PLAYLISTS_CREATE_PLAYLIST_INIT');
export const PLAYLISTS_CREATE_PLAYLIST_SUCCESS = createAction('PLAYLISTS_CREATE_PLAYLIST_SUCCESS');
export const PLAYLISTS_CREATE_PLAYLIST_FAIL = createAction('PLAYLISTS_CREATE_PLAYLIST_FAIL');

export const PLAYLISTS_MODIFY_PLAYLIST_INIT = createAction('PLAYLISTS_MODIFY_PLAYLIST_INIT');
export const PLAYLISTS_MODIFY_PLAYLIST_SUCCESS = createAction('PLAYLISTS_MODIFY_PLAYLIST_SUCCESS');
export const PLAYLISTS_MODIFY_PLAYLIST_FAIL = createAction('PLAYLISTS_MODIFY_v_FAIL');

export const PLAYLISTS_CLEAN_UP = createAction('PLAYLISTS_CLEAN_UP');

export const PLAYLISTS_CLEAR_DATA_LOGOUT = createAction('PLAYLISTS_CLEAR_DATA_LOGOUT');

export const paginatePlaylists = (pageSize, search, direction, attribute) => {
    return async (dispatch, getState) => {
        const prevPlaylists = [...getState().playlists.data];
        const prevTotal = getState().playlists.total;
        const prevPage = getState().playlists.page;
        let newPage = (direction === 'init') ? 1 : (prevPage || 1);
        if (direction === 'next') {
            newPage += 1;
        } else if (direction === 'prev') {
            newPage -= 1;
        }

        const prevOrder = getState().playlists.sortConfig.order;
        const prevAttribute = getState().playlists.sortConfig.attribute;

        const newAttribute = attribute || prevAttribute;
        let newOrder = 'asc';
        if (attribute === prevAttribute) {
            newOrder = prevOrder === 'asc' ? 'desc' : 'asc';
        }
        const newSortConfig = {
            attribute: newAttribute,
            order: newOrder
        };

        dispatch(checkUserData());
        dispatch(PLAYLISTS_FETCH_DATA_INIT());

        let playlists;
        let total = prevTotal;
        try {
            if (search) {
                const res = await searchIndex('playlists', search, newPage - 1, pageSize);
                const ids = res.hits.map((hit) => hit.objectID);

                playlists = await fetchDocuments('playlists', ids);
                total = res.nbHits;
            } else if (direction === 'next') {
                playlists = await fetchCollection('playlists', {
                    sort: newSortConfig,
                    startAfter: prevPlaylists.pop().name,
                    pageSize,
                });
            } else if (direction === 'prev') {
                playlists = await fetchCollection('playlists', {
                    sort: newSortConfig,
                    endBefore: prevPlaylists.shift().name,
                    pageSize,
                });
            } else {
                playlists = await fetchCollection('playlists', {
                    sort: newSortConfig,
                    pageSize,
                });
                total = await firebase.firestore().collection('playlists').get().then((counter) => {
                  return counter.size;
                });
            }
        } catch (error) {
            toast.error(error);
            return dispatch(PLAYLISTS_FETCH_DATA_FAIL({error}));
        }

        return dispatch(
            PLAYLISTS_FETCH_DATA_SUCCESS({
                data: playlists,
                total,
                page: newPage,
                sortConfig: newSortConfig
            })
        );
    };
};

export const fetchPlaylist = (id) => {
    return async (dispatch, getState) => {
        dispatch(checkUserData());
        dispatch(PLAYLISTS_FETCH_DATA_INIT());

        let playlist;
        try {
            playlist = await fetchDocument('playlists', id);
            playlist.songs = await fetchDocuments('songs', playlist.songs);
        } catch (error) {
            toast.error(error);
            return dispatch(PLAYLISTS_FETCH_DATA_FAIL({error}));
        }

        if (!playlist) {
            const errorMessage = 'Playlist not available';
            toast.error(errorMessage);
            return dispatch(PLAYLISTS_FETCH_DATA_FAIL({error: errorMessage}));
        }

        return dispatch(
            PLAYLIST_FETCH_DATA_SUCCESS({
                playlist,
            })
        );
    };
};

const deleteFile = (oldImage) => {
    if (!oldImage.includes('firebasestorage')) {
        return null;
    }

    const imagePath = oldImage.split('playlists%2F').pop();
    const thumbPath = getImgThumb(oldImage).split('playlists%2F').pop();

    return Promise.all([
        firebase.storage().ref(`playlists/${imagePath}`).delete(),
        firebase.storage().ref(`playlists/${thumbPath}`).delete()
    ]);
};

export const deletePlaylist = (id) => {
    return async (dispatch, getState) => {
        dispatch(PLAYLISTS_DELETE_PLAYLIST_INIT());
        const {locale} = getState().preferences;
        const {img, url} = getState()
            .playlists.data.filter((playlist) => playlist.id === id)
            .pop();

        const deleteFileTask = img ? deleteFile(img) : null;
        const deletePlaylistTask = deleteDocument('playlists', id);

        try {
            await Promise.all([deleteFileTask, deletePlaylistTask]);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                PLAYLISTS_DELETE_PLAYLIST_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('The playlist was deleted.');
        return dispatch(PLAYLISTS_DELETE_PLAYLIST_SUCCESS({id}));
    };
};

export const clearPlaylistsDataLogout = () => {
    return (dispatch) => {
        dispatch(PLAYLISTS_CLEAR_DATA_LOGOUT());
    };
};

const uploadFile = (uid, file) => {
    const storageRef = firebase.storage().ref();
    const fileExtension = file.name.split('.').pop();
    const fileName = `${uid}.${fileExtension}`;
    return storageRef.child(`playlists/${fileName}`).put(file);
};

const getImageUrl = (uid, file) => {
    const fileExtension = file.name.split('.').pop();
    const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;
    return `${bucketUrl}/o/playlists%2F${uid}.${fileExtension}`;
};

export const createPlaylist = ({name, file, songs}) => {
    return async (dispatch, getState) => {
        dispatch(PLAYLISTS_CREATE_PLAYLIST_INIT());
        const {locale} = getState().preferences;

        const playlistData = {
            name,
            songs: songs.map((song) => {
                return (song.id) ? song.id : song;
            }),
            createdAt: new Date()
        };
        let response;
        try {
            response = await createDocument('playlists', null, playlistData);


            if (file) {
                const imgUrl = getImageUrl(response.id, file);
                await uploadFile(response.id, file);

                playlistData.img = imgUrl;
                await updateDocument('playlists', response.id, playlistData);
            }

            playlistData.id = response.id;
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                PLAYLISTS_CREATE_PLAYLIST_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('Playlist created successfully');
        return dispatch(PLAYLISTS_CREATE_PLAYLIST_SUCCESS({
            playlist: {
                ...playlistData,
                songs: await fetchDocuments('songs', playlistData.songs),
            }
        }));
    };
};

export const modifyPlaylist = ({
                                   id,
                                   name,
                                   section,
                                   songs,
                                   file,
                               }) => {
    return async (dispatch, getState) => {
        dispatch(PLAYLISTS_MODIFY_PLAYLIST_INIT());
        const {locale} = getState().preferences;
        const currentPlaylist = getState().playlists.playlist;
        if(currentPlaylist.id !== id) {
            return dispatch(
                PLAYLISTS_MODIFY_PLAYLIST_FAIL({
                    error: 'Technical error',
                })
            );
        }
        const {img} = currentPlaylist;

        let deleteImgTask;
        let uploadImgTask;
        let newImgUrl = null;
        if (file) {
            newImgUrl = getImageUrl(id, file);
            deleteImgTask = img && deleteFile(img);
            uploadImgTask = uploadFile(id, file);
        }

        const playlistData = {
            name,
            section,
            private: false,
            updatedAt: new Date(),
            songs: songs.map((song) => {
                return (song.id) ? song.id : song;
            }),
            img: newImgUrl || img,
        };
        const updatePlaylistDbTask = updateDocument('playlists', id, playlistData);

        try {
            await Promise.all([deleteImgTask, uploadImgTask, updatePlaylistDbTask]);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                PLAYLISTS_MODIFY_PLAYLIST_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('Playlist updated successfully');

        return dispatch(PLAYLISTS_FETCH_DATA_SUCCESS({
            playlist: {
                ...playlistData,
                songs: await fetchDocuments('songs', playlistData.songs),
            },
            id
        }));
    };
};

export const playlistsCleanUp = () => (dispatch) => dispatch(PLAYLISTS_CLEAN_UP());
