import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {ToastContainer} from 'react-toastify';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import LanguageWrapper from 'components/LanguageWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { configureStore } from './state/store';
import './index.scss';
import Router from './pages/Router';
import * as serviceWorker from './serviceWorker';

import './assets/css/main.css';

const { store, persistor } = configureStore({});

Sentry.init({
  dsn: "https://d9868a5f78fa41b2be492a31424b1b03@o1012100.ingest.sentry.io/5977326",
  environment: window.location.hostname,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const app = (
  <Provider store={store}>
    <LanguageWrapper>
      <PersistGate persistor={persistor}>
          <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
          />
        <Router />
      </PersistGate>
    </LanguageWrapper>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
