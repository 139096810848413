import React, {useEffect, useMemo} from 'react';
import {useParams, Redirect} from 'react-router-dom';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import UserForm from 'components/UserForm';
import {createUser, modifyUser, fetchUser} from 'state/actions/users';
import paths from 'pages/Router/paths';
import {useFormatMessage} from 'hooks';

const schema = yup.object().shape({
    email: yup.string().email().required(),
    name: yup.string().required(),
    isAdmin: yup.boolean().notRequired(),
    isLegal: yup.boolean().notRequired(),
    location: yup.string().notRequired(),
    createdAt: yup.string().required(),
    roles: yup.array().of(yup.object()),
});

const User = () => {
    const {id} = useParams();

    const isEditing = useMemo(() => !!id, [id]);

    const {success, userData, error} = useSelector(
        (state) => ({
            success: state.users.success,
            userData: state.users.user,
            error: state.users.error,
        }),
        shallowEqual
    );

    const dispatch = useDispatch();

    useEffect(() => {
        if (isEditing) {
            if (!userData) {
                dispatch(fetchUser(id));
            }
        }
    }, [isEditing, id, userData, dispatch]);

    const redirect = ((isEditing && error) || success) && (
        <Redirect to={paths.USERS}/>
    );

    const editUserMessage = useFormatMessage('User.editUser');

    const newUserMessage = useFormatMessage('User.editUser');

    const onSubmitHandler = (value) => {
        const newUser = {
            ...value,
            file: value?.file[0] || null,
            isEditing,
            id,
            roles: value.roles ? value.roles.map(selected => selected.value) : [],
            createdAt: new Date(value.createdAt)
        };

        if (isEditing) {
            dispatch(modifyUser(newUser));
        } else {
            dispatch(createUser(newUser));
        }
    };

    const getFormData = () => {
        return (isEditing) ? {
            ...userData,
            createdAt: (userData.createdAt.seconds) ? (new Date(userData.createdAt.seconds * 1000)) : userData.createdAt
        } : {
            name: '',
            email: '',
            location: '',
            roles: [],
            createdAt: new Date(),
            isAdmin: false,
            isLegal: false,
        };
    };

    return (
        <>
            {redirect}
            <section className="hero is-hero-bar">
                <div className="hero-body">
                    <h1 className="title">
                        {isEditing ? editUserMessage : newUserMessage}
                    </h1>
                </div>
            </section>
            <section className="section is-main-section">
                {isEditing && !userData ? (
                    <ClipLoader/>
                ) : (
                    <UserForm
                        isEditing={isEditing}
                        user={getFormData()}
                        onSubmitHandler={onSubmitHandler}
                        schema={schema}
                    />
                )}
            </section>
        </>
    );
};

export default User;
