/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers';

import paths from 'pages/Router/paths';
import { playlistsCleanUp } from 'state/actions/playlists';
import { useFormatMessage } from 'hooks';
import ErrorMessage from 'components/ErrorMessage';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { getImgThumb } from 'utils';

import { fetchCollection } from '../../state/api';
import { searchIndex  } from '../../service/algolia';

import './PlaylistForm.scss';
import AudioButton from "../AudioButton";

const MultiValueContainer = props => {
  const {children, data} = props;
  return (
    <components.MultiValueContainer {...props}>
      <AudioButton url={`${data.data.url}?alt=media`}/>
      {children}
    </components.MultiValueContainer>
  );
};

const PlaylistForm = ({ playlist, onSubmitHandler, schema }) => {
  const { loading, success } = useSelector(
    (state) => ({
      loading: state.playlists.loading,
      success: state.playlists.success,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const { register, handleSubmit, errors, setValue, watch, control } = useForm({
    defaultValues: {
      ...playlist,
      songs: playlist.songs.map(song => {return {value: song.id, label: song.name, data: song};})
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (success) {
       setValue('file', null);
    }
    return () => dispatch(playlistsCleanUp());
  }, [dispatch, success, setValue]);


  const imagePreviewUrl =
    watch('file') && watch('file')[0]
      ? URL.createObjectURL(watch('file')[0])
      : getImgThumb(playlist.img, true);

  const goBackMessage = useFormatMessage('PlaylistForm.goBack');
  const pickAnotherFileMessage = useFormatMessage('PlaylistForm.pickAnotherFile');
  const pickFileMessage = useFormatMessage('PlaylistForm.pickFile');

  const loadSongs = (searchTerm) => {
    const options = {};
    if(searchTerm) {
      return searchIndex('songs', searchTerm, 0, 50).then((res) => {
        return res.hits.map((hit) => {
          return {
            value: hit.objectID,
            label: `${hit.name} - ${hit.artists.map((artist) => artist.name).join(', ')}`,
            data: hit
          };
        });
      });
    }
    return fetchCollection('songs', {pageSize: 50}).then((songs) => {
      return songs.map((song) => {
        return {
          value: song.id,
          label: song.name,
          data: song
        };
      });
    });
  };

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                {useFormatMessage('PlaylistForm.playlistInfo')}
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('PlaylistForm.name')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="name"
                          id="name"
                          className={classNames('input', {
                            'is-danger': errors.name,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.name && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('PlaylistForm.songs')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <Controller
                            as={AsyncSelect}
                            name="songs"
                            control={control}
                            loadOptions={loadSongs}
                            components={{MultiValueContainer}}
                            openMenuOnFocus={false}
                            openMenuOnClick={false}
                            defaultOptions
                            isMulti
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.songs && (
                    <div className="field is-horizontal">
                      <div className="field-label is-normal" />
                      <div className="field-body">
                        <ErrorMessage />
                      </div>
                    </div>
                )}
                {/**
                 Playlist section type

                 options = ['mood','featured','archive','public', 'unpublished']

                 */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('PlaylistForm.section')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <select name="section" id="section"   ref={register} >
                          <option value="unpublished">{useFormatMessage('PlaylistForm.unpublished')}</option>
                          <option value="mood">{useFormatMessage('PlaylistForm.mood')}</option>
                          <option value="featured">{useFormatMessage('PlaylistForm.featured')}</option>
                          <option value="archive">{useFormatMessage('PlaylistForm.archive')}</option>
                          <option value="public">{useFormatMessage('PlaylistForm.public')}</option>
                          <option value="genre">{useFormatMessage('PlaylistForm.genre')}</option>
                        </select>

                      </div>
                    </div>
                  </div>
                </div>


                <hr />

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('PlaylistForm.logo')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="file has-name">
                        <label className="file-label">
                          <input
                            className="file-input"
                            type="file"
                            name="file"
                            ref={register}
                            accept="image/*"
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="mdi mdi-upload" />
                            </span>
                            <span className="file-label">
                              {watch('file') && watch('file').file
                                ? pickAnotherFileMessage
                                : pickFileMessage}
                            </span>
                          </span>
                          <span className="file-name">
                            {watch('file') && watch('file')[0]?.name}
                          </span>
                        </label>
                      </div>
                      <em>Cover art must be square .jpg or .png file and be at least 1000x1000 px.</em>
                      {imagePreviewUrl && (
                          <div className="has-max-width">
                            <img
                                className="album-img"
                                src={imagePreviewUrl}
                                alt="User profile logo preview"
                            />
                          </div>
                      )}
                    </div>
                  </div>
                </div>
                {errors.file && (
                    <div className="field is-horizontal">
                      <div className="field-label is-normal" />
                      <div className="field-body">
                        <ErrorMessage />
                      </div>
                    </div>
                )}

                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('PlaylistForm.submit')}</span>
                          </button>
                        </div>
                        <Link to={paths.PLAYLISTS} className="button">
                          {goBackMessage}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

PlaylistForm.propTypes = {
  playlist: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    section: PropTypes.string,
    songs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })),
    img: PropTypes.string,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
};

PlaylistForm.defaultProps = {

};

export default PlaylistForm;
