import {createReducer} from 'redux-act';

import {
    APPROVALS_SONGS_FETCH_DATA_INIT,
    APPROVALS_SONGS_FETCH_DATA_SUCCESS,
    APPROVALS_SONG_FETCH_DATA_SUCCESS,
    APPROVALS_SONGS_FETCH_DATA_FAIL,
    APPROVALS_SONGS_MODIFY_SONG_INIT,
    APPROVALS_SONGS_MODIFY_SONG_SUCCESS,
    APPROVALS_SONGS_MODIFY_SONG_FAIL,
    APPROVALS_SONGS_CLEAN_UP,
    APPROVALS_SONGS_CLEAR_DATA_LOGOUT,
} from 'state/actions/songs_approvals';

const initialState = {
    data: [],
    song: null,
    total: 0,
    page: 1,
    loading: false,
    error: null,
    success: false,
    deleted: false,
    sortConfig: {
      attribute: 'name',
      order: 'asc'
    }
};

export const approvalsSongsReducer = createReducer(
    {
        [APPROVALS_SONGS_FETCH_DATA_INIT]: () => ({
            ...initialState,
            loading: true,
        }),
        [APPROVALS_SONG_FETCH_DATA_SUCCESS]: (state, payload) => ({
            ...state,
            song: payload.song,
            loading: false,
            error: null,
        }),
        [APPROVALS_SONGS_FETCH_DATA_SUCCESS]: (state, payload) => ({
            ...state,
            data: payload.data,
            total: payload.total,
            page: payload.page,
            sortConfig: payload.sortConfig,
            loading: false,
            error: null,
        }),
        [APPROVALS_SONGS_FETCH_DATA_FAIL]: (state, payload) => ({
            ...state,
            loading: false,
            error: payload.error,
        }),
        [APPROVALS_SONGS_MODIFY_SONG_INIT]: (state) => ({
            ...state,
            loading: true,
        }),
        [APPROVALS_SONGS_MODIFY_SONG_SUCCESS]: (state, payload) => ({
            ...state,
            data: !state.data
                ? []
                : state.data.map((elem) => {
                    if (elem.id === payload.id) {
                        return {
                            name: payload.song.name,
                            id: payload.id,
                            img: payload.song.img,
                            url: payload.song.url,
                            artists: payload.song.artists,
                            createdAt: payload.song.createdAt,
                        };
                    }
                    return elem;
                }),
            loading: false,
            error: null,
            success: true,
        }),
        [APPROVALS_SONGS_MODIFY_SONG_FAIL]: (state, payload) => ({
            ...state,
            loading: false,
            error: payload.error,
        }),
        [APPROVALS_SONGS_CLEAN_UP]: (state) => ({
            ...state,
            loading: false,
            error: null,
            success: false,
            deleted: false,
        }),
        [APPROVALS_SONGS_CLEAR_DATA_LOGOUT]: () => ({
            ...initialState,
        }),
    },
    initialState
);
