import {createReducer} from 'redux-act';

import {
    PLAYLISTS_FETCH_DATA_INIT,
    PLAYLISTS_FETCH_DATA_SUCCESS,
    PLAYLIST_FETCH_DATA_SUCCESS,
    PLAYLISTS_FETCH_DATA_FAIL,
    PLAYLISTS_FEATURED_PLAYLIST_INIT,
    PLAYLISTS_FEATURED_PLAYLIST_SUCCESS,
    PLAYLISTS_DELETE_PLAYLIST_INIT,
    PLAYLISTS_DELETE_PLAYLIST_SUCCESS,
    PLAYLISTS_DELETE_PLAYLIST_FAIL,
    PLAYLISTS_CREATE_PLAYLIST_INIT,
    PLAYLISTS_CREATE_PLAYLIST_SUCCESS,
    PLAYLISTS_CREATE_PLAYLIST_FAIL,
    PLAYLISTS_MODIFY_PLAYLIST_INIT,
    PLAYLISTS_MODIFY_PLAYLIST_SUCCESS,
    PLAYLISTS_MODIFY_PLAYLIST_FAIL,
    PLAYLISTS_CLEAN_UP,
    PLAYLISTS_CLEAR_DATA_LOGOUT,
} from 'state/actions/playlists';

const initialState = {
    data: [],
    playlist: null,
    total: 0,
    page: 1,
    loading: false,
    error: null,
    success: false,
    deleted: false,
    sortConfig: {
      attribute: 'name',
      order: 'asc'
    }
};

export const playlistsReducer = createReducer(
    {
        [PLAYLISTS_FETCH_DATA_INIT]: () => ({
            ...initialState,
            loading: true,
        }),
        [PLAYLIST_FETCH_DATA_SUCCESS]: (state, payload) => ({
            ...state,
            playlist: payload.playlist,
            loading: false,
            error: null,
        }),
        [PLAYLISTS_FETCH_DATA_SUCCESS]: (state, payload) => ({
            ...state,
            data: payload.data,
            total: payload.total,
            page: payload.page,
            sortConfig: payload.sortConfig,
            loading: false,
            error: null,
        }),
        [PLAYLISTS_FETCH_DATA_FAIL]: (state, payload) => ({
            ...state,
            loading: false,
            error: payload.error,
        }),
        [PLAYLISTS_FEATURED_PLAYLIST_INIT]: (state) => ({
            ...state,
            loading: true,
        }),
        [PLAYLISTS_FEATURED_PLAYLIST_SUCCESS]: (state, payload) => ({
            ...state,
            // playlist: payload.playlist,
            loading: false,
            error: null,
        }),
        [PLAYLISTS_DELETE_PLAYLIST_INIT]: (state) => ({
            ...state,
            loading: true,
        }),
        [PLAYLISTS_DELETE_PLAYLIST_SUCCESS]: (state, payload) => ({
            ...state,
            data: state.data.filter((elem) => elem.id !== payload.id),
            loading: false,
            error: null,
            deleted: true,
        }),
        [PLAYLISTS_DELETE_PLAYLIST_FAIL]: (state, payload) => ({
            ...state,
            loading: false,
            error: payload.error,
        }),
        [PLAYLISTS_CREATE_PLAYLIST_INIT]: (state) => ({
            ...state,
            loading: true,
        }),
        [PLAYLISTS_CREATE_PLAYLIST_SUCCESS]: (state, payload) => ({
            ...state,
            data: state.data.concat(payload.playlist),
            loading: false,
            error: null,
            success: true,
        }),
        [PLAYLISTS_CREATE_PLAYLIST_FAIL]: (state, payload) => ({
            ...state,
            loading: false,
            error: payload.error,
        }),
        [PLAYLISTS_MODIFY_PLAYLIST_INIT]: (state) => ({
            ...state,
            loading: true,
        }),
        [PLAYLISTS_MODIFY_PLAYLIST_SUCCESS]: (state, payload) => ({
            ...state,
            data: !state.data
                ? []
                : state.data.map((elem) => {
                    if (elem.id === payload.id) {
                        return {
                            name: payload.playlist.name,
                            id: payload.id,
                            img: payload.playlist.img,
                            songs: payload.playlist.songs,
                            createdAt: payload.playlist.createdAt,
                        };
                    }
                    return elem;
                }),
            loading: false,
            error: null,
            success: true,
        }),
        [PLAYLISTS_MODIFY_PLAYLIST_FAIL]: (state, payload) => ({
            ...state,
            loading: false,
            error: payload.error,
        }),
        [PLAYLISTS_CLEAN_UP]: (state) => ({
            ...state,
            loading: false,
            error: null,
            success: false,
            deleted: false,
        }),
        [PLAYLISTS_CLEAR_DATA_LOGOUT]: () => ({
            ...initialState,
        }),
    },
    initialState
);
