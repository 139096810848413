import { createReducer } from 'redux-act';

import {
  GENRES_FETCH_DATA_INIT,
  GENRES_FETCH_DATA_SUCCESS,
  GENRES_FETCH_DATA_FAIL,
  GENRES_DELETE_GENRE_INIT,
  GENRES_DELETE_GENRE_SUCCESS,
  GENRES_DELETE_GENRE_FAIL,
  GENRES_CREATE_GENRE_INIT,
  GENRES_CREATE_GENRE_SUCCESS,
  GENRES_CREATE_GENRE_FAIL,
  GENRES_MODIFY_GENRE_INIT,
  GENRES_MODIFY_GENRE_SUCCESS,
  GENRES_MODIFY_GENRE_FAIL,
  GENRES_CLEAN_UP,
  GENRES_CLEAR_DATA_LOGOUT,
} from 'state/actions/genres';

const initialState = {
  data: [],
  total: 0,
  page: 1,
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const genresReducer = createReducer(
  {
    [GENRES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [GENRES_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data,
      total: payload.total,
      page: payload.page,
      loading: false,
      error: null,
    }),
    [GENRES_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [GENRES_DELETE_GENRE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [GENRES_DELETE_GENRE_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [GENRES_DELETE_GENRE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [GENRES_CREATE_GENRE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [GENRES_CREATE_GENRE_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat(payload.genre),
      loading: false,
      error: null,
      success: true,
    }),
    [GENRES_CREATE_GENRE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [GENRES_MODIFY_GENRE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [GENRES_MODIFY_GENRE_SUCCESS]: (state, payload) => ({
      ...state,
      data: !state.data
        ? []
        : state.data.map((elem) => {
            if (elem.id === payload.id) {
              return {
                name: payload.genre.name,
                id: payload.id,
                // logoUrl: payload.user.logoUrl,
                createdAt: payload.genre.createdAt,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
    }),
    [GENRES_MODIFY_GENRE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [GENRES_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [GENRES_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
