import React, { useEffect, useMemo } from 'react';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';

import SongForm from 'components/SongForm';
import { createSong, modifySong, fetchSong } from 'state/actions/songs';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';

const Song = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success, songData, error } = useSelector(
    (state) => ({
      success: state.songs.success,
      songData: state.songs.song,
      error: state.songs.error,
    }),
    shallowEqual
  );


  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      if (!songData) {
        dispatch(fetchSong(id));
      }
    }
  }, [isEditing, id, songData, dispatch]);
  
  const history = useHistory();
  const redirect = ((isEditing && error) || success) && (
    <Redirect to={paths.SONGS} />
  );
  
  const editSongMessage = useFormatMessage('Song.editSong');

  const newSongMessage = useFormatMessage('Song.addSong');

  const onSubmitHandler = (value) => {
    console.log(value);
    const newSong = {
      ...value,
      artists: value.artists.map(selected => selected.value),
      songFile: value?.songFile[0] ||  null,
      ownerId: value.owner.value,
      isEditing,
      id,
    };

    if (isEditing) {
      dispatch(modifySong(newSong));
    } else {
      dispatch(createSong(newSong));
    }
  };

const goBackHandler = () => {
  return history.push(paths.SONGS);
};

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editSongMessage : newSongMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !songData ? (
          <ClipLoader />
        ) : (
          <SongForm
            song={
              isEditing
                ? songData
                : {
                    name: '',
                    artists: [],
                    createdAt: new Date().toDateString(),
                    owner: {}
                  }
            }
            onSubmitHandler={onSubmitHandler}
            goBackHandler={goBackHandler}
          />
        )}
      </section>
    </>
  );
};

export default Song;
