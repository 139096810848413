import {createAction} from 'redux-act';
import {toast} from 'react-toastify';

import {firebaseError, getImgThumb} from 'utils';
import firebase from 'firebase.js';
import {checkUserData} from './auth';
import {
    fetchCollection,
    fetchDocument,
    fetchDocuments,
    createDocument,
    deleteDocument,
    updateDocument,
} from '../api';
import {searchIndex} from "../../service/algolia";
import SongStore from "../../service/SongStore";

export const SONGS_FETCH_DATA_INIT = createAction('SONGS_FETCH_DATA_INIT');
export const SONGS_FETCH_DATA_SUCCESS = createAction('SONGS_FETCH_DATA_SUCCESS');
export const SONG_FETCH_DATA_SUCCESS = createAction('SONG_FETCH_DATA_SUCCESS');
export const SONGS_FETCH_DATA_FAIL = createAction('SONGS_FETCH_DATA_FAIL');

export const SONGS_DELETE_SONG_INIT = createAction('SONGS_DELETE_SONG_INIT');
export const SONGS_DELETE_SONG_SUCCESS = createAction(
    'SONGS_DELETE_SONG_SUCCESS'
);
export const SONGS_DELETE_SONG_FAIL = createAction('SONGS_DELETE_SONG_FAIL');

export const SONGS_CREATE_SONG_INIT = createAction('SONGS_CREATE_SONG_INIT');
export const SONGS_CREATE_SONG_SUCCESS = createAction(
    'SONGS_CREATE_SONG_SUCCESS'
);
export const SONGS_CREATE_SONG_FAIL = createAction('SONGS_CREATE_SONG_FAIL');

export const SONGS_MODIFY_SONG_INIT = createAction('SONGS_MODIFY_SONG_INIT');
export const SONGS_MODIFY_SONG_SUCCESS = createAction(
    'SONGS_MODIFY_SONG_SUCCESS'
);
export const SONGS_MODIFY_SONG_FAIL = createAction('SONGS_MODIFY_v_FAIL');

export const SONGS_CLEAN_UP = createAction('SONGS_CLEAN_UP');

export const SONGS_CLEAR_DATA_LOGOUT = createAction('SONGS_CLEAR_DATA_LOGOUT');

export const paginateSongs = (pageSize, search, direction, attribute) => {
    return async (dispatch, getState) => {
        const prevSongs = [...getState().songs.data];
        const prevTotal = getState().songs.total;
        const prevPage = getState().songs.page;
        let newPage = (direction === 'init') ? 1 : (prevPage || 1);
        if (direction === 'next') {
            newPage += 1;
        } else if (direction === 'prev') {
            newPage -= 1;
        }

        const prevOrder = getState().songs.sortConfig.order;
        const prevAttribute = getState().songs.sortConfig.attribute;

        const newAttribute = attribute || prevAttribute;
        let newOrder = 'asc';
        if (attribute === prevAttribute) {
            newOrder = prevOrder === 'asc' ? 'desc' : 'asc';
        }
        const newSortConfig = {
            attribute: newAttribute,
            order: newOrder
        };

        dispatch(checkUserData());
        dispatch(SONGS_FETCH_DATA_INIT());

        let songs;
        let total = prevTotal;
        try {
            if (search) {
                const res = await searchIndex('songs', search, newPage - 1, pageSize);
                const ids = res.hits.map((hit) => hit.objectID);

                songs = await fetchDocuments('songs', ids);
                total = res.nbHits;
            } else if (direction === 'next') {
                songs = await fetchCollection('songs', {
                    sort: newSortConfig,
                    startAfter: prevSongs.pop().name,
                    pageSize,
                });
            } else if (direction === 'prev') {
                songs = await fetchCollection('songs', {
                    sort: newSortConfig,
                    endBefore: prevSongs.shift().name,
                    pageSize,
                });
            } else {
                songs = await fetchCollection('songs', {
                    sort: newSortConfig,
                    pageSize,
                });
                total = await firebase.firestore().collection('songs').get().then((counter) => {
                  return counter.size;
                });
            }
        } catch (error) {
            toast.error(error);
            return dispatch(SONGS_FETCH_DATA_FAIL({error}));
        }

        return dispatch(
            SONGS_FETCH_DATA_SUCCESS({
                data: songs,
                total,
                page: newPage,
                sortConfig: newSortConfig
            })
        );
    };
};

export const fetchSong = (id) => {
    return async (dispatch, getState) => {
        dispatch(checkUserData());
        dispatch(SONGS_FETCH_DATA_INIT());

        let song;
        try {
            song = await fetchDocument('songs', id);
            song.artists = await fetchDocuments('artists', song.artists);
            song.owner = (song.ownerId) ? await fetchDocument('owners', song.ownerId): null;
        } catch (error) {
            toast.error(error);
            return dispatch(SONGS_FETCH_DATA_FAIL({error}));
        }

        if (!song) {
            const errorMessage = 'Song not available';
            toast.error(errorMessage);
            return dispatch(SONGS_FETCH_DATA_FAIL({error: errorMessage}));
        }

        return dispatch(
            SONG_FETCH_DATA_SUCCESS({
                song,
            })
        );
    };
};

export const fetchSongs = (songId = '') => {
    return async (dispatch, getState) => {
        dispatch(checkUserData());

        dispatch(SONGS_FETCH_DATA_INIT());

        if (songId) {
            let song;
            try {
                song = await fetchDocument('songs', songId);
                song.artists = await fetchDocuments('artists', song.artists);
            } catch (error) {
                toast.error(error);
                return dispatch(SONGS_FETCH_DATA_FAIL({error}));
            }

            if (!song) {
                const errorMessage = 'Song not available';
                toast.error(errorMessage);
                return dispatch(SONGS_FETCH_DATA_FAIL({error: errorMessage}));
            }

            const songs = getState().songs.data;
            songs.push(song);

            return dispatch(
                SONGS_FETCH_DATA_SUCCESS({
                    data: songs,
                })
            );
        }

        let songs;
        try {
            songs = await fetchCollection('songs');
            const artistIds = [];
            await songs.forEach((song) => {
                artistIds.push(...song.artists);
            });

            const artists = await fetchDocuments('artists', artistIds);

            songs = songs.map((song) => {
                return {
                    ...song,
                    artists: artists.filter((artist) => {
                        return song.artists.indexOf(artist.id) !== -1;
                    }),
                };
            });
        } catch (error) {
            console.log(error);
            toast.error(error);
            return dispatch(SONGS_FETCH_DATA_FAIL({error}));
        }

        return dispatch(
            SONGS_FETCH_DATA_SUCCESS({
                data: songs,
            })
        );
    };
};

const deleteFile = (oldImage) => {
    if (!oldImage.includes('firebasestorage')) {
        return null;
    }

    const imagePath = oldImage.split('songs%2F').pop();
    const thumbPath = getImgThumb(oldImage).split('songs%2F').pop();

    return Promise.all([
        firebase.storage().ref(`songs/${imagePath}`).delete(),
        firebase.storage().ref(`songs/${thumbPath}`).delete()
    ]);
};

const deleteSongFile = (oldUrl) => {
    if (!oldUrl.includes('firebasestorage')) {
        return null;
    }

    const path = oldUrl.split('songs%2F').pop();
    return firebase.storage().ref(`songs/${path}`).delete();
};

export const deleteSong = (id) => {
    return async (dispatch, getState) => {
        dispatch(SONGS_DELETE_SONG_INIT());
        const {locale} = getState().preferences;
        const {url} = getState()
            .songs.data.filter((song) => song.id === id)
            .pop();

        const deleteSongFileTask = url ? deleteSongFile(url) : null;
        const deleteSongTask = deleteDocument('songs', id);

        try {
            await Promise.all([deleteSongFileTask, deleteSongTask]);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                SONGS_DELETE_SONG_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('The song was deleted.');
        return dispatch(SONGS_DELETE_SONG_SUCCESS({id}));
    };
};

export const clearSongsDataLogout = () => {
    return (dispatch) => {
        dispatch(SONGS_CLEAR_DATA_LOGOUT());
    };
};

const uploadFile = (uid, file) => {
    const storageRef = firebase.storage().ref();
    const fileExtension = file.name.split('.').pop();
    const fileName = `${uid}.${fileExtension}`;
    return storageRef.child(`songs/${fileName}`).put(file);
};

const getImageUrl = (uid, file) => {
    const fileExtension = file.name.split('.').pop();
    const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;
    return `${bucketUrl}/o/songs%2F${uid}.${fileExtension}`;
};

const getSongUrl = (uid, file) => {
    const fileExtension = file.name.split('.').pop();
    const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;
    return `${bucketUrl}/o/songs%2F${uid}.${fileExtension}`;
};

export const createSong = (input) => {
    return async (dispatch, getState) => {
        dispatch(SONGS_CREATE_SONG_INIT());
        const {locale} = getState().preferences;

        let song;
        try {
            song = await SongStore.createSong(input);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                SONGS_CREATE_SONG_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('Song created successfully');
        return dispatch(SONGS_CREATE_SONG_SUCCESS({
            song: {
                ...song,
                artists: await fetchDocuments('artists', song.artists),
                owner: await fetchDocument('owners', song.ownerId)
            }
        }));
    };
};

export const modifySong = (newData) => {
    return async (dispatch, getState) => {
        dispatch(SONGS_MODIFY_SONG_INIT());
        const {locale} = getState().preferences;
        const currentSong = getState().songs.song;

        if (currentSong.id !== newData.id) {
            return dispatch(
                SONGS_MODIFY_SONG_FAIL({
                    error: 'Technical error',
                })
            );
        }

        let songData;
        try {
            songData = await SongStore.modifySong(currentSong, newData);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                SONGS_MODIFY_SONG_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('Song updated successfully');

        return dispatch(SONGS_MODIFY_SONG_SUCCESS({
            song: {
                ...songData,
                artists: await fetchDocuments('artists', songData.artists),
                owner: await fetchDocument('owners', songData.ownerId)
            },
            id: songData.id,
        }));
    };
};

export const songsCleanUp = () => (dispatch) => dispatch(SONGS_CLEAN_UP());
