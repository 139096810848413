/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from '@hookform/resolvers';
import classNames from 'classnames';
import ErrorMessage from 'components/ErrorMessage';
import { useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { transactionsCleanUp } from 'state/actions/transactions';
import Select from 'react-select';



const TransactionForm = ({ transaction, onSubmitHandler, schema }) => {
  const { loading, success } = useSelector(
    (state) => ({
      loading: state.transactions.loading,
      success: state.transactions.success,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const { handleSubmit, errors, control } = useForm({
    defaultValues: {
        ...transaction,
        id: transaction.id,
        status: { label: transaction.status, value: transaction.status}
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    return () => dispatch(transactionsCleanUp());
  }, [dispatch, success]);

  const goBackMessage = useFormatMessage('PlaylistForm.goBack');

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                {useFormatMessage('Transactions.transaction')}
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('TransactionForm.status')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                      <Controller
                          as={Select}
                          name='status'
                          control={control}
                          options={[
                              {
                                label: 'Pending',
                                value: 'Pending',
                              },
                              {
                                label: 'Failed',
                                value: 'Failed',
                              },
                              {
                                label: 'Completed',
                                value: 'Completed',
                              },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.status && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <hr />

                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('PlaylistForm.submit')}</span>
                          </button>
                        </div>
                        <Link to={paths.TRANSACTIONS} className="button">
                          {goBackMessage}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

TransactionForm.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
};

TransactionForm.defaultProps = {

};

export default TransactionForm;
