/* eslint-disable jsx-a11y/media-has-caption */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useFormatMessage, usePrevious} from 'hooks';
import {Redirect, Link} from 'react-router-dom';
import classNames from "classnames";
import ClipLoader from "react-spinners/ClipLoader";
import RemoteTable from 'components/RemoteTable';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import {paginateSongs, deleteSong, songsCleanUp} from 'state/actions/songs';

import classes from "../Users/Users.module.scss";

const Songs = () => {
    const {songsList, songsTotal, page, isAdmin, error, loading, deleted} = useSelector(
        (state) => {
            return ({
                songsList: state.songs.data,
                songsTotal: state.songs.total,
                page: state.songs.page,
                isAdmin: state.auth.userData.isAdmin,
                error: state.songs.error,
                loading: state.songs.loading,
                deleted: state.songs.deleted,
            });
        },
        shallowEqual
    );

    const [deleteModal, setDeleteModal] = useState({
        songId: null,
        isOpen: false,
    });
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [pageSize, setPageSize] = useState(50);
    const prev = usePrevious({page, pageSize, search});

    const handlePageChange = (newPage, movement) => {
        dispatch(paginateSongs(pageSize, search, movement));
    };

    const handleSort = (attribute) => {
        if(attribute) {
            dispatch(paginateSongs(pageSize, search, 'init', attribute));
        }
    };

    const pullInitial = () => dispatch(paginateSongs(pageSize, search, 'init'));

    useEffect(() => {
        if (isAdmin) {
            pullInitial();
        }

        return () => dispatch(songsCleanUp());
    }, [dispatch, isAdmin]);

    useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        if(prev === undefined) {
            return; // Only run once we have actually searched
        }

        const timeout = setTimeout(() => {
            pullInitial();
        }, 500);
        setSearchTimeout(timeout);
    }, [search]);

    useEffect(() => {
        if (prev && pageSize !== prev.pageSize) {
            pullInitial();
        }
    }, [pageSize]);

    useEffect(() => {
        if (deleted && !loading) {
            setDeleteModal((prevState) => ({
                songId: null,
                isOpen: !prevState.isOpen,
            }));
        }
    }, [deleted, loading]);

    const redirect = !isAdmin && <Redirect to={paths.ROOT}/>;

    const onRemoveButtonClickHandler = (songId) => {
        setDeleteModal((prevState) => ({
            songId,
            isOpen: !prevState.isOpen,
        }));
    };

    const onCloseModalHandler = () => {
        setDeleteModal({songId: null, isOpen: false});
    };

    const onDeleteSongHandler = () => {
        dispatch(deleteSong(deleteModal.songId));
    };

    const cols = [
        {
            name: 'Name',
            field: 'name',
        },
        {
            name: 'Created',
            field: 'createdAt',
        },
        {
            name: '',
        },
        {
            name: '',
        },
    ];

    const tableData = songsList.map((song) => {
        return [
            {
                value: song.name,
            },
            {
                value: song.createdAt ? new Date(song.createdAt.seconds * 1000).toLocaleDateString() : 'N/A',
            },
            {
                value: (
                    <audio controls preload="none">
                        <source src={`${song.url}?alt=media`}/>
                        Audio playing not supported
                    </audio>
                )
            },
            {
                className: 'is-actions-cell',
                value: (
                    <>
                        <div className="buttons is-right">
                            <Link to={`/songs/${song.id}`} className="button is-small is-primary">
                                <span className="icon is-small">
                                  <i className="mdi mdi-pencil-outline"/>
                                </span>

                            </Link>
                            <button
                                type="button"
                                className="button is-small is-danger"
                                onClick={() => onRemoveButtonClickHandler(song.id)}
                            >
                                <span className="icon is-small">
                                  <i className="mdi mdi-trash-can"/>
                                </span>
                            </button>
                        </div>
                    </>
                )
            },
        ];
    });


    const deleteMessage = useFormatMessage('Songs.delete');
    const confirmMessage = useFormatMessage('Songs.confirm');
    const permDeleteMessage = useFormatMessage('Songs.permDelete');
    const cancelMessage = useFormatMessage('Songs.cancel');

    return (
        <>
            {redirect}
            {deleteModal.isOpen && (
                <ConfirmationModal
                    isActive={deleteModal.isOpen}
                    isLoading={loading}
                    confirmButtonMessage={deleteMessage}
                    title={confirmMessage}
                    body={permDeleteMessage}
                    cancelButtonMessage={cancelMessage}
                    onConfirmation={onDeleteSongHandler}
                    onCancel={onCloseModalHandler}
                />
            )}
            <section className="hero is-hero-bar">
                <div className="hero-body">
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <h1 className="title">{useFormatMessage('Songs.songs')} ({songsTotal})</h1>
                            </div>
                        </div>
                        <div className="level-right">
                            <div className="level-item">
                                <Link to={paths.ADD_SONG} className="button">
                                    {useFormatMessage('Songs.newSong')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section is-main-section">
                <div className="card has-table has-mobile-sort-spaced">
                    <header className="card-header">
                        <p className={classNames('card-header-title', classes.tableHeader)}>
                            <span>{useFormatMessage('Songs.search')}</span>
                            <input
                                type="text"
                                className="input"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </p>
                    </header>
                    <div className="b-table">
                        {loading ? <ClipLoader/> : <RemoteTable
                            columns={cols}
                            data={tableData}
                            onPageSizeChange={(size) => setPageSize(size)}
                            onPageChange={(pageNo, movementType) => handlePageChange(pageNo, movementType)}
                            onSort={(attribute) => handleSort(attribute)}
                            pages={Math.ceil(songsTotal / pageSize)}
                            page={page}
                            pageSize={pageSize}
                        />}
                        {error && 'Show error'}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Songs;
