import firebase from 'firebase.js';
import { getImgThumb} from '../utils';
import {
    fetchCollection,
    fetchDocument,
    fetchDocuments,
    createDocument,
    deleteDocument,
    updateDocument,
} from '../state/api';


const getImageUrl = (uid, file) => {
    const fileExtension = file.name.split('.').pop();
    const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;
    return `${bucketUrl}/o/songs%2F${uid}.${fileExtension}`;
};

const getSongUrl = (uid, file) => {
    const fileExtension = file.name.split('.').pop();
    const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;
    return `${bucketUrl}/o/songs%2F${uid}.${fileExtension}`;
};

const uploadFile = (uid, file) => {
    const storageRef = firebase.storage().ref();
    const fileExtension = file.name.split('.').pop();
    const fileName = `${uid}.${fileExtension}`;
    console.log(file);
    console.log("uploading to ", fileName);
    return storageRef.child(`songs/${fileName}`).put(file);
};

const deleteFile = (oldImage) => {
    if (!oldImage.includes('firebasestorage')) {
        return null;
    }

    const imagePath = oldImage.split('songs%2F').pop();
    const thumbPath = getImgThumb(oldImage).split('songs%2F').pop();

    const fileExtension = imagePath.split('.').pop();
    return fileExtension === 'm4a'
        ? firebase.storage().ref(`songs/${imagePath}`).delete()
        : Promise.all([
        firebase.storage().ref(`songs/${imagePath}`).delete(),
        firebase.storage().ref(`songs/${thumbPath}`).delete()
    ]);
};

class SongStore {

    /* eslint-disable class-methods-use-this */
    static async createSong({name, artists, songFile, ownerId, defaultImg = null, defaultRecordingYear = null, availableFrom}) {
        const songData = {
            name,
            artists: artists.map((artist) => {
                return (artist.id) ? artist.id : artist;
            }),
            createdAt: new Date(),
            img: defaultImg || '',
            url: '',
            isrc: '',
            recordingYear: defaultRecordingYear || null,
            ownerId,
            approved: false,
            availableFrom: availableFrom || new Date()
        };

        const convertedFile = songFile;
      return createDocument('songs', null, songData).then((response) => {
        const tasks = [];
        if (convertedFile) {
          console.log("before upload");
          tasks.push(uploadFile(response.id, convertedFile));
          songData.url = getSongUrl(response.id, convertedFile);
        }

        if(tasks.length) {
          tasks.push(updateDocument('songs', response.id, songData));
        }

        return (tasks.length) ? Promise.all(tasks).then(() => ({...songData, id: response.id})) : {...songData, id: response.id};
      });
/*
        return convertWavToM4a(songFile).then((convertedFile) => {

        });

 */
    }

    static async modifySong(currentSong, newData) {
        const tasks = [];

        if(currentSong.url && newData.songFile) {
            tasks.push(deleteFile(currentSong.url));
        }

        let newSongUrl = null;
        if(newData.songFile) {
            const convertedFile = newData.songFile;
            // await convertWavToM4a(newData.songFile);
            newSongUrl = getSongUrl(currentSong.id, convertedFile);
            tasks.push(uploadFile(currentSong.id, convertedFile));
        }

        const artists = newData.artists || currentSong.artists;
        const songData = {
            name: newData.name || currentSong.name,
            artists: artists.map((artist) => {
                return (artist.id) ? artist.id : artist;
            }),
            url: newSongUrl || currentSong.url,
            isrc: newData.isrc || currentSong.isrc || null,
            recordingYear: newData.recordingYear || null,
            ownerId: newData.ownerId,
            approved: newData.approved ?? currentSong.approved,
            availableFrom: currentSong.availableFrom || new Date()
        };
        tasks.push(updateDocument('songs', currentSong.id, songData));

        return Promise.all(tasks).then((res) => {
            return {
                id: currentSong.id,
                ...songData,
            };
        });
    }

}

export default SongStore;

