import {createReducer} from 'redux-act';

import {
  TRANSACTIONS_FETCH_DATA_INIT,
  TRANSACTION_FETCH_DATA_SUCCESS,
  TRANSACTIONS_FETCH_DATA_SUCCESS,
  TRANSACTIONS_FETCH_DATA_FAIL,
  TRANSACTIONS_MODIFY_TRANSACTION_INIT,
  TRANSACTIONS_MODIFY_TRANSACTION_SUCCESS,
  TRANSACTIONS_MODIFY_TRANSACTION_FAIL,
  TRANSACTIONS_CLEAN_UP,
  TRANSACTIONS_CLEAR_DATA_LOGOUT,
} from 'state/actions/transactions';

const initialState = {
    data: [],
    transaction: null,
    total: 0,
    page: 1,
    loading: false,
    error: null,
    success: false,
    deleted: false,
    sortConfig: {
      attribute: 'createdAt',
      order: 'desc'
    }
};

export const transactionsReducer = createReducer(
    {
        [TRANSACTIONS_FETCH_DATA_INIT]: () => ({
            ...initialState,
            loading: true,
        }),
        [TRANSACTION_FETCH_DATA_SUCCESS]: (state, payload) => ({
            ...state,
            transaction: payload.transaction,
            loading: false,
            error: null,
        }),
        [TRANSACTIONS_FETCH_DATA_SUCCESS]: (state, payload) => ({
            ...state,
            data: payload.data,
            total: payload.total,
            page: payload.page,
            sortConfig: payload.sortConfig,
            loading: false,
            error: null,
        }),
        [TRANSACTIONS_FETCH_DATA_FAIL]: (state, payload) => ({
            ...state,
            loading: false,
            error: payload.error,
        }),
        [TRANSACTIONS_MODIFY_TRANSACTION_INIT]: (state) => ({
            ...state,
            loading: true,
        }),
        [TRANSACTIONS_MODIFY_TRANSACTION_SUCCESS]: (state, payload) => ({
            ...state,
            data: !state.data
                ? []
                : state.data.map((elem) => {
                    if (elem.id === payload.id) {
                        return {
                            id: payload.id,
                            amount: payload.amount,
                            iban: payload.iban,
                            ownerId: payload.ownerId,
                            period: payload.period,
                            status: payload.status,
                            type: payload.type,
                            recipient: payload.recipient,
                            createdAt: payload.playlist.createdAt,
                        };
                    }
                    return elem;
                }),
            loading: false,
            error: null,
            success: true,
        }),
        [TRANSACTIONS_MODIFY_TRANSACTION_FAIL]: (state, payload) => ({
            ...state,
            loading: false,
            error: payload.error,
        }),
        [TRANSACTIONS_CLEAN_UP]: (state) => ({
            ...state,
            loading: false,
            error: null,
            success: false,
            deleted: false,
        }),
        [TRANSACTIONS_CLEAR_DATA_LOGOUT]: () => ({
            ...initialState,
        }),
    },
    initialState
);
