/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/media-has-caption */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import classNames from 'classnames';
import {yupResolver} from '@hookform/resolvers';
import {toast} from 'react-toastify';
import {arrayMoveImmutable} from 'array-move';

import paths from 'pages/Router/paths';
import {albumsCleanUp, fetchAlbum} from 'state/actions/albums';
import {useFormatMessage} from 'hooks';
import ErrorMessage from 'components/ErrorMessage';
import AsyncSelect from 'react-select/async';
import {getImgThumb, getImgUrl} from 'utils';
import DatePicker, { getDefaultLocale } from 'react-datepicker';
import SortableList from '../SortableList';
import Modal from '../Modal';
import Dropzone from '../Dropzone';
import SongStore from '../../service/SongStore';
import AlbumStore from '../../service/AlbumStore';

import {fetchCollection} from '../../state/api';
import {searchIndex} from '../../service/algolia';

import './AlbumForm.scss';
import SongForm from "../SongForm";
import AudioButton from "../AudioButton";

const AlbumForm = ({album, onSubmitHandler, schema}) => {
    const {loading, success} = useSelector(
        (state) => ({
            loading: state.albums.loading,
            success: state.albums.success,
        }),
        shallowEqual
    );

    const [albumSongs, setAlbumSongs] = useState([...album.songs]);
    const handleAlbumSubmit = (data) => {
        onSubmitHandler({
            ...data,
            songs: albumSongs.map(song => song.id),
            ownerId: data.owner.value
        });
    };

    const dispatch = useDispatch();

    const initialOwnerValue = album.ownerId ?? (album.owner?.id ?? null);
    const initialOwnerLabel = (album.owner?.name ?? album.owner?.email) ?? (album.ownerId ?? null);

    const {register, handleSubmit, errors, setValue, watch, control} = useForm({
        defaultValues: {
            ...album,
            artists: album.artists.map(artist => {
                return {value: artist.id, label: artist.name};
            }),
            genres: album.genres.map(genre => {
                return {value: genre.id, label: genre.name};
            }),
            owner: { value: initialOwnerValue, label: initialOwnerLabel}
        },
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        if (success) {
            setValue('file', null);
        }
        return () => dispatch(albumsCleanUp());
    }, [dispatch, success, setValue]);


    const imagePreviewUrl =
        watch('file') && watch('file')[0]
            ? URL.createObjectURL(watch('file')[0])
            : getImgThumb(album.img, true);


    const imageFullUrl = getImgUrl(album.img, true);


    const goBackMessage = useFormatMessage('AlbumForm.goBack');
    const pickAnotherFileMessage = useFormatMessage('AlbumForm.pickAnotherFile');
    const pickFileMessage = useFormatMessage('AlbumForm.pickFile');

    const loadArtists = (searchTerm) => {
        const options = {};
        if (searchTerm) {
            return searchIndex('artists', searchTerm, 0, 50).then((res) => {
                return res.hits.map((hit) => {
                    return {
                        value: hit.objectID,
                        label: hit.name,
                    };
                });
            });
        }
        return fetchCollection('artists', {pageSize: 50}).then((artists) => {
            return artists.map((artist) => {
                return {
                    value: artist.id,
                    label: artist.name,
                };
            });
        });
    };
    const loadGenres = (searchTerm) => {
        const options = {};
        if (searchTerm) {
            return searchIndex('genres', searchTerm, 0, 50).then((res) => {
                return res.hits.map((hit) => {
                    return {
                        value: hit.objectID,
                        label: hit.name,
                    };
                });
            });
        }
        return fetchCollection('genres', {pageSize: 50}).then((genres) => {
            return genres.map((genre) => {
                return {
                    value: genre.id,
                    label: genre.name,
                };
            });
        });
    };

    const loadSongs = (searchTerm) => {
        const options = {};
        if (searchTerm) {
            return searchIndex('songs', searchTerm, 0, 50).then((res) => {
                return res.hits.map((hit) => {
                    return {
                        value: hit.objectID,
                        label: hit.name,
                    };
                });
            });
        }
        return fetchCollection('songs', {pageSize: 50}).then((songs) => {
            return songs.map((song) => {
                return {
                    value: song.id,
                    label: song.name,
                };
            });
        });
    };

    const loadOwners = (searchTerm) => {
        return fetchCollection('owners', {sort: {attribute: 'name', order: 'asc'}}).then((owners) => {
            let options = owners.map((owner) => ({
                value: owner.id,
                label: `${owner.name ? `${owner.name} - ` : ''}${owner.email}`,
                name: owner.name
            }));

            if (searchTerm) {
                const search = searchTerm.trim().toLowerCase();
                options = options.filter((owner) => owner.label.toLowerCase().includes(search));
            }

            const sortedOwners = options.sort((a, b) => {
                if (a.name === null) {
                    return 1;
                }

                if (b.name === null) {
                    return -1;
                }

                if (a.name === b.name) {
                    return 0;
                }

                return a < b ? -1 : 1;
            });

            return sortedOwners;
        });
    };

    const detachSong = (songId) => {
        AlbumStore.modifyAlbum(album, {
            ...album,
            songs: album.songs.filter((s) => s.id !== songId)
        }).then(() => {
            toast.success('Song removed successfully');
            dispatch(fetchAlbum(album.id));
        }).catch((err) => {
            toast.error('Failed to remove song');
        });
    };

    const [openSong, setOpenSong] = useState(null);
    const renderSongItem = (song) => {
        return (
            <span>
                <strong>{song.name}</strong>
                <AudioButton url={`${song.url}?alt=media`}/>
                <a className="edit-btn" href="#" onClick={() => setOpenSong(song.id)}>Edit</a>
                <a className="remove-btn" href="#" onClick={() => detachSong(song.id)}>Remove</a>
            </span>
        );
    };
    const onSongSubmit = (song, newData) => {
        SongStore.modifySong(song, {
            ...newData,
            artists: newData.artists.map(selected => selected.value),
            songFile: newData?.songFile[0] ||  null,
            id: song.id,
            ownerId: album.ownerId,
            approved: album.approved
        }).then(() => {
            toast.success('Song updated successfully');
            dispatch(fetchAlbum(album.id));
        }).catch((err) => {
            toast.error('Failed to update song');
        });
    };


    const handleSongsSort = ({oldIndex, newIndex}) => {
        setAlbumSongs(arrayMoveImmutable(albumSongs, oldIndex, newIndex));
    };

    const [uploadingCount, setUploadingCount] = useState(0);
    const [uploadedCount, setUploadedCount] = useState(0);
    const handleSongsDrop = (files) => {
        console.log(files);
        if (!files.length) {
            toast.error('Files not in correct format');
            return;
        }

        setUploadingCount(files.length);
        const queue = [];

        files.forEach((file) => {
            const task = SongStore.createSong({
                name: file.name,
                artists: album.artists,
                songFile: file,
                defaultImg: album.img,
                defaultRecordingYear: album.recordingYear,
                ownerId: album.ownerId,
                approved: album.approved,
                availableFrom: album.availableFrom
            }).then((res) => {
                setUploadedCount(uploadedCount + 1);
                return res;
            });
            queue.push(task);
        });

        Promise.all(queue).then((songs) => {
            return AlbumStore.modifyAlbum(album, {
                ...album,
                songs: [...albumSongs, ...songs]
            });
        }).then(() => {
            setUploadingCount(0);
            setUploadedCount(0);
            toast.success('Songs uploaded successfully');
            dispatch(fetchAlbum(album.id));
        }).catch((err) => {
            setUploadingCount(0);
            setUploadedCount(0);
            toast.error('Uploading songs has failed');
        });
    };

    return (
        <>
            <div className="tile is-ancestor">
                <div className="tile is-parent">
                    <div className="card tile is-child">
                        <header className="card-header">
                            <p className="card-header-title">
                                <span className="icon">
                                    <i className="mdi mdi-account-edit default"/>
                                </span>
                                {useFormatMessage(`AlbumForm.${albumSongs.length > 1 ? 'albumInfo' : 'singleInfo'}`)}
                            </p>
                        </header>
                        <div className="card-content">
                            <form onSubmit={handleSubmit(handleAlbumSubmit)}>
                                <div className="columns">
                                    <div className="column">
                                        <div className="field is-horizontal">
                                            <div className="field-label is-normal">
                                                <label className="label">
                                                    {useFormatMessage('AlbumForm.name')}
                                                </label>
                                            </div>
                                            <div className="field-body">
                                                <div className="field">
                                                    <div className="control">
                                                        <input
                                                            name="name"
                                                            id="name"
                                                            className={classNames('input', {
                                                                'is-danger': errors.name,
                                                            })}
                                                            ref={register}
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {errors.name && (
                                            <div className="field is-horizontal">
                                                <div className="field-label is-normal"/>
                                                <div className="field-body">
                                                    <ErrorMessage/>
                                                </div>
                                            </div>
                                        )}

                                        <div className="field is-horizontal">
                                            <div className="field-label is-normal">
                                                <label className="label">
                                                    {useFormatMessage('AlbumForm.artists')}
                                                </label>
                                            </div>
                                            <div className="field-body">
                                                <div className="field">
                                                    <div className="control">
                                                        <Controller
                                                            as={AsyncSelect}
                                                            name="artists"
                                                            control={control}
                                                            loadOptions={loadArtists}
                                                            defaultOptions
                                                            isMulti
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {errors.artists && (
                                            <div className="field is-horizontal">
                                                <div className="field-label is-normal"/>
                                                <div className="field-body">
                                                    <ErrorMessage/>
                                                </div>
                                            </div>
                                        )}

                                        <div className="field is-horizontal">
                                            <div className="field-label is-normal">
                                                <label className="label">
                                                {useFormatMessage('AlbumForm.recordingYear')}
                                                </label>
                                            </div>
                                            <div className="field-body">
                                                <div className="field">
                                                    <div className="control">
                                                        <Controller
                                                            name="recordingYear"
                                                            control={control}
                                                            defaultOptions
                                                            defaultValue={null}
                                                            render={({ onChange, value }) => (
                                                                <DatePicker
                                                                    selected={!value ? null : new Date(`${value}`)}
                                                                    showYearPicker
                                                                    dateFormat="yyyy"
                                                                    onChange={date => onChange(!date ? null : date.getFullYear())}
                                                                    maxDate={new Date()}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                       <div className="field is-horizontal">
                                            <div className="field-label is-normal">
                                                <label className="label">
                                                    {useFormatMessage('AlbumForm.availableFrom')}
                                                </label>
                                            </div>
                                            <div className="field-body">
                                                <div className="field">
                                                    <div className="control">
                                                        <Controller
                                                            control={control}
                                                            name="availableFrom"
                                                            render={({ onChange, name, value }) => (
                                                                <DatePicker
                                                                    name={name}
                                                                    onChange={onChange}
                                                                    showTimeSelect
                                                                    timeFormat="HH:mm"
                                                                    timeIntervals={60}
                                                                    selected={value}
                                                                    dateFormat="MMMM d, yyyy HH:mm"
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="field is-horizontal">
                                            <div className="field-label is-normal">
                                                <label className="label">
                                                    {useFormatMessage('Genres')}
                                                </label>
                                            </div>
                                            <div className="field-body">
                                                <div className="field">
                                                    <div className="control">
                                                        <Controller
                                                            as={AsyncSelect}
                                                            name="genres"
                                                            control={control}
                                                            loadOptions={loadGenres}
                                                            defaultOptions
                                                            isMulti
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {errors.genres && (
                                            <div className="field is-horizontal">
                                                <div className="field-label is-normal"/>
                                                <div className="field-body">
                                                    <ErrorMessage/>
                                                </div>
                                            </div>
                                        )}

                                        <div className="field is-horizontal">
                                            <div className="field-label is-normal">
                                                <label className="label">
                                                    {useFormatMessage('AlbumForm.owner')}
                                                </label>
                                            </div>
                                            <div className="field-body">
                                                <div className="field">
                                                    <div className="control">
                                                        <Controller
                                                            as={AsyncSelect}
                                                            name="owner"
                                                            control={control}
                                                            loadOptions={loadOwners}
                                                            defaultOptions
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {errors.owner && (
                                            <div className="field is-horizontal">
                                                <div className="field-label is-normal"/>
                                                <div className="field-body">
                                                    <ErrorMessage/>
                                                </div>
                                            </div>
                                        )}

                                        <div className="field has-check is-horizontal">
                                            <div className="field-label">
                                                <label className="label">{useFormatMessage('AlbumForm.single')}</label>
                                            </div>
                                            <div className="field-body">
                                                <div className="field">
                                                <div className="field">
                                                    <div className="control">
                                                    <label className="b-checkbox checkbox">
                                                        <input
                                                        type="checkbox"
                                                        name="isSingle"
                                                        ref={register}
                                                        />
                                                        <span className="check is-primary" />
                                                    </label>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        <div className="field is-horizontal">
                                            <div className="field-label is-normal">
                                                <label className="label">
                                                    {useFormatMessage('AlbumForm.logo')}
                                                </label>
                                            </div>
                                            <div className="field-body">
                                                <div className="field">
                                                    <div className="file has-name">
                                                        <label className="file-label">
                                                            <input
                                                                className="file-input"
                                                                type="file"
                                                                name="file"
                                                                ref={register}
                                                                accept="image/*"
                                                            />
                                                            <span className="file-cta">
                            <span className="file-icon">
                              <i className="mdi mdi-upload"/>
                            </span>
                            <span className="file-label">
                              {watch('file') && watch('file').file
                                  ? pickAnotherFileMessage
                                  : pickFileMessage}
                            </span>
                          </span>
                                                            <span className="file-name">
                            {watch('file') && watch('file')[0]?.name}
                          </span>
                                                        </label>

                                                    </div>
                                                    <em>Cover art must be square .jpg or .png file and be at least 1000x1000 px.</em>
                                                    {imagePreviewUrl && (
                                                        <div className="has-max-width">
                                                            <img
                                                                className="album-img"
                                                                src={imagePreviewUrl}
                                                                alt="User profile logo preview"
                                                            />
                                                            <a href={imageFullUrl} download>Full image</a>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {errors.file && (
                                            <div className="field is-horizontal">
                                                <div className="field-label is-normal"/>
                                                <div className="field-body">
                                                    <ErrorMessage/>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {album.id && (
                                        <div className="column">
                                            <SortableList items={albumSongs} onSortEnd={handleSongsSort}
                                                          renderItem={renderSongItem}/>
                                            <Dropzone onDrop={handleSongsDrop} acceptedFileTypes={["audio/*"]}/>
                                            {uploadingCount ? (
                                                <div>
                                                    <div>Uploading {uploadedCount}/{uploadingCount} songs</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </div>

                                <hr/>
                                <div className="field is-horizontal">
                                    <div className="field-body">
                                        <div className="field">
                                            <div className="field is-grouped">
                                                <div className="control">
                                                    <button
                                                        type="submit"
                                                        className={`button is-primary ${
                                                        loading && 'is-loading'
                                                            }`}
                                                    >
                                                        <span>{useFormatMessage('AlbumForm.submit')}</span>
                                                    </button>
                                                </div>
                                                <Link to={paths.ALBUMS} className="button">
                                                    {goBackMessage}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {albumSongs.map((song) => (
                <Modal key={song.id} title={song.name} isOpen={openSong === song.id} onClose={() => setOpenSong(null)}>
                    <SongForm song={song} onSubmitHandler={(newData) => onSongSubmit(song, newData)} goBackHandler={() => setOpenSong(null)}/>
                </Modal>
            ))}
        </>
    );
};

AlbumForm.propTypes = {
    album: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string.isRequired,
        artists: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })),
        genres: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })),
        songs: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })),
        img: PropTypes.string,
        recordingYear: PropTypes.number,
        availableFrom: PropTypes.instanceOf(Date),
        owner: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    }).isRequired,
    onSubmitHandler: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    schema: PropTypes.object.isRequired,
};

AlbumForm.defaultProps = {};

export default AlbumForm;
