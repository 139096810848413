/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';

import './SortableList.scss';

const DragHandle = SortableHandle(() => <div className="handle"> </div>);

const FairmusSortableItem = SortableElement(({value}) => (
    <li className="item">
        <DragHandle/>
        {value}
    </li>
));

const FairmusSortableContainer = SortableContainer(({children}) => {
    return <ul className="list">{children}</ul>;
});

const SortableList = ({items, renderItem, onSortEnd, resolveKey}) => {
    const keyResolver = resolveKey || ((val, index) => {
        return `item-${index}`;
    });
    return (
        <FairmusSortableContainer onSortEnd={onSortEnd} useDragHandle>
            {items.map((val, index) => {
                const children = renderItem(val);
                const key = keyResolver(val, index);
                return (
                    <FairmusSortableItem key={key} index={index} value={children}/>
                );
            })}
        </FairmusSortableContainer>
    );
};

SortableList.propTypes = {
};

SortableList.defaultProps = {};

export default SortableList;
