import {createAction} from 'redux-act';
import {toast} from 'react-toastify';

import {firebaseError, getImgThumb} from 'utils';
import firebase from 'firebase.js';
import {checkUserData} from './auth';
import {
  fetchCollection,
  fetchDocument,
  fetchDocuments,
  createDocument,
  deleteDocument,
  updateDocument,
} from '../api';
import {searchIndex} from "../../service/algolia";
import {paginatePlaylists} from "./playlists";

export const FEATURED_FETCH_DATA_INIT = createAction('FEATURED_FETCH_DATA_INIT');
export const FEATURED_FETCH_DATA_SUCCESS = createAction('FEATURED_FETCH_DATA_SUCCESS');
export const FEATURED_FETCH_DATA_FAIL = createAction('FEATURED_FETCH_DATA_FAIL');

export const FEATURED_DELETE_FEATURED_INIT = createAction('FEATURED_DELETE_FEATURED_INIT');
export const FEATURED_DELETE_FEATURED_SUCCESS = createAction('FEATURED_DELETE_FEATURED_SUCCESS');
export const FEATURED_DELETE_FEATURED_FAIL = createAction('FEATURED_DELETE_FEATURED_FAIL');

export const FEATURED_CREATE_FEATURED_INIT = createAction('FEATURED_CREATE_FEATURED_INIT');
export const FEATURED_CREATE_FEATURED_SUCCESS = createAction('FEATURED_CREATE_FEATURED_SUCCESS');
export const FEATURED_CREATE_FEATURED_FAIL = createAction('FEATURED_CREATE_FEATURED_FAIL');

export const FEATURED_CLEAN_UP = createAction('FEATURED_CLEAN_UP');


const getCollection = (type) => {
  let collection;
  if (type === 'Playlist') {
    collection = 'playlists';
  } else if (type === 'Album') {
    collection = 'albums';
  } else if (type === 'Artist') {
    collection = 'artists';
  }
  return collection;
};

export const deleteFeatured = ({id, featuredId, type}) => {
  return async (dispatch, getState) => {
    dispatch(FEATURED_DELETE_FEATURED_INIT());
    const {locale} = getState().preferences;

    const playlistData = {
      featuredId: null,
    };

    const updatePlaylistDbTask = updateDocument(getCollection(type), id, playlistData);
    const deleteGenreTask = deleteDocument('featured', featuredId);

    try {
      await Promise.all([updatePlaylistDbTask, deleteGenreTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toast.error(errorMessage);
      return dispatch(
        FEATURED_DELETE_FEATURED_FAIL({
          error: errorMessage,
        })
      );
    }

    toast.success('The featured was deleted.');
    return dispatch(FEATURED_DELETE_FEATURED_SUCCESS({id}));
  };
};

export const createFeatured = ({id, type, img, name, songs, artists}) => {
  return async (dispatch, getState) => {
    dispatch(FEATURED_CREATE_FEATURED_INIT());
    const {locale} = getState().preferences;

    let artistList;
    if (artists) {
      artistList = (await fetchDocuments('artists', artists)).map(artist => {
        return {id: artist.id, name: artist.name};
      });
    }

    const featuredData = {
      id,
      item: {
        img: img || null,
        name,
        songs: songs ? songs.map((song) => {
          return (song.id) ? song.id : song;
        }) : null,
        artists: artists ? artistList : null
      },

      type,
      createdAt: new Date()
    };

    let response;
    try {
      response = await createDocument('featured', null, featuredData);
      featuredData.id = response.id;

      const data = {
        featuredId: featuredData.id,
      };

      response = await updateDocument(getCollection(type), id, data);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toast.error(errorMessage);
      return dispatch(
        FEATURED_CREATE_FEATURED_FAIL({
          error: errorMessage,
        })
      );
    }

    toast.success('Featured created successfully');
    return dispatch(FEATURED_CREATE_FEATURED_SUCCESS({featured: featuredData}));
  };
};

export const playlistsCleanUp = () => (dispatch) => dispatch(FEATURED_CLEAN_UP());
