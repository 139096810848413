import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import { useFormatDate, useFormatMessage, usePrevious } from 'hooks';
import { Redirect, Link } from 'react-router-dom';
import classNames from "classnames";
import ClipLoader from "react-spinners/ClipLoader";
import RemoteTable from 'components/RemoteTable';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import { paginateOwners, deleteOwner, ownersCleanUp } from 'state/actions/owners';

import classes from "../Users/Users.module.scss";
import {getImgThumb} from "../../utils";
import { paginateAlbums } from '../../state/actions/albums';
import {createFeatured, deleteFeatured} from "../../state/actions/featured";

const Owners = () => {
    const {artistsList, artistsTotal, page, isAdmin, error, loading, deleted} = useSelector(
        (state) => {
            console.log('state', state);
            return ({
                artistsList: state.owners.data,
                artistsTotal: state.owners.total,
                page: state.owners.page,
                isAdmin: state.auth.userData.isAdmin,
                error: state.owners.error,
                loading: state.owners.loading,
                deleted: state.owners.deleted,
            });
        },
        shallowEqual
    );

    const [deleteModal, setDeleteModal] = useState({
        artistId: null,
        isOpen: false,
    });
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [pageSize, setPageSize] = useState(50);
    const prev = usePrevious({page, pageSize, search});

    const handlePageChange = (newPage, movement) => {
        dispatch(paginateOwners(pageSize, search, movement));
    };

    const handleSort = (attribute) => {
        if(attribute) {
            dispatch(paginateOwners(pageSize, search, 'init', attribute));
        }
    };

    const pullInitial = () => dispatch(paginateOwners(pageSize, search, 'init'));

    useEffect(() => {
        if (isAdmin) {
            pullInitial();
        }

        return () => dispatch(ownersCleanUp());
    }, [dispatch, isAdmin]);

    useEffect(() => {
        if(searchTimeout) {
            clearTimeout(searchTimeout);
        }

        if(prev === undefined) {
            return; // Only run once we have actually searched
        }

        const timeout = setTimeout(() => {
            pullInitial();
        }, 500);
        setSearchTimeout(timeout);
    }, [search]);

    useEffect(() => {
        if(prev && pageSize !== prev.pageSize) {
            pullInitial();
        }
    }, [pageSize]);

    useEffect(() => {
        if (deleted && !loading) {
            setDeleteModal((prevState) => ({
                artistId: null,
                isOpen: !prevState.isOpen,
            }));
        }
    }, [deleted, loading]);

    const redirect = !isAdmin && <Redirect to={paths.ROOT}/>;

    const onRemoveButtonClickHandler = (artistId) => {
        setDeleteModal((prevState) => ({
            artistId,
            isOpen: !prevState.isOpen,
        }));
    };

    const onCloseModalHandler = () => {
        setDeleteModal({artistId: null, isOpen: false});
    };

    const onDeleteArtistHandler = () => {
        dispatch(deleteOwner(deleteModal.artistId));
    };

/*     const onHandleFeatured = (artist) => {
        const data = {
            ...artist,
            type: 'Artist'
        };
        const createFeature = !artist.featuredId;
        if(createFeature) {
            dispatch(createFeatured(data));
        } else {
            dispatch(deleteFeatured(data));
        }
        pullInitial();
    };
 */
    const deleteMessage = useFormatMessage('Artists.delete');
    const confirmMessage = useFormatMessage('Artists.confirm');
    const permDeleteMessage = useFormatMessage('Owners.permDelete');
    const cancelMessage = useFormatMessage('Artists.cancel');

    const cols = [
        {
            name: '',
        },
        {
            name: 'Name',
            field: 'name',
        },
        {
            name: 'Created',
            field: 'createdAt',
        },
        {
            name: '',
        },
    ];
    const tableData = artistsList.map((artist) => {
        const createdAt = (artist.createdAt && artist.createdAt.seconds) ? new Date(artist.createdAt.seconds * 1000) : artist.createdAt;
        let { name } = artist;
        if (!artist.name) {
            if (!artist?.firstName) name = artist.email;
            else {
                name = artist.firstName;
                if (artist?.lastName) name += ` ${artist.lastName}`;
            }
        }
        return [
            {
                className: 'table-img-cell',
                value: (<>
                    <img src={artist.image} alt="" className="table-img artist-img" />
                </>),
            },
            {
                value: name,
            },
            {
                value: (
                  <small className="has-text-grey is-abbr-like">
                      {useFormatDate(createdAt, {
                          weekday: 'short',
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                      })}
                  </small>
                ),
            },
            {
                className: 'is-actions-cell',
                value: (
                    <>
                        <div className="buttons is-right">
                            {/* <button
                                type="button"
                                className="button is-small"
                                onClick={() => onHandleFeatured(artist)}
                            >
                                <span className="icon is-small">
                                  <i className={artist.featuredId ? "mdi mdi-star" : "mdi mdi-star-outline"}/>
                                </span>
                            </button> */}
                            <Link to={`/owners/${artist.id}`} className="button is-small is-primary">
                                <span className="icon is-small">
                                  <i className="mdi mdi-pencil-outline"/>
                                </span>
                            </Link>
                            <button
                                type="button"
                                className="button is-small is-danger"
                                onClick={() => onRemoveButtonClickHandler(artist.id)}
                            >
                                <span className="icon is-small">
                                  <i className="mdi mdi-trash-can"/>
                                </span>
                            </button>
                        </div>

                    </>
                )
            }
        ];
    });

    return (
        <>
            {redirect}
            {deleteModal.isOpen && (
                <ConfirmationModal
                    isActive={deleteModal.isOpen}
                    isLoading={loading}
                    confirmButtonMessage={deleteMessage}
                    title={confirmMessage}
                    body={permDeleteMessage}
                    cancelButtonMessage={cancelMessage}
                    onConfirmation={onDeleteArtistHandler}
                    onCancel={onCloseModalHandler}
                />
            )}
            <section className="hero is-hero-bar">
                <div className="hero-body">
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <h1 className="title">{useFormatMessage('Owners.owners')} ({artistsTotal})</h1>
                            </div>
                        </div>
                        <div className="level-right">
                            <div className="level-item">
                                <Link to={paths.ADD_OWNER} className="button">
                                    {useFormatMessage('Owners.newOwner')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section is-main-section">
                <div className="card has-table has-mobile-sort-spaced">
                    {/* <header className="card-header">
                        <p className={classNames('card-header-title', classes.tableHeader)}>
                            <span>{useFormatMessage('Artists.search')}</span>
                            <input
                                type="text"
                                className="input"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </p>
                    </header> */}
                    <div className="b-table">
                        {loading ? <ClipLoader /> : <RemoteTable
                            columns={cols}
                            data={tableData}
                            onPageSizeChange={(size) => setPageSize(size)}
                            onPageChange={(pageNo, movementType) => handlePageChange(pageNo, movementType)}
                            onSort={(attribute) => handleSort(attribute)}
                            pages={Math.ceil(artistsTotal / pageSize)}
                            page={page}
                            pageSize={pageSize}
                        />}
                        {error && 'Show error'}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Owners;
