import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useFormatMessage, usePrevious} from 'hooks';
import { Redirect, Link } from 'react-router-dom';
import classNames from "classnames";
import ClipLoader from "react-spinners/ClipLoader";
import RemoteTable from 'components/RemoteTable';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import { paginateGenres, deleteGenre, genresCleanUp } from 'state/actions/genres';
import firebase from 'firebase.js';

import {DataGrid} from "@mui/x-data-grid";
import classes from "../Users/Users.module.scss";
import {fetchCollection, fetchDocuments} from "../../state/api";
import {searchIndex} from "../../service/algolia";
import useSearch from '../../hooks/useSearch';

const Genres = () => {
    const {genresList, genresTotal, isAdmin, error, loading, deleted} = useSelector(
        (state) => {
            return ({
                genresList: state.genres.data,
                genresTotal: state.genres.total,
                page: state.genres.page,
                isAdmin: state.auth.userData.isAdmin,
                error: state.genres.error,
                loading: state.genres.loading,
                deleted: state.genres.deleted,
            });
        },
        shallowEqual
    );

    const [deleteModal, setDeleteModal] = useState({
        genreId: null,
        isOpen: false,
    });
    const dispatch = useDispatch();
    const {query, setQuery, page, setPage, pageSize, setPageSize } = useSearch();
    const [rows, setRows] = useState([]);
    const [songCounts, setSongCounts] = useState(null);
    const [searchTimeout, setSearchTimeout] = useState(null);
    const prev = usePrevious({page, pageSize, query});

    const getData = async () => {
        if(query) {
            const res = await searchIndex('genres', query, 0, pageSize);
            const ids = res.hits.map((hit) => hit.objectID);

            const data = await fetchDocuments('genres', ids);
            setRows(data);
        } else {
            const data = await fetchCollection('genres');
            setRows(data);
        }
    };

    useEffect(() => {
        if (isAdmin) {
            getData();
        }

        return () => dispatch(genresCleanUp());
    }, [dispatch, isAdmin]);

    useEffect(() => {
        if (rows.length > 0 && !songCounts) {
            (async () => {
                try {
                    const albums = await fetchCollection('albums');
                    const obj = {};
                    albums.forEach((album) => {
                        const { genres, songs } = album;
                        genres.forEach((genre) => {
                            if (obj[genre] === undefined) obj[genre] = songs.length ?? 0;
                            else if (songs.length > 0) obj[genre] += songs.length;
                        });
                    });
                    setSongCounts(obj);
                    setRows(p => p.map((row) => ({...row, songs: obj[row.id] ?? 0})));
                } catch (err) {
                    console.log('err', err);
                }
            })();
        }
    }, [rows, songCounts]);

    useEffect(() => {
        if(searchTimeout) {
            clearTimeout(searchTimeout);
        }

        if(prev === undefined) {
            return; // Only run once we have actually searched
        }

        const timeout = setTimeout(() => {
            getData();
        }, 500);
        setSearchTimeout(timeout);
    }, [query]);

    useEffect(() => {
        if(prev && pageSize !== prev.pageSize) {
            // pullInitial();
        }
    }, [pageSize]);

    useEffect(() => {
        if (deleted && !loading) {
            setDeleteModal((prevState) => ({
                genreId: null,
                isOpen: !prevState.isOpen,
            }));
        }
    }, [deleted, loading]);

    const redirect = !isAdmin && <Redirect to={paths.ROOT}/>;

    const onRemoveButtonClickHandler = (genreId) => {
        setDeleteModal((prevState) => ({
            genreId,
            isOpen: !prevState.isOpen,
        }));
    };

    const onCloseModalHandler = () => {
        setDeleteModal({genreId: null, isOpen: false});
    };

    const onDeleteGenreHandler = () => {
        dispatch(deleteGenre(deleteModal.genreId));
    };

    const deleteMessage = useFormatMessage('Genres.delete');
    const confirmMessage = useFormatMessage('Genres.confirm');
    const permDeleteMessage = useFormatMessage('Genres.permDelete');
    const cancelMessage = useFormatMessage('Genres.cancel');

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'songs',
            headerName: 'Songs count',
            flex: 1,
        },
        {
            field: 'createdAt',
            headerName: 'Created',
            flex: 1,
            renderCell: ({row}) => {
                return (row.createdAt && row.createdAt.seconds) ? new Date(row.createdAt.seconds * 1000).toLocaleDateString() : "-";
            }
        },
        {
            field: 'edit',
            headerName: '',
            flex: 1,
            renderCell: (genre) => {
                return (
                    <>
                        <div className="buttons is-right is-actions-cell">
                            <Link to={`/genres/${genre.id}`} className="button is-small is-primary">
                                <span className="icon is-small">
                                  <i className="mdi mdi-pencil-outline"/>
                                </span>
                            </Link>
                            <button
                                type="button"
                                className="button is-small is-danger"
                                onClick={() => onRemoveButtonClickHandler(genre.id)}
                            >
                                <span className="icon is-small">
                                  <i className="mdi mdi-trash-can"/>
                                </span>
                            </button>
                        </div>

                    </>
                );
            },
            width: 70,
            sortable: false,
        }
    ];
    console.log("genres data: ", genresList, genresTotal, page, isAdmin, error, loading, deleted);

    return (
        <>
            {redirect}
            {deleteModal.isOpen && (
                <ConfirmationModal
                    isActive={deleteModal.isOpen}
                    isLoading={loading}
                    confirmButtonMessage={deleteMessage}
                    title={confirmMessage}
                    body={permDeleteMessage}
                    cancelButtonMessage={cancelMessage}
                    onConfirmation={onDeleteGenreHandler}
                    onCancel={onCloseModalHandler}
                />
            )}
            <section className="hero is-hero-bar">
                <div className="hero-body">
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <h1 className="title">{useFormatMessage('Genres.genres')} ({rows.length})</h1>
                            </div>
                        </div>
                        <div className="level-right">
                            <div className="level-item">
                                <Link to={paths.ADD_GENRE} className="button">
                                    {useFormatMessage('Genres.newGenre')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section is-main-section">
                <div className="card has-table has-mobile-sort-spaced">
                    <header className="card-header">
                        <p className={classNames('card-header-title', classes.tableHeader)}>
                            <span>{useFormatMessage('Genres.search')}</span>
                            <input
                                type="text"
                                className="input"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                            />
                        </p>
                    </header>
                    <div className="b-table">
                        {loading ? <ClipLoader /> :<>
                            <DataGrid
                                columns={columns}
                                rows={rows}
                                pageSize={pageSize}
                                onPageSizeChange={(size) => setPageSize(size)}
                                disableColumnMenu
                                disableColumnSelector
                                isRowSelectable={() => false}
                                isCellEditable={() => false}
                                disableSelectionOnClick
                                autoHeight
                                page={page}
                                onPageChange={(index) => setPage(index)}
                            />
                        </> }
                        {error && 'Show error'}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Genres;
