import classNames from "classnames";
import RemoteTable from 'components/RemoteTable';
import { useFormatMessage, usePrevious } from 'hooks';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { paginateTransactions, transactionsCleanUp } from 'state/actions/transactions';
import classes from "../Users/Users.module.scss";


const Transactions = () => {
    const {transactionsList, transactionsTotal, page, isAdmin, error, loading} = useSelector(
        (state) => {
            return ({
                transactionsList: state.transactions.data,
                transactionsTotal: state.transactions.total,
                page: state.transactions.page,
                isAdmin: state.auth.userData.isAdmin,
                error: state.transactions.error,
                loading: state.transactions.loading,
            });
        },
        shallowEqual
    );

    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [pageSize, setPageSize] = useState(50);
    const prev = usePrevious({page, pageSize, search});

    const handlePageChange = (newPage, movement) => {
        dispatch(paginateTransactions(pageSize, search, movement));
    };

    const handleSort = (attribute, order) => {
        if(attribute && order) {
            dispatch(paginateTransactions(pageSize, search, 'init', attribute, order));
        }
    };

    const pullInitial = () => dispatch(paginateTransactions(pageSize, search, 'init'));

    useEffect(() => {
        if (isAdmin) {
            pullInitial();
        }

        return () => dispatch(transactionsCleanUp());
    }, [dispatch, isAdmin]);

    useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        if(prev === undefined) {
            return; // Only run once we have actually searched
        }

        const timeout = setTimeout(() => {
            pullInitial();
        }, 500);
        setSearchTimeout(timeout);
    }, [search]);

    useEffect(() => {
        if (prev && pageSize !== prev.pageSize) {
            pullInitial();
        }
    }, [pageSize]);


    const cols = [
        {
            name: 'Type',
            field: 'type',
        },
        {
            name: 'Recipient',
            field: 'recipient',
        },
        {
            name: 'IBAN',
            field: 'iban',
        },
        {
            name: 'Period',
            field: 'periodFrom',
        },
        {
            name: 'Amount',
            field: 'amount'
        },
        {
            name: 'Status',
            field: 'status'
        },
        {
            name: '',
        },
    ];

    const tableData = transactionsList.map((transaction) => {
        return [
            {
                value: transaction.type
            },
            {
                value: transaction.recipient ?? '-'
            },
            {
                value: transaction.iban ?? '-'
            },
            {
                value: transaction.periodFrom && transaction.periodTo ? `${transaction.periodFrom.slice(-5).replace('-', '.')} - ${transaction.periodTo.slice(-5).replace('-', '.')}` : "-",
            },
            {
                value: `${transaction.amount.toFixed(2)}€`
            },
            {
                value: transaction.status
            },
            {
                className: 'is-actions-cell',
                value: (
                    <>
                        <div className="buttons is-right">
                            <Link to={`/transactions/${transaction.id}`} className="button is-small is-primary">
                                <span className="icon is-small">
                                  <i className="mdi mdi-pencil-outline"/>
                                </span>
                            </Link>
                        </div>
                    </>
                ),
            }
        ];
    });

    const redirect = !isAdmin && <Redirect to={paths.ROOT}/>;
    return (
        <>
            {redirect}
            <section className="hero is-hero-bar">
                <div className="hero-body">
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <h1 className="title">{useFormatMessage('Transactions.transactions')}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section is-main-section">
                <div className="card has-table has-mobile-sort-spaced">
                    <header className="card-header">
                        <p className={classNames('card-header-title', classes.tableHeader)}>
                            <span>{useFormatMessage('Playlists.search')}</span>
                            <input
                                type="text"
                                className="input"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </p>
                    </header>
                    <div className="b-table">
                        {loading ? <ClipLoader /> : <RemoteTable
                            columns={cols}
                            data={tableData}
                            onPageSizeChange={(size) => setPageSize(size)}
                            onPageChange={(pageNo, movementType) => handlePageChange(pageNo, movementType)}
                            onSort={(attribute) => handleSort(attribute, 'desc')}
                            pages={Math.ceil(transactionsTotal / pageSize)}
                            page={page}
                            pageSize={pageSize}
                        />}
                        {error && 'Show error'}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Transactions;
