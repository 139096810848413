import React, { useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import PlaylistForm from 'components/PlaylistForm';
import { createPlaylist, modifyPlaylist, fetchPlaylist } from 'state/actions/playlists';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';

const Playlist = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success, playlistData, error } = useSelector(
    (state) => ({
      success: state.playlists.success,
      playlistData: state.playlists.playlist,
      error: state.playlists.error,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      if (!playlistData) {
        dispatch(fetchPlaylist(id));
      }
    }
  }, [isEditing, id, playlistData, dispatch]);

  const yupShape = {
    name: yup.string().required(),
    songs: yup.array().of(yup.object()).min(1)
  };

  if(!playlistData || !playlistData.img) {
    yupShape.file = yup.mixed().test('file-test', 'Please pick a file', (value, context) => {
      return (value && value.length === 1);
    });
  }

  const schema = yup.object().shape(yupShape);

  const redirect = ((isEditing && error) || success) && (
    <Redirect to={paths.PLAYLISTS} />
  );

  const editPlaylistMessage = useFormatMessage('Playlist.editPlaylist');

  const newPlaylistMessage = useFormatMessage('Playlist.editPlaylist');

  const onSubmitHandler = (value) => {
    console.log(value);
    const newPlaylist = {
      ...value,
      songs: value.songs.map(selected => selected.value),
      file: value?.file[0] || null,
      isEditing,
      id,
    };

    if (isEditing) {
      dispatch(modifyPlaylist(newPlaylist));
    } else {
      dispatch(createPlaylist(newPlaylist));
    }
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editPlaylistMessage : newPlaylistMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !playlistData ? (
          <ClipLoader />
        ) : (
          <PlaylistForm
            isEditing={isEditing}
            playlist={
              isEditing
                ? playlistData
                : {
                    name: '',
                    songs: [],
                    createdAt: new Date().toDateString(),
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default Playlist;
