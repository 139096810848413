import Actions from 'pages/Actions';
import Album from 'pages/Album';
import Albums from 'pages/Albums';
import Approvals from 'pages/Approvals';
import Artist from 'pages/Artist';
import Owner from 'pages/Owner';
import Artists from 'pages/Artists';
import Owners from 'pages/Owners';
import Export from 'pages/Export';
import Genre from 'pages/Genre';
import Genres from 'pages/Genres';
import Home from 'pages/Home';
import Login from 'pages/Login';
import NotFound from 'pages/NotFound';
import Playlist from 'pages/Playlist';
import Playlists from 'pages/Playlists';
import Profile from 'pages/Profile';
import ResetPassword from 'pages/ResetPassword';
import Section from 'pages/Section';
import Song from 'pages/Song';
import Songs from 'pages/Songs';
import Submenu from 'pages/Submenu';
import Transaction from 'pages/Transaction';
import Transactions from 'pages/Transactions';
import User from 'pages/User';
import Users from 'pages/Users';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Withdrawals from "pages/Withdrawals";
import paths from './paths';
import PrivateRoute from './PrivateRoute';


const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={paths.LOGIN} component={Login} />
        <Route exact path={paths.RESET_PASSWORD} component={ResetPassword} />
        <PrivateRoute path={paths.ADD_USER} component={User} />
        <PrivateRoute path={paths.MODIFY_USER} component={User} />
        <PrivateRoute path={paths.USERS} component={Users} />
        <PrivateRoute path={paths.PROFILE} component={Profile} />
        <PrivateRoute path={paths.SECTION} component={Section} />
        <PrivateRoute path={paths.SUBMENU_1} component={Submenu} />
        <PrivateRoute path={paths.SUBMENU_2} component={Submenu} />
        <PrivateRoute path={paths.ADD_ARTIST} component={Artist} />
        <PrivateRoute path={paths.ADD_OWNER} component={Owner} />
        <PrivateRoute path={paths.MODIFY_ARTIST} component={Artist} />
        <PrivateRoute path={paths.MODIFY_OWNER} component={Owner} />
        <PrivateRoute path={paths.ARTISTS} component={Artists} />
        <PrivateRoute path={paths.OWNERS} component={Owners} />
        <PrivateRoute path={paths.ADD_GENRE} component={Genre} />
        <PrivateRoute path={paths.MODIFY_GENRE} component={Genre} />
        <PrivateRoute path={paths.GENRES} component={Genres} />
        <PrivateRoute path={paths.ADD_SONG} component={Song} />
        <PrivateRoute path={paths.MODIFY_SONG} component={Song} />
        <PrivateRoute path={paths.SONGS} component={Songs} />
        <PrivateRoute path={paths.ADD_ALBUM} component={Album} />
        <PrivateRoute path={paths.MODIFY_ALBUM} component={Album} />
        <PrivateRoute path={paths.ALBUMS} component={Albums} />
        <PrivateRoute path={paths.ADD_PLAYLIST} component={Playlist} />
        <PrivateRoute path={paths.MODIFY_PLAYLIST} component={Playlist} />
        <PrivateRoute path={paths.PLAYLISTS} component={Playlists} />
        <PrivateRoute path={paths.EXPORT} component={Export} />
        <PrivateRoute path={paths.ADD_TRANSACTION} component={Transaction} />
        <PrivateRoute path={paths.MODIFY_TRANSACTION} component={Transaction} />
        <PrivateRoute path={paths.TRANSACTIONS} component={Transactions} />
        <PrivateRoute path={paths.WITHDRAWALS} component={Withdrawals} />
        <PrivateRoute path={paths.APPROVALS} component={Approvals} />
        <PrivateRoute path={paths.ACTIONS} component={Actions} />
        <PrivateRoute path={paths.ROOT} component={Home} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterComponent;
