import {createAction} from 'redux-act';
import {toast} from "react-toastify";

import {firebaseError} from 'utils';
import firebase from 'firebase.js';
import {checkUserData, AUTH_UPDATE_USER_DATA} from './auth';
import {
    fetchCollection,
    fetchDocuments,
    fetchDocument,
    createDocument,
    deleteDocument,
    updateDocument,
} from '../api';
import {searchIndex} from "../../service/algolia";

export const USERS_FETCH_DATA_INIT = createAction('USERS_FETCH_DATA_INIT');
export const USERS_FETCH_DATA_SUCCESS = createAction('USERS_FETCH_DATA_SUCCESS');
export const USER_FETCH_DATA_SUCCESS = createAction('USER_FETCH_DATA_SUCCESS');
export const USERS_FETCH_DATA_FAIL = createAction('USERS_FETCH_DATA_FAIL');

export const USERS_DELETE_USER_INIT = createAction('USERS_DELETE_USER_INIT');
export const USERS_DELETE_USER_SUCCESS = createAction('USERS_DELETE_USER_SUCCESS');
export const USERS_DELETE_USER_FAIL = createAction('USERS_DELETE_USER_FAIL');

export const USERS_CREATE_USER_INIT = createAction('USERS_CREATE_USER_INIT');
export const USERS_CREATE_USER_SUCCESS = createAction('USERS_CREATE_USER_SUCCESS');
export const USERS_CREATE_USER_FAIL = createAction('USERS_CREATE_USER_FAIL');

export const USERS_MODIFY_USER_INIT = createAction('USERS_MODIFY_USER_INIT');
export const USERS_MODIFY_USER_SUCCESS = createAction('USERS_MODIFY_USER_SUCCESS');
export const USERS_MODIFY_USER_FAIL = createAction('USERS_MODIFY_USER_FAIL');

export const USERS_CLEAN_UP = createAction('USERS_CLEAN_UP');

export const USERS_CLEAR_DATA_LOGOUT = createAction('USERS_CLEAR_DATA_LOGOUT');

export const paginateUsers = (pageSize, search, direction, attribute) => {
    return async (dispatch, getState) => {
        const prevUsers = [...getState().users.data];
        const prevTotal = getState().users.total;
        const prevPage = getState().users.page;
        let newPage = (direction === 'init') ? 1 : (prevPage || 1);
        if (direction === 'next') {
            newPage += 1;
        } else if (direction === 'prev') {
            newPage -= 1;
        }

        const prevOrder = getState().users.sortConfig.order;
        const prevAttribute = getState().users.sortConfig.attribute;

        const newAttribute = attribute || prevAttribute;

        let newOrder = 'asc';
        if (attribute === prevAttribute) {
            newOrder = (prevOrder === 'asc') ? 'desc' : 'asc';
        }
        const newSortConfig = {
            attribute: newAttribute,
            order: newOrder
        };

        dispatch(checkUserData());
        dispatch(USERS_FETCH_DATA_INIT());

        let users;
        let total = prevTotal;
        try {
            if (search) {
                const res = await searchIndex('users', search, newPage - 1, pageSize);
                const ids = res.hits.map((hit) => hit.objectID);

                users = await fetchDocuments('users', ids);
                total = res.nbHits;
            } else if (direction === 'next') {
                users = await fetchCollection('users', {
                    sort: newSortConfig,
                    startAfter: prevUsers.pop().name,
                    pageSize,
                });
            } else if (direction === 'prev') {
                users = await fetchCollection('users', {
                    sort: newSortConfig,
                    endBefore: prevUsers.shift().name,
                    pageSize,
                });
            } else {
                users = await fetchCollection('users', {
                    sort: newSortConfig,
                    pageSize,
                });
                total = await firebase.firestore().collection('users').get().then((counter) => {
                  return counter.size;
                });
            }
        } catch (error) {
            toast.error(error);
            return dispatch(USERS_FETCH_DATA_FAIL({error}));
        }

        return dispatch(
            USERS_FETCH_DATA_SUCCESS({
                data: users,
                total,
                page: newPage,
                sortConfig: newSortConfig
            })
        );
    };
};

export const fetchUser = (id) => {
    return async (dispatch, getState) => {
        dispatch(checkUserData());
        dispatch(USERS_FETCH_DATA_INIT());
        let user;
        try {
            user = await fetchDocument('users', id);
        } catch (error) {
            toast('', error);
            return dispatch(USERS_FETCH_DATA_FAIL({error}));
        }

        if (!user) {
            const errorMessage = 'User not available';
            toast.error(errorMessage);
            return dispatch(USERS_FETCH_DATA_FAIL({error: errorMessage}));
        }

        return dispatch(
            USER_FETCH_DATA_SUCCESS({
                user,
            })
        );
    };
};

const deleteLogo = (oldLogo) => {
    if (!oldLogo.includes('firebasestorage')) {
        return null;
    }
    const logoPath = oldLogo.split('users%2F').pop().split('?alt=media').shift();
    return firebase.storage().ref(`users/${logoPath}`).delete();
};

export const deleteUser = (id) => {
    return async (dispatch, getState) => {
        dispatch(USERS_DELETE_USER_INIT());
        const {locale} = getState().preferences;
        const {logoUrl} = getState()
            .users.data.filter((user) => user.id === id)
            .pop();

        const deleteLogoTask = logoUrl ? deleteLogo(logoUrl) : null;

        const deleteUserTask = deleteDocument('users', id);

        try {
            await Promise.all([deleteLogoTask, deleteUserTask]);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                USERS_DELETE_USER_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('The user was deleted.');
        return dispatch(USERS_DELETE_USER_SUCCESS({id}));
    };
};

export const clearUsersDataLogout = () => {
    return (dispatch) => {
        dispatch(USERS_CLEAR_DATA_LOGOUT());
    };
};

const uploadLogo = (uid, file) => {
    const storageRef = firebase.storage().ref();

    const fileExtension = file.name.split('.').pop();

    const fileName = `${uid}.${fileExtension}`;

    return storageRef.child(`users/${fileName}`).put(file);
};

const getLogoUrl = (uid, file) => {
    const fileExtension = file.name.split('.').pop();

    const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

    return `${bucketUrl}/o/users%2F${uid}_200x200.${fileExtension}?alt=media`;
};

export const createUser = ({
                               name,
                               email,
                               location,
                               file,
                               roles,
                               createdAt,
                               isAdmin,
                               isLegal,
                           }) => {
    return async (dispatch, getState) => {
        dispatch(USERS_CREATE_USER_INIT());
        const {locale} = getState().preferences;
        const user = getState().auth ? getState().auth.userData : getState().users.user;

        let response;
        try {
            const createUserAuth = firebase
                .functions()
                .httpsCallable('httpsCreateUser');

            response = await createUserAuth({email, isAdmin});
        } catch (error) {
            const errorMessage = firebaseError(error.message, locale);
            toast.error(errorMessage);
            return dispatch(
                USERS_CREATE_USER_FAIL({
                    error: errorMessage,
                })
            );
        }

        const {uid} = response.data;

        let uploadLogoTask = null;
        let logoUrl = null;
        if (file) {
            logoUrl = getLogoUrl(uid, file);
            uploadLogoTask = uploadLogo(uid, file);
        }

        const isAdminNew = user.isAdmin ? isAdmin : false;

        const userData = {
            name,
            email,
            location,
            logoUrl,
            roles,
            createdAt,
            isAdmin: user ? isAdminNew : false,
            isLegal
        };

        const createUserDbTask = createDocument('users', uid, userData);

        const actionCodeSettings = {
            url: process.env.REACT_APP_LOGIN_PAGE_URL,
            handleCodeInApp: true,
        };

        const sendSignInLinkToEmailTask = firebase
            .auth()
            .sendSignInLinkToEmail(email, actionCodeSettings);

        try {
            await Promise.all([
                uploadLogoTask,
                createUserDbTask,
                sendSignInLinkToEmailTask,
            ]);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                USERS_CREATE_USER_FAIL({
                    error: errorMessage,
                })
            );
        }

        toast.success('User created successfully');
        return dispatch(USERS_CREATE_USER_SUCCESS({user: response.data}));
    };
};

export const modifyUser = ({
                               name,
                               location,
                               isAdmin,
                               isLegal,
                               companyName,
                               regCode,
                               address,
                               iban,
                               file,
                               createdAt,
                               id,
                               roles,
                               isEditing,
                               isProfile,
                           }) => {
    return async (dispatch, getState) => {
        dispatch(USERS_MODIFY_USER_INIT());
        const {locale} = getState().preferences;
        const user = isProfile ? getState().auth.userData : getState().users.user;
        if(user.id !== id) {
            return dispatch(
                USERS_MODIFY_USER_FAIL({
                    error: 'Technical error',
                })
            );
        }

        const {logoUrl} = user;
        let deleteLogoTask;
        let uploadLogoTask;
        let newLogoUrl = null;
        if (file) {
            newLogoUrl = getLogoUrl(id, file);
            deleteLogoTask = logoUrl && deleteLogo(logoUrl);
            uploadLogoTask = uploadLogo(id, file);
        }

        const userData = {
            name,
            location,
            createdAt,
            roles,
            companyName: isLegal ? companyName : null,
            regCode: isLegal ? regCode : null,
            address: isLegal ? address : null,
            iban: isLegal ? iban : null,
            isAdmin: user.isAdmin ? isAdmin : false,
            isLegal,
            logoUrl: newLogoUrl || logoUrl,
        };
        const updateUserDbTask = updateDocument('users', id, userData);

        try {
            await Promise.all([deleteLogoTask, uploadLogoTask, updateUserDbTask]);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toast.error(errorMessage);
            return dispatch(
                USERS_MODIFY_USER_FAIL({
                    error: errorMessage,
                })
            );
        }

        const {uid} = firebase.auth().currentUser;

        if (id === uid) {
            dispatch(AUTH_UPDATE_USER_DATA({...userData, id}));
        }

        if (isProfile) {
            toast.success('Profile updated successfully');
        } else if (isEditing) {
            toast.success('User updated successfully');
        }

        return dispatch(USERS_MODIFY_USER_SUCCESS({user: userData, id}));
    };
};

export const usersCleanUp = () => (dispatch) => dispatch(USERS_CLEAN_UP());
