import {createReducer} from 'redux-act';

import {
    APPROVALS_ALBUMS_FETCH_DATA_INIT,
    APPROVALS_ALBUMS_FETCH_DATA_SUCCESS,
    APPROVALS_ALBUM_FETCH_DATA_SUCCESS,
    APPROVALS_ALBUMS_FETCH_DATA_FAIL,
    APPROVALS_ALBUMS_MODIFY_ALBUM_INIT,
    APPROVALS_ALBUMS_MODIFY_ALBUM_SUCCESS,
    APPROVALS_ALBUMS_MODIFY_ALBUM_FAIL,
    APPROVALS_ALBUMS_CLEAN_UP,
    APPROVALS_ALBUMS_CLEAR_DATA_LOGOUT,
} from 'state/actions/albums_approvals';

const initialState = {
    data: [],
    album: null,
    total: 0,
    page: 1,
    loading: false,
    error: null,
    success: false,
    deleted: false,
    sortConfig: {
      attribute: 'name',
      order: 'asc'
    }
};

export const approvalsAlbumsReducer = createReducer(
    {
        [APPROVALS_ALBUMS_FETCH_DATA_INIT]: () => ({
            ...initialState,
            loading: true,
        }),
        [APPROVALS_ALBUM_FETCH_DATA_SUCCESS]: (state, payload) => ({
            ...state,
            album: payload.album,
            loading: false,
            error: null,
        }),
        [APPROVALS_ALBUMS_FETCH_DATA_SUCCESS]: (state, payload) => ({
            ...state,
            data: payload.data,
            total: payload.total,
            page: payload.page,
            sortConfig: payload.sortConfig,
            loading: false,
            error: null,
        }),
        [APPROVALS_ALBUMS_FETCH_DATA_FAIL]: (state, payload) => ({
            ...state,
            loading: false,
            error: payload.error,
        }),
        [APPROVALS_ALBUMS_MODIFY_ALBUM_INIT]: (state) => ({
            ...state,
            loading: true,
        }),
        [APPROVALS_ALBUMS_MODIFY_ALBUM_SUCCESS]: (state, payload) => ({
            ...state,
            data: !state.data
                ? []
                : state.data.map((elem) => {
                    if (elem.id === payload.id) {
                        return {
                            name: payload.album.name,
                            id: payload.id,
                            img: payload.album.img,
                            artists: payload.album.artists,
                            genres: payload.album.genres,
                            songs: payload.album.songs,
                            createdAt: payload.album.createdAt,
                        };
                    }
                    return elem;
                }),
            loading: false,
            error: null,
            success: true,
        }),
        [APPROVALS_ALBUMS_MODIFY_ALBUM_FAIL]: (state, payload) => ({
            ...state,
            loading: false,
            error: payload.error,
        }),
        [APPROVALS_ALBUMS_CLEAN_UP]: (state) => ({
            ...state,
            loading: false,
            error: null,
            success: false,
            deleted: false,
        }),
        [APPROVALS_ALBUMS_CLEAR_DATA_LOGOUT]: () => ({
            ...initialState,
        }),
    },
    initialState
);
