import firebase from 'firebase';
import { useFormatMessage } from 'hooks';
import React, { useState } from 'react';
import { fetchCollection } from 'state/api';
import { createDateFromSeconds, getDaysBetweenDates } from 'utils';
import ExportTile from './ExportTile';

const GoogleNotificationTypes = {
    SUBSCRIPTION_RECOVERED: 1,
    SUBSCRIPTION_RENEWED: 2,
    SUBSCRIPTION_CANCELED: 3,
    SUBSCRIPTION_PURCHASED: 4,
    SUBSCRIPTION_ON_HOLD: 5,
    SUBSCRIPTION_IN_GRACE_PERIOD: 6,
    SUBSCRIPTION_RESTARTED: 7,
    SUBSCRIPTION_PRICE_CHANGE_CONFIRMED: 8,
    SUBSCRIPTION_DEFERRED: 9,
    SUBSCRIPTION_PAUSED: 10,
    SUBSCRIPTION_PAUSE_SCHEDULE_CHANGED: 11,
    SUBSCRIPTION_REVOKED: 12,
    SUBSCRIPTION_EXPIRED: 13,
};

const currentDate = new Date();

const csvHeaders = ["user_id", "user_name", "user_email", "user_createdAt", "user_language", "subscription_is_active", "subscription_duration_days", "subscription_is_canceled", "subscription_is_expired", "subscription_app"];

const UsersExport = () => {
    const [isFetchingUsers, setIsFetchingUsers] = useState(false);
    const [usersData, setUsersData] = useState(null);

    const getAllUserSubscriptions = async (collection, origTxId) => {
        const { docs } = await firebase.firestore().collection(collection).where('origTxId', '==', origTxId).get();
        const subscriptions = [];
        docs.forEach((doc) => subscriptions.push(doc.data()));

        const subscriptionsSortedByEndDate = subscriptions.sort((a, b) => b.endDate.toDate() - a.endDate.toDate());

        return subscriptionsSortedByEndDate?.length > 0 ? subscriptionsSortedByEndDate : null;
    };

    const getUserDefaultSubscriptionData = (user) => {
        const subscriptionStartDate = user.subscription.startDate.toDate();
        const subscriptionEndDate = user.subscription.endDate.toDate();
        const toTimeframe = subscriptionEndDate > currentDate ? currentDate : subscriptionEndDate;

        // Set is subscription active
        const subscriptionCurrentlyActive = subscriptionEndDate > currentDate ? 1 : null;
        const subscriptionDuration = getDaysBetweenDates(subscriptionStartDate, toTimeframe);
        const subscriptionIsCanceled = null; // we could do subscriptionEndDate < currentDate here but this would be faulty with trial subscriptions
        const subscriptionIsExpired = subscriptionEndDate < currentDate ? 1 : null;
        const subscriptionApp = user.subscription.app.length > 0 ? user.subscription.app : null;
        return [user.id, user.name, user.email, createDateFromSeconds(user.createdAt.seconds).toLocaleDateString(), user.language ?? 'et', subscriptionCurrentlyActive, subscriptionDuration, subscriptionIsCanceled, subscriptionIsExpired, subscriptionApp];
    };

    const getUserSubscriptions = async (startDate, endDate) => {
        setUsersData(null);
        setIsFetchingUsers(true);
        const users = await fetchCollection('users', {
            queries: [
                {
                    attribute: 'createdAt',
                    operator: '>=',
                    value: firebase.firestore.Timestamp.fromDate(new Date(startDate.setHours(0, 0, 0, 0))),
                },
                {
                    attribute: 'createdAt',
                    operator: '<=',
                    value: firebase.firestore.Timestamp.fromDate(new Date(endDate.setHours(23, 59, 59, 999))),
                }
            ]
        });

        const data = await Promise.all(users.map((async (user) => {

            let subscriptionCurrentlyActive = null;
            let subscriptionDuration = null;
            let subscriptionIsCanceled = null;
            let subscriptionApp = null;
            let subscriptionIsExpired = null;

            if (user.subscription) {
                if (user?.subscription?.app === 'android') {
                    // We fetch notifications for android
                    const subscriptions = await getAllUserSubscriptions('googleNotifications', user.subscription.origTxId);
                    const subscription = subscriptions?.[0];
                    if (!subscription) {
                        return getUserDefaultSubscriptionData(user);
                    }

                    const subscriptionEndDate = subscription.endDate.toDate();

                    // Set is subscription active
                    if (subscriptionEndDate > currentDate) subscriptionCurrentlyActive = 1;

                    // Google doesn't create multiple retry notifications, so we don't have to filter them out
                    // We only filter out based on type
                    const filteredSubscriptions = subscriptions.filter((sub) => [/* GoogleNotificationTypes.SUBSCRIPTION_RECOVERED,  */GoogleNotificationTypes.SUBSCRIPTION_PURCHASED, GoogleNotificationTypes.SUBSCRIPTION_RENEWED, GoogleNotificationTypes.SUBSCRIPTION_CANCELED, GoogleNotificationTypes.SUBSCRIPTION_EXPIRED].indexOf(sub?.subscriptionNotification?.notificationType || sub.type) > -1);

                    // Set is cancelled
                    subscriptionIsCanceled = subscription.latestReceipt.type === GoogleNotificationTypes.SUBSCRIPTION_CANCELED ? 1 : null;

                    // Set is expired
                    subscriptionIsExpired = subscription.latestReceipt.type === GoogleNotificationTypes.SUBSCRIPTION_EXPIRED ? 1 : null;

                    // Set subscription duration
                    let hasCalculatedFreeTrialPeriod = false;
                    filteredSubscriptions.forEach((sub) => {
                        const start = sub.startDate.toDate();
                        let end = sub.endDate.toDate();

                        // Google sets free trial period to 1 month, we reset it to 3 days
                        if (sub.type === GoogleNotificationTypes.SUBSCRIPTION_PURCHASED && !hasCalculatedFreeTrialPeriod) {
                            const threeDays = new Date(sub.startDate.toDate());
                            threeDays.setDate(threeDays.getDate() + 3);
                            end = threeDays;
                            hasCalculatedFreeTrialPeriod = true;
                        }

                        // So here we have to check if the trial subscription has been cancelled or expired
                        // based on resetting the trial period to 3 days
                        if (sub.type === GoogleNotificationTypes.SUBSCRIPTION_CANCELED) {
                            if (end < currentDate) subscriptionIsCanceled = 1;
                        }
                        if (sub.type === GoogleNotificationTypes.SUBSCRIPTION_EXPIRED) {
                            if (end < currentDate) subscriptionIsExpired = 1;
                        }

                        // if subscription end is longer than current date, use current date instead
                        const toTimeframe = end > currentDate ? currentDate : end;
                        subscriptionDuration += getDaysBetweenDates(start, toTimeframe);
                    });

                    // Set app
                    subscriptionApp = 'android';
                } else {
                    // We fetch notifications for iOS
                    const subscriptions = await getAllUserSubscriptions('notifications', user.subscription.origTxId);
                    const subscription = subscriptions?.[0];
                    if (!subscription) {
                        return getUserDefaultSubscriptionData(user);
                    }

                    const subscriptionEndDate = subscription.endDate.toDate();

                    // Set is subscription active
                    if (subscriptionEndDate > currentDate) subscriptionCurrentlyActive = 1;

                    // We create an array of subscriptions that have a unique 'transactionId'
                    const subs = [];
                    subscriptions.forEach((sub) => {
                        const found = subs.find((o) => o.transactionId === sub.transactionId);
                        if (!found) {
                            subs.push(sub);
                            return;
                        }
                        // We check if it is newer than the previous one
                        if (sub?.createdAt?.toDate() > found?.createdAt?.toDate()) {
                            subs.splice(subs.indexOf(found), 1, sub);
                        }
                    });

                    const filteredSubscriptions = subs.filter((sub) => ['INITIAL_BUY', 'DID_RENEW', 'CANCEL', 'DID_RECOVER', 'INTERACTIVE_RENEWAL'].indexOf(sub.type) > -1);
                    // Set subscription duration
                    filteredSubscriptions.forEach((sub) => {
                        const start = sub.startDate.toDate();
                        const end = sub.endDate.toDate();

                        // if subscription end is longer than current date, use current date instead
                        const toTimeframe = end > currentDate ? currentDate : end;
                        subscriptionDuration += getDaysBetweenDates(start, toTimeframe);
                    });

                    // Set is cancelled
                    if (subscription.raw.unified_receipt.pending_renewal_info[0].auto_renew_status !== "1") subscriptionIsCanceled = 1;

                    // Set is expired
                    if (subscription.raw.unified_receipt.latest_receipt_info[0].expires_date_ms < Date.now()) subscriptionIsExpired = 1;

                    // Set app
                    subscriptionApp = 'ios';
                }
            }
            return [user.id, user.name, user.email, createDateFromSeconds(user.createdAt.seconds).toLocaleDateString(), user.language ?? 'et', subscriptionCurrentlyActive, subscriptionDuration, subscriptionIsCanceled, subscriptionIsExpired, subscriptionApp];
        })));

        setIsFetchingUsers(false);
        setUsersData(data);
    };
    return (
        <ExportTile
            title="Export users"
            onFetch={(startDate, endDate) => getUserSubscriptions(startDate, endDate)}
            isFetching={isFetchingUsers}
            data={usersData}
            headers={csvHeaders}
        />
    );
};

export default UsersExport;