import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {authReducer} from './auth';
import {usersReducer} from './users';
import {artistsReducer} from './artists';
import {ownersReducer} from './owners';
import {genresReducer} from './genres';
import {songsReducer} from './songs';
import {approvalsSongsReducer} from './songs/approvals';
import {albumsReducer} from './albums';
import {approvalsAlbumsReducer} from './albums/approvals';
import {playlistsReducer} from './playlists';
import {featuredReducer} from './featured';
import {preferencesReducer} from './preferences';
import {transactionsReducer} from './transactions';

export default combineReducers({
    auth: persistReducer(
        {
            key: 'auth',
            storage,
            blacklist: ['error', 'loading'],
        },
        authReducer
    ),
    preferences: persistReducer(
        {key: 'preferences', storage},
        preferencesReducer
    ),
    users: persistReducer(
        {
            key: 'users',
            storage,
            blacklist: ['error', 'loading'],
        },
        usersReducer
    ),
    artists: persistReducer(
        {
            key: 'artists',
            storage,
            blacklist: ['error', 'loading'],
        },
        artistsReducer
    ),
    owners: persistReducer(
      {
          key: 'owners',
          storage,
          blacklist: ['error', 'loading'],
      },
      ownersReducer
    ),
    genres: persistReducer(
        {
            key: 'genres',
            storage,
            blacklist: ['error', 'loading'],
        },
        genresReducer
    ),
    songs: persistReducer(
        {
            key: 'songs',
            storage,
            blacklist: ['error', 'loading'],
        },
        songsReducer
    ),
    approvalSongs: persistReducer(
      {
          key: 'approvalSongs',
          storage,
          blacklist: ['error', 'loading'],
      },
      approvalsSongsReducer
    ),
    albums: persistReducer(
        {
            key: 'albums',
            storage,
            blacklist: ['error', 'loading'],
        },
        albumsReducer
    ),
    approvalAlbums: persistReducer(
        {
            key: 'approvalAlbums',
            storage,
            blacklist: ['error', 'loading'],
        },
        approvalsAlbumsReducer
    ),
    playlists: persistReducer(
        {
            key: 'playlists',
            storage,
            blacklist: ['error', 'loading'],
        },
        playlistsReducer
    ),
    featured: persistReducer(
        {
            key: 'featured',
            storage,
            blacklist: ['error', 'loading'],
        },
        featuredReducer
    ),
    transactions: persistReducer(
        {
            key: 'transactions',
            storage,
            blacklist: ['error', 'loading'],
        },
        transactionsReducer
    ),
});
