/* eslint-disable jsx-a11y/label-has-associated-control */
import { yupResolver } from '@hookform/resolvers';
import classNames from 'classnames';
import ErrorMessage from 'components/ErrorMessage';
import { useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ownersCleanUp } from 'state/actions/owners';
import { getImgThumb } from 'utils';
import '../ArtistForm/ArtistForm.scss';

const OwnerForm = ({ owner, onSubmitHandler, schema }) => {
  const { loading, success } = useSelector(
    (state) => ({
      loading: state.owners.loading,
      success: state.owners.success,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const { register, handleSubmit, errors, setValue, watch, control } = useForm({
    defaultValues: {
      ...owner,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (success) {
      setValue('file', null);
    }
    return () => dispatch(ownersCleanUp());
  }, [dispatch, success, setValue]);


  const imagePreviewUrl =
    watch('file') && watch('file')[0]
      ? URL.createObjectURL(watch('file')[0])
      : getImgThumb(owner?.img, true);

  const goBackMessage = useFormatMessage('ArtistForm.goBack');

  const pickAnotherFileMessage = useFormatMessage('ArtistForm.pickAnotherFile');
  const pickFileMessage = useFormatMessage('ArtistForm.pickFile');

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                {useFormatMessage('OwnerForm.ownerInfo')}
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ArtistForm.name')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="name"
                          id="name"
                          className={classNames('input', {
                            'is-danger': errors.name,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.name && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <hr />

                <div className="field has-check is-horizontal">
                  <div className="field-label">
                    <label className="label">{useFormatMessage('OwnersForm.RecordLabel')}</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="field">
                        <div className="control">
                          <label className="b-checkbox checkbox">
                            <input
                              type="checkbox"
                              name="recordLabel"
                              ref={register}
                            />
                            <span className="check is-primary" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('UserForm.logo')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="file has-name">
                        <label className="file-label">
                          <input
                            className="file-input"
                            type="file"
                            name="file"
                            ref={register}
                            accept="image/*"
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="mdi mdi-upload" />
                            </span>
                            <span className="file-label">
                              {watch('file') && watch('file').file
                                ? pickAnotherFileMessage
                                : pickFileMessage}
                            </span>
                          </span>
                          <span className="file-name">
                            {watch('file') && watch('file')[0]?.name}
                          </span>
                        </label>
                      </div>
                      <em>Cover art must be square .jpg or .png file and be at least 1000x1000 px.</em>
                      {imagePreviewUrl && (
                        <div className="has-max-width">
                          <img
                            className="artist-img"
                            src={imagePreviewUrl}
                            alt="User profile logo preview"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {errors.file && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${loading && 'is-loading'
                              }`}
                          >
                            <span>{useFormatMessage('ArtistForm.submit')}</span>
                          </button>
                        </div>
                        <Link to={paths.OWNERS} className="button">
                          {goBackMessage}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

OwnerForm.propTypes = {
  artist: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    img: PropTypes.string,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
};

OwnerForm.defaultProps = {

};

export default OwnerForm;
