import firebase from 'firebase.js';
import { getImgThumb} from '../utils';
import {
    fetchCollection,
    fetchDocument,
    fetchDocuments,
    createDocument,
    deleteDocument,
    updateDocument,
} from '../state/api';

const getImageUrl = (uid, file) => {
    const fileExtension = file.name.split('.').pop();
    const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;
    return `${bucketUrl}/o/albums%2F${uid}.${fileExtension}`;
};

const uploadFile = (uid, file) => {
    const storageRef = firebase.storage().ref();
    const fileExtension = file.name.split('.').pop();
    const fileName = `${uid}.${fileExtension}`;
    return storageRef.child(`albums/${fileName}`).put(file);
};

const deleteFile = (oldImage) => {
    if (!oldImage.includes('firebasestorage')) {
        return null;
    }

    const imagePath = oldImage.split('albums%2F').pop();
    const thumbPath = getImgThumb(oldImage).split('albums%2F').pop();

    return Promise.all([
        firebase.storage().ref(`albums/${imagePath}`).delete(),
        firebase.storage().ref(`albums/${thumbPath}`).delete()
    ]);
};

class AlbumStore {

    /* eslint-disable class-methods-use-this */
    static modifyAlbum(currentAlbum, newData) {
        const tasks = [];

        if(currentAlbum.img && newData.file) {
            tasks.push(deleteFile(currentAlbum.img));
        }
        let newImgUrl = null;
        if(newData.file) {
            newImgUrl = getImageUrl(currentAlbum.id, newData.file);
            tasks.push(uploadFile(currentAlbum.id, newData.file));
        }

        const artists = newData.artists || currentAlbum.artists;
        const genres = newData.genres || currentAlbum.genres;
        const songs = newData.songs || currentAlbum.songs;
        const songIds = songs.map((song) => {
            return (song.id) ? song.id : song;
        });
        const albumData = {
            name: newData.name || currentAlbum.name,
            artists: artists.map((artist) => {
                return (artist.id) ? artist.id : artist;
            }),
            genres: genres.map((genre) => {
                return (genre.id) ? genre.id : genre;
            }),
            songs: songIds,
            img: newImgUrl || currentAlbum.img,
            recordingYear: newData.recordingYear !== undefined ? newData.recordingYear : currentAlbum.recordingYear || null,
            isSingle: newData.isSingle ?? false,
            availableFrom: newData.availableFrom || currentAlbum.availableFrom,
            ownerId: newData.ownerId || currentAlbum.ownerId,
            approved: newData.approved ?? currentAlbum.approved
        };

        tasks.push(updateDocument('albums', currentAlbum.id, albumData));

        if (newData.ownerId !== currentAlbum.ownerId) {
          songIds.forEach((songId) => {
            tasks.push(updateDocument('songs', songId, {
              ownerId: newData.ownerId,
              approved: true
            }));
          });
        }

        if(newImgUrl) {
            songIds.forEach((songId) => {
                tasks.push(updateDocument('songs', songId, {
                    img: newImgUrl,
                }));
            });
        }

        if (newData.availableFrom !== currentAlbum.availableFrom) {
          songIds.forEach((songId) => {
            tasks.push(updateDocument('songs', songId, {
              availableFrom: newData.availableFrom || currentAlbum.availableFrom
            }));
          });
        }

        if (newData.approved !== currentAlbum.approved) {
            songIds.forEach((songId) => {
                tasks.push(updateDocument('songs', songId, {
                  approved: newData.approved ?? currentAlbum.approved
                }));
            });
        }

        // eslint-disable-next-line eqeqeq
        if(newData.recordingYear !== undefined && newData.recordingYear != currentAlbum.recordingYear) {
            songIds.forEach((songId) => {
                tasks.push(updateDocument('songs', songId, {
                    recordingYear: newData.recordingYear,
                }));
            });
        }

        return Promise.all(tasks).then(() => {
            return {
                id: currentAlbum.id,
                ...albumData,
            };
        });
    }
}

export default AlbumStore;

