import React, {useState} from 'react';
import PropTypes from 'prop-types';

const AudioButton = ({url}) => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);
    const start = (e) => {
        audio.play().catch((err) => {
            console.log(url);
            console.warn(err);
        });
        setPlaying(true);
    };
    const pause = (e) => {
        audio.pause();
        setPlaying(false);
    };

    return (
        <a className="icon" onClick={(e) => playing ? pause(e) : start(e)}>
            <i className={playing ? 'mdi mdi-pause default' : 'mdi mdi-play default'}/>
        </a>
    );
};

AudioButton.propTypes = {
    url: PropTypes.string.isRequired,
};

AudioButton.defaultProps = {};

export default AudioButton;
